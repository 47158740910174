import React, { useContext, useEffect, useState, useRef } from "react";
import "./Courses.scss";
import { LoadingContext } from "../../components/Spinner/LoadingContext";
import Banner from "../../components/courses/Banner";
import SemesterAbroadProgram from "../../components/courses/SemesterAbroadProgram";
import MeetTopMentors from "../../components/courses/MeetTopMentors";
import ExploreStreamCourse from "../../components/courses/ExploreStreamCourse";
import TakeAFreetest from "../../components/courses/TakeaFreetest";
import SuccessStrip from "../../components/courses/SuccessStrip";
import ScholarshipGuidance from "../../components/courses/ScholarshipGuidance";
import StudentTestimonials from "../../components/courses/StudentTestimonials";
import CourseStreamQuiz from "../../components/courses/CourseStreamQuiz";
import ExploreMore from "../../components/courses/ExploreMore/ExploreMore";
import ExploreOccupation from "../../components/courses/ExploreOccupation";

import { CarreVisualStories } from "../../components/career-visual-stories/CarreVisualStories";
import CollegeMentors from "../../components/courses/CollegeMentors";
import SubscribeNow from "../../components/courses/SubscribeNow";
import config from '../../util/config';
interface IStreamData {
  streamTitle: string;
  streamName: string;
  streamIcon: string;
}

interface SpotLight {
  image: string;
  pushlishDate: string;
  link: string;
  description: string;
  title: string;
}

interface VisualStory {
  id: number;
  title: string;
  description: string;
  stories: {
    id: number;
    type: "video" | "article" | "image";
    url: string;
    careerVisualStoryDataId?: number;
  }[];
}

interface Article {
  publishDate: string;
  description: string;
  title: string;
}

interface Webinar {
  date: string;
  by: string;
  description: string;
  time: string;
  title: string;
  image1: string;
  name2: string;
  image2: string;
  name1: string;
}

interface ExploreMoreData {
  spotLight: SpotLight[];
  visualStories: VisualStory[];
  articles: Article[];
  webinar: Webinar[];
}

interface IApiData {
  streams: IStreamData[];
  exploreMore: ExploreMoreData;
}

const Courses: React.FC = () => {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [data, setData] = useState<IApiData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [activeSectionIndex, setActiveSectionIndex] = useState<number | null>(
    null
  );

  const streams =
    data?.streams?.map((item) => ({
      name: item.streamTitle,
      key: item.streamName,
      icon: item.streamIcon,
    })) || [];

  const exploreMore: ExploreMoreData = {
    spotLight: data?.exploreMore?.spotLight || [],
    visualStories: data?.exploreMore?.visualStories || [],
    articles: data?.exploreMore?.articles || [],
    webinar: data?.exploreMore?.webinar || [],
  };

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const response = await fetch(
          config.apiUrl + "/api/course/get-all-streams-courses"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (err: any) {
        setError(err.message || "An unknown error occurred");
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [startLoading, stopLoading]);

  const careerVisualStoriesData = [
    {
      id: 1,
      src: "/images/careers/cvs01.jpg",
      title: "Career Visual Stories",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 2,
      src: "/images/careers/cvs02.jpg",
      title: "Career Visual Stories",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 3,
      src: "/images/careers/cvs03.jpg",
      title: "Career Visual Stories",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 4,
      src: "/images/careers/cvs04.jpg",
      title: "Career Visual Stories",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 5,
      src: "/images/careers/cvs01.jpg",
      title: "Career Visual Stories",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 6,
      src: "/images/careers/cvs02.jpg",
      title: "Career Visual Stories",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ];

  useEffect(() => {
    const observerCallbacks = [
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSectionIndex(0);
          }
        });
      },
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSectionIndex(1);
          }
        });
      },
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSectionIndex(2);
          }
        });
      },
    ];

    const observers = [
      new IntersectionObserver(observerCallbacks[0], { threshold: 0.1 }),
      new IntersectionObserver(observerCallbacks[1], { threshold: 0.5 }),
      new IntersectionObserver(observerCallbacks[2], { threshold: 0.1 }),
    ];

    sectionRefs.current.forEach((ref, index) => {
      if (ref) observers[index].observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref, index) => {
        if (ref) observers[index].unobserve(ref);
      });
    };
  }, []);

  return (
    <>
      <Banner />
      <SemesterAbroadProgram />
      <MeetTopMentors />
      <ExploreStreamCourse data={streams} />
      <TakeAFreetest />
      <ExploreOccupation />
      <CourseStreamQuiz />
      <SuccessStrip />
      <CollegeMentors />
      <ScholarshipGuidance />
      <StudentTestimonials />

      <ExploreMore exploreMore={exploreMore} />
      <SubscribeNow />
    </>
  );
};

export default Courses;
