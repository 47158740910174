import React, { useState, useRef, useEffect } from "react";
import StudyAbroadCards from "./StudyAbroadCards";
import StudyVisualCards from "./StudyVisualCards";
import CustomSwiper from "../../../components/Slider/CustomSwiper";
import StudyVisualPopup from "./StudyVisualPopup";

interface Iprops {
  blogsAndArticles: {
    news: {
      image: string;
      pushlishDate: string;
      description: string;
      title: string;
    }[];
    spotlights: {
      image: string;
      pushlishDate: string;
      link: string;
      description: string;
      title: string;
      views: string;
    }[];
    visualStories: {
      image: string;
      slides: {
        image: string;
        description: string;
        title: string;
      }[];
      pushlishDate: string;
      description: string;
      title: string;
    }[];
    articles: {
      image: string;
      postedOn: string;
      description: string;
      title: string;
    }[];
    webinar: {
      image: string;
      postedOn: string;
      description: string;
      title: string;
    }[];
  };
}

const ExploreKnowledgeHub: React.FC<Iprops> = ({ blogsAndArticles }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabIndicatorStyle, setTabIndicatorStyle] =
    useState<React.CSSProperties>({
      marginLeft: 0,
      width: 0,
    });
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedPopupData, setSelectedPopupData] = useState<any>(null);

  const tabRef = useRef<HTMLButtonElement[]>([]);

  const reorderSlides = (slides: any[], startIndex: number) => {
    return [...slides.slice(startIndex), ...slides.slice(0, startIndex)];
  };

  const handleCardClick = (slides: any[], index: number) => {
    const reorderedSlides = reorderSlides(slides, index);
    setIsPopupVisible(true);
    setSelectedPopupData({
      slides: reorderedSlides,
      initialIndex: index,
    });
  };

  const tabs = [
    {
      name: "Articles",
      content: blogsAndArticles.articles.map((data, index) => (
        <StudyAbroadCards
          key={index}
          image={data.image}
          title={data.title}
          description={data.description}
        />
      )),
    },
    {
      name: "Spotlights",
      content: blogsAndArticles.spotlights.map((data, index) => (
        <StudyAbroadCards
          key={index}
          image={data.image}
          title={data.title}
          description={data.description}
        />
      )),
    },
    {
      name: "News",
      content: blogsAndArticles.news.map((data, index) => (
        <StudyAbroadCards
          key={index}
          image={data.image}
          title={data.title}
          description={data.description}
        />
      )),
    },
    {
      name: "Visual Stories",
      content: blogsAndArticles.visualStories.map((data, index) => (
        <StudyVisualCards
          key={index}
          image={data.image}
          title={data.title}
          description={data.description}
          onClick={() => handleCardClick(data.slides, 0)} // Pass slides and starting index
        />
      )),
    },
    {
      name: "Webinar",
      content: blogsAndArticles.webinar.map((data, index) => (
        <StudyAbroadCards
          key={index}
          image={data.image}
          title={data.title}
          description={data.description}
        />
      )),
    },
  ];

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
    setIndicatorToActiveTab(index);
  };

  const setIndicatorToActiveTab = (index: number) => {
    const tabElement = tabRef.current[index];
    if (!tabElement) return;

    const tabRect = tabElement.getBoundingClientRect();
    const containerRect = tabElement.parentElement?.getBoundingClientRect();
    const distance = tabRect.left - (containerRect?.left || 0);

    setTabIndicatorStyle({
      marginLeft: `${distance + tabRect.width / 4}px`,
      width: `${tabRect.width / 2}px`,
    });
  };

  useEffect(() => {
    setIndicatorToActiveTab(activeTabIndex);
  }, [activeTabIndex]);

  return (
    <>
      {isPopupVisible && selectedPopupData && (
        <StudyVisualPopup
          slides={selectedPopupData.slides}
          isOpen={isPopupVisible}
          initialIndex={selectedPopupData.initialIndex}
          onClose={() => setIsPopupVisible(false)}
        />
      )}
      <section className="relative pt-10 StudyKnowledgeHub">
        <div className="flex flex-col gap-4">
          <div className="container mx-auto">
            <div className="flex flex-row justify-between">
              <h2 className="text-2xl lg:text-5xl font-bold text-black">
                Explore Knowledge Hub
              </h2>
            </div>
          </div>

          <div className="flex flex-col gap-7">
            <div className="container mx-auto">
              <div className="relative">
                <div
                  onMouseLeave={() => setIndicatorToActiveTab(activeTabIndex)}
                  className="flex tab-container-occupation gap-4 lg:gap-20"
                >
                  {tabs.map((tab, index) => (
                    <button
                      key={index}
                      ref={(el) => (tabRef.current[index] = el!)}
                      onClick={() => handleTabClick(index)}
                      className={`py-2 text-sm sm:text-base font-semibold lg:leading-[1.375rem] font-open-sans lg:text-xl hover:text-[#173CBA] transition-all duration-300 ease-in-out ${
                        activeTabIndex === index
                          ? "text-[#173CBA] font-bold"
                          : "font-normal text-[#A8A8A8]"
                      }`}
                    >
                      {tab.name}
                    </button>
                  ))}
                  <div
                    className="absolute bottom-[-4px] h-[6px] bg-[#00C798] rounded-lg transition-all duration-300 ease-in-out"
                    style={tabIndicatorStyle}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ExploreMore-occupation w-full bg-[#173CBA] py-10">
            <div className="container mx-auto">
              <CustomSwiper
                items={tabs[activeTabIndex].content}
                navigation={"bottom"}
                autoplay={false}
                className="ExploreMoreOccupation"
                breakpoints={{
                  320: { slidesPerView: 1, spaceBetween: 20 },
                  768: { slidesPerView: 2, spaceBetween: 20 },
                  1024: { slidesPerView: 3, spaceBetween: 20 },
                  1280: { slidesPerView: 4, spaceBetween: 30 },
                }}
                progressBar={true}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreKnowledgeHub;
