import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../../util/config";

export const updateMyProfile = createAsyncThunk(
  "myProfile/updateUser",
  async (profileData: { [key: string]: string | number }) => {
    const response = await axios.post(
      config.apiUrl + "/api/auth/updateuser",
      profileData,
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      }
    );
    return response?.data;
  }
);

export const getMyProfile = createAsyncThunk("myProfile/getUser", async () => {
  const response = await axios.get(config.apiUrl + "/api/auth/user/profile", {
    headers: {
      Authorization: localStorage.getItem("accessToken"),
    },
  });

  const data = response.data;
  return {
    firstName: data?.basicProfile?.fullName?.split(", ")[0],
    lastName: data?.basicProfile?.fullName?.split(", ")[1],
    email: data?.contactDetails?.email,
    mobileNumber: data?.contactDetails?.mobileNumber,
    state: data?.contactDetails?.state?.stateId,
    city: data?.contactDetails?.city?.cityId,
    gender: data?.basicProfile?.gender,
    dob: data?.basicProfile?.dob,
    physicallyChallenged: data?.basicProfile?.physicallyChallenged,
    userExamDetails: data?.examDetails,
  };
});

export const getOptionData = createAsyncThunk(
  "myProfile/optionData",
  async () => {
    const response = await axios.post(
      config.apiUrl + "/api/auth/signup-info",
      {
        streamId: 0,
        courseId: 0,
        state: 0,
        city: 0,
      },
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      }
    );

    return response?.data;
  }
);
