const MainDetails = () => {
  return (
    <div className="flex flex-col gap-4 text-base">
      <div id="about" className="bg-pink-light p-8">
        <h1 className="text-4xl font-medium text-blue">About the Program</h1>
        <p className="mt-2">
          Education loans are designed to help students finance their higher
          education, both in India and abroad. These loans are offered by a
          variety of financial institutions, including public sector banks,
          private banks, and NBFCs.
        </p>
        <p className="mt-2">
          <strong>Coverage</strong> : Education loans typically cover expenses
          such as tuition fees, living costs, books, and supplies.
        </p>
        <p className="mt-2">
          <strong>Loan Amount and Interest Rates</strong> : Loans can range from
          ₹1 lakh to ₹1.5 crore, with interest rates starting from 8.15%
          (current rate of interest)
        </p>
        <strong>Types of Education Loans</strong> :
        <ul className="mt-2 ml-8">
          <li>
            <strong>Secured Education Loan</strong> Requires collateral as
            security.
          </li>
          <li>
            <strong>Unsecured Education Loan</strong> Does not require
            collateral but may have different terms.
          </li>
          <li>
            <strong>Terms and Conditions</strong> Loan terms, including amount,
            interest rate, moratorium period, and repayment duration, vary based
            on factors such as the location (domestic or overseas) and type of
            course (undergraduate or postgraduate) chosen by the student.
          </li>
        </ul>
        <p className="mt-2">
          Collateral is any form of asset / property that you pledge to the bank
          to get your loan. A collateral acts as a security to the bank in case
          you are unable to repay your loan for any reason.
        </p>
        <p className="mt-2">
          A Secured or Collateralized Education Loan is an education loan where
          you have to pledge collateral (an asset or security) such as a house
          or land to receive the loan amount.
        </p>
        <strong>Unsecured or Collateral - free Education Loan</strong>
        <p className="mt-2">
          An Unsecured or Collateral-free education loan is an education loan
          where the student can take an education loan without having to offer
          any security or collateral.
        </p>
        <strong>The collateral accepted by lenders is of two types</strong>
        <p className="mt-2">
          <strong>Immovable property</strong> : House, flat, or non-agricultural
          land.
        </p>
        <p className="mt-2">
          <strong>Liquid security</strong> : Fixed deposits, Insurance policies
          (term insurance), or Government bonds.
        </p>
        <p className="mt-2">
          <strong>Unsecured or Collateral - free Education Loan</strong> For
          detailed information on each aspect, please continue reading or
          comment with any additional queries you may have.
        </p>
        <p className="mt-2">*Terms and conditions apply.</p>
      </div>
      <div id="eligibility" className="p-8">
        <h1 className="text-4xl font-medium text-blue">Eligibility</h1>
        <p className="mt-2">
          Education Loan : <strong>UG & PG</strong>
        </p>
        <p className="mt-2">
          <strong>Coverage</strong> : Includes tuition fees, accommodation,
          expenses, books, Travel and supplies.
        </p>
        <p className="mt-2">
          <strong>Eligibility</strong> : Depends on the student’s academic
          performance and the institution’s requirements.
        </p>
        <p className="mt-2">
          The College Mentor Education Loan Programme is available to Indian
          students who meet the following criteria:
        </p>
        <ul className="mt-2 ml-8">
          <li>
            <strong>Admission Confirmation</strong> Must have secured admission
            to a UG/PG program at an institution in India.
          </li>
          <li>
            <strong>Qualification</strong> Must have fulfilled all prerequisite
            qualifications required by the chosen institution.
          </li>
          <li>
            <strong>Family Income</strong> The annual family income of the
            applicant should exceed ₹3 lakh. (Note: This criterion does not
            apply for loan amounts below ₹7.5 lakh.)
          </li>
          <li>
            <strong>Loan Amount</strong> The minimum loan amount required must
            be ₹1 lakh.
          </li>
        </ul>
      </div>
      <div id="benefits-documents" className="bg-pink-light p-8">
        <h1 className="text-4xl font-medium text-blue">Benefit & Documents</h1>
        <p className="mt-2">
          Applicants for the College Mentor Education Loan Programme will enjoy
          the following benefits:
        </p>
        <ul className="mt-2 ml-8">
          <li>
            <strong>Collateral-Free Loans</strong> : Access loan amounts up to
            ₹40 lakh without the need for collateral. (Minimum loan amount is ₹1
            lakh.)
          </li>
          <li>
            <strong>Competitive Interest Rates</strong> : Loans come with
            interest rates starting at 8.1% per annum*.
          </li>
          <li>
            <strong>Fast and Easy Sanction</strong> : Benefit from a streamlined
            application process for quick loan approval.
          </li>
          <li>
            <strong>Higher Loan Amounts</strong> : Loans up to ₹2 crore are
            available with collateral*.
          </li>
          <li>
            <strong>Income Tax Benefits</strong> : Receive 100% income tax
            benefit on interest paid under Section 80E of the IT Act.
          </li>
          <li>
            <strong>Comprehensive Coverage</strong> : The loan amount can cover
            various expenses, including:
          </li>
        </ul>
        <strong>College-Related Expenditures</strong>
        <ul className="mt-2 ml-8">
          <li>Tuition and fees</li>
          <li>Examination/library/laboratory fees</li>
          <li>Books, equipment, and uniforms</li>
          <li>Living expenses, including hostel fees</li>
        </ul>
        <strong>Additional Expenditures</strong>
        <ul className="mt-2 ml-8">
          <li>Travel expenses</li>
          <li>Purchase of computers/laptops</li>
          <li>Overseas travel insurance</li>
          <li>Health insurance</li>
        </ul>
        <p className="mt-2">
          (Note: Special considerations may be given to students pursuing
          professional courses at NIRF-ranked or top educational institutions,
          including lower interest rates and expedited loan disbursal.)
        </p>
      </div>
      <div id="how-to-apply" className="p-8">
        <h1 className="text-4xl font-medium text-blue">How to Apply</h1>
        <p className="mt-2">Click the ‘Apply Now’ button below.</p>
        <p className="mt-2">
          <strong>Log in to College Mentor with your registered ID</strong> and
          navigate to the <strong>‘Application Form Page’</strong>. If you are
          not registered with <strong>College Mentor</strong>, sign up with your
          <strong>Email / Mobile / Gmail Account.</strong> You will be
          redirected to the{" "}
          <strong>‘College Mentor Education Loan Programme’</strong>
          application form page. Click on the{" "}
          <strong>‘Start Application’</strong> button to begin
        </p>
        <p className="mt-2">
          Complete the online application form with the required details. Upload
          the necessary documents. Accept the{" "}
          <strong>‘Terms and Conditions’</strong> and click{" "}
          <strong>‘Preview’</strong>. Review the details on the preview screen.
          If everything is correct, click <strong>‘Submit’</strong> to finalize
          your Application.
        </p>
      </div>
    </div>
  );
};

export default MainDetails;
