import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const PAGE_SIZE = 5;

const useQueryParamPagination = (pageSize: number = PAGE_SIZE) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [totalCount, setTotalCount] = useState(0);
  const totalPages = Math.ceil(totalCount / pageSize);
  const currentPage = parseInt(searchParams.get("page") || "1");

  const handleNextPage = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("page", (currentPage + 1).toString());
      return updatedParams;
    });
  };

  const handlePrevPage = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("page", (currentPage - 1).toString());
      return updatedParams;
    });
  };

  const handleResetPagination = () => {
    if (currentPage != 1)
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        updatedParams.set("page", "1");
        return updatedParams;
      });
  };

  const updateTotalCount = (newTotalCount: number) =>
    setTotalCount(newTotalCount);

  return {
    totalPages,
    currentPage,
    updateTotalCount,
    handlePrevPage,
    handleNextPage,
    handleResetPagination,
  };
};

export default useQueryParamPagination;
