import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

interface BlogProps {
  authorDetails: any;
}

const AboutAuthor: React.FC<BlogProps> = ({ authorDetails }) => {
  console.log("AboutAuthor", authorDetails);
  return (
    <div className="flex flex-col gap-1 text-base text-black">
      {/* <h1 className="text-xl font-bold">About the Author</h1> */}
      <div className="flex items-center gap-4">
        <img
          src={
            authorDetails?.createdUser?.profileImageUrl ||
            "/images/avatar-placeholder.jpg"
          }
          // width="35"
          // className="rounded-full w-15"
          className="object-cover w-10 h-10 rounded-full"
          // className="rounded-full h-18 w-15"
        />
        <div>
          <span className="flex items-center justify-start text-xl font-bold">
            <span className="mr-1 text-[#173CBA] text-xs">
              {`${authorDetails?.createdUser?.firstName || ""} ${
                authorDetails?.createdUser?.lastName || ""
              }`.trim() || "Author"}
            </span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16px"
              height="16px"
              viewBox="0 0 53 53"
            >
              <g
                xmlns="http://www.w3.org/2000/svg"
                id="Artboard"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="Combined-Shape-2" transform="scale(3.7)">
                  <path
                    d="M6.72000015,0 C7.76368192,0 8.62675893,0.773260914 8.76757721,1.77812878 C9.57721063,1.16676664 10.7339269,1.23041159 11.4717578,1.96824248 C12.2095887,2.70607336 12.2732337,3.86278968 11.6626927,4.67288629 C12.6667394,4.81324138 13.4400003,5.67631838 13.4400003,6.72000015 C13.4400003,7.76368192 12.6667394,8.62675893 11.6618715,8.76757721 C12.2732337,9.57721063 12.2095887,10.7339269 11.4717578,11.4717578 C10.7339269,12.2095887 9.57721063,12.2732337 8.76711401,11.6626927 C8.62675893,12.6667394 7.76368192,13.4400003 6.72000015,13.4400003 C5.67631838,13.4400003 4.81324138,12.6667394 4.67242309,11.6618715 C3.86278968,12.2732337 2.70607336,12.2095887 1.96824248,11.4717578 C1.23041159,10.7339269 1.16676664,9.57721063 1.77730761,8.76711401 C0.773260914,8.62675893 -3.55271368e-15,7.76368192 -3.55271368e-15,6.72000015 C-3.55271368e-15,5.67631838 0.773260914,4.81324138 1.77812878,4.67242309 C1.16676664,3.86278968 1.23041159,2.70607336 1.96824248,1.96824248 C2.70607336,1.23041159 3.86278968,1.16676664 4.67288629,1.77730761 C4.81324138,0.773260914 5.67631838,0 6.72000015,0 Z"
                    id="Combined-Shape"
                    fill="#008A3B"
                  ></path>
                  <polyline
                    id="Path-2"
                    stroke="#FFFFFF"
                    stroke-width="1.20960001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    points="3.96000016 6.86884066 5.82229217 8.85040848 9.4581821 4.88727284"
                  ></polyline>
                </g>
              </g>
            </svg>
          </span>
          <p className="text-xs">
            {"Senior Content Writer"}
          </p>
        </div>
      </div>
      <p>
        {/* {authorDetails?.createdUser?.role || "Author Description"} */}
        {/* <Link to="/" className="text-blue">
          Read Full Bio
        </Link> */}
      </p>
    </div>
  );
};

export default AboutAuthor;
