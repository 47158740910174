import { list } from "@material-tailwind/react";
import { Description } from "@mui/icons-material";
import { title } from "process";
import React, { SVGProps } from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";
import CourseSubjects from "../eligibility/CourseSubjects";
import config from '../../../../util/config';

export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}
const interestedCourses = [
  {
    name: "Agricultural Engineering",
    url: config.apiUrl + "/course-details/agricultural-engineering",
  },
  {
    name: "Aerospace Engineering",
    url: config.apiUrl + "/course-details/aerospace-engineering",
  },
  {
    name: "Automobile Engineering",
    url: config.apiUrl + "/course-details/automobile-engineering",
  },
  {
    name: "Architecture Engineering",
    url: config.apiUrl + "/course-details/architecture-engineering",
  },
  {
    name: "Biotechnology Engineering",
    url: config.apiUrl + "/course-details/biotechnology-engineering",
  },
  {
    name: "Biomedical Engineering",
    url: config.apiUrl + "/course-details/biomedical-engineering",
  },
  {
    name: "Civil Engineering",
    url: config.apiUrl + "/course-details/civil-engineering",
  },
  {
    name: "Chemical Engineering",
    url: config.apiUrl + "/course-details/chemical-engineering",
  },
  {
    name: "Computer Science Engineering",
    url: config.apiUrl + "/course-details/computer-science-engineering",
  },
  {
    name: "Electrical Engineering",
    url: config.apiUrl + "/course-details/electrical-engineering",
  },
  {
    name: "Environmental Engineering",
    url: config.apiUrl + "/course-details/environmental-engineering",
  },
  {
    name: "Electronics and Communication Engineering",
    url: config.apiUrl + "/course-details/Electronics-and-Communication-Engineering",
  },
  {
    name: "Food Technology Engineering",
    url: config.apiUrl + "/course-details/food-technology",
  },
  {
    name: "Genetic Engineering",
    url: config.apiUrl + "/course-details/genetic-engineering",
  },
  {
    name: "Information Technology",
    url: config.apiUrl + "/course-details/information-technology",
  },
  {
    name: "Marine Engineering",
    url: config.apiUrl + "/course-details/marine-engineering",
  },
  {
    name: "Mechanical Engineering",
    url: config.apiUrl + "/course-details/mechanical-engineering",
  },
  {
    name: "Mining Engineering",
    url: config.apiUrl + "/course-details/mining-engineering",
  },
  {
    name: "Medical Transcriptions",
    url: config.apiUrl + "/course-details/medical-transcription",
  },
  {
    name: "Nanotechnology Engineering",
    url: config.apiUrl + "/course-details/nano-technology-engineering",
  },
  {
    name: "Ocean Engineering",
    url: config.apiUrl + "/course-details/naval-architecture-and-ocean-engineering",
  },
  {
    name: "Pharmaceutical Engineering",
    url: config.apiUrl + "/course-details/pharmaceutical-engineering-and-technology",
  },
  {
    name: "Petroleum Engineering",
    url: config.apiUrl + "/course-details/petroleum-engineering",
  },
  {
    name: "Rubber and Plastic Engineering",
    url: config.apiUrl + "/course-details/rubber-technology",
  },
  {
    name: "Robotics Engineering",
    url: config.apiUrl + "/course-details/robotics-engineering",
  },
  {
    name: "Textile Engineering",
    url: config.apiUrl + "/course-details/textile-engineering",
  },
];
const CourseCurriculamMecContent = {
  title: "Course Curriculam of Mechanical Engineering",
  description:
    "Students enrolled in mechanical engineering courses will delve into a variety of topics. These include the application of modern analytical, computational, and simulation tools to tackle challenges in mechanical and related engineering fields. They will also acquire knowledge on how to plan, design, construct, enhance, and maintain technically sound  and economical mechanical engineering systems. Furthermore, these courses aim to teach various skills in candidates, such as an ethical disposition, professional attitude, team spirit, and a lifelong learning mindset to achieve their organizational and career goals.",
};
const eligibilityCriteriaMecContent = {
  title: "Eligibility Criteria (UG & PG) of Mechanical Engineering",
  description:
    "The aspirants must fulfil certain eligibility requirements specified by the institute to be eligible for admissions. Generally for an undergraduate course, the candidates need to have completed their 10+2 in Non-Medical stream with minimum aggregate score specified by the desired institute. For a postgraduate course, candidates require a Bachelor’s degree in Mechanical Engineering or related field.",
};
const eligibilityCriteriaUGContent = {
  title: "Eligibility Criteria for UG Courses",
  description:
    "The eligibility criteria for Mechanical Engineering at undergraduate courses depends on the institute. Typically, to enrol in an undergraduate programme, students must have finished their 10+2 education in Non-Medical stream and achieved a minimum cumulative score desired by the institute. Candidates can check the eligibility criteria on the official website of the desired institute.",
  list: [
    "Aspirants must have cleared their Higher Secondary Examination with Mathematics, Physics, and Chemistry as the core subjects.",
    "They must have acquired a minimum of 50 per cent in their Class 12 Examination.",
    "Candidates with a three-year Diploma in a related field from a recognized institute are eligible to apply for Lateral Entry.",
  ],
};

const CourseCurriculamMecEngineering: React.FC = () => {
  const { data } = useCourseDetails();
  const { courseTitle = "", course_curriculum } = data || {};
  return (
    <>
      <div className="flex flex-col gap-3 bg-[#fff]">
        <div className="flex flex-col lg:flex-row">
          <div className="w-full flex flex-col gap-12">
            <div id="course-curriculam">
              <div className="flex max-md:w-full">
                <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
                  {`Course Curriculum of ${courseTitle}`}
                </h3>
              </div>

              <p className="text-base font-normal text-[#747474] leading-7 px-2 py-8 md:py-5 md:px-9">
                {course_curriculum?.description}
              </p>
            </div>
            <div className="w-full flex flex-col gap-12">
              <CourseSubjects />
            </div>
          </div>
          <div className="w-full lg:w-3/12">
            <div className="bg-[#EFF] w-full h-full flex justify-center items-center">
              <div className="application-forms-section bg-[#EFF] px-6 py-14">
                <div className="bg-[#fff]">
                  <h2 className="text-sm font-bold mb-4 bg-[#173CBA] text-[#fff] py-2 px-1">
                    Courses You May Be Interested In
                  </h2>
                  <ul className="list-none pl-5">
                    {interestedCourses.map((course, index) => (
                      <li key={index} className="border-b border-gray-300 py-2">
                        <a
                          href={course.url}
                          className="text-[#747474] hover:text-[#173CBA]"
                        >
                          {course.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div className="view-all-button bg-[#0C9] h-8 text-center">
                    <button className="flex mx-auto  text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full group">
                      <span className="mr-2">View All</span>
                      <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col gap-4 px-9 py-12 relative">
            <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-9 py-2 absolute top-0 left-0 ">
              {`Eligibility Criteria (UG & PG) of ${courseTitle}`}
            </h3>

            <p className="text-base font-normal text-[#747474] leading-7">
              {eligibility_criteria?.postgraduate.description}
              
            </p>
            <ul className="list-decimal pl-5">
              {eligibility_criteria?.postgraduate?.points.map(
                (item: any, index: number) => (
                  <li
                    key={index}
                    className="text-base font-normal text-[#747474] leading-7"
                  >
                    {item}
                  </li>
                )
              )}
            </ul>
          </div> */}
        {/* <div className="flex flex-col gap-4 px-9 py-12 relative">
            <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-9 py-2 absolute top-0 left-0 ">
              {eligibilityCriteriaUGContent.title}
            </h3>

            <p className="text-base font-normal text-[#747474] leading-7">
              {eligibility_criteria?.undergraduate?.description}
            </p>
            <ul className="list-decimal pl-5">
              {eligibility_criteria?.undergraduate?.points.map(
                (item: any, index: number) => (
                  <li
                    key={index}
                    className="text-base font-normal text-[#747474] leading-7"
                  >
                    {item}
                  </li>
                )
              )}
            </ul>
          </div> */}

        <div className="bg-white w-1/5"></div>
      </div>
    </>
  );
};

export default CourseCurriculamMecEngineering;
