import React from 'react';

const Disclaimer: React.FC = () => {
  return (
    <div className="my-7 container mx-auto p-6  text-black rounded-lg shadow-lg">
      <h2 className="font-bold text-lg mb-2">Disclaimer</h2>
      <p className="mb-2">
        All the information displayed is notional. Utmost care is taken to present accurate and updated information. We apologize if there is any incorrect information, and the company doesn't take any legal responsibility for this.
      </p>
      <p>
        We would like to acknowledge Freepik, Shutterstock, Wikipedia, official websites of colleges, government websites, and some news websites from where images are sourced.
      </p>
    </div>
  );
};

export default Disclaimer;
