import React, { useState } from "react";
import TitleCard from "../../components/TitleCard";
import BlogInfoCard from "../../components/BlogInfoCard";
import BlogHoverContainer from "../../components/BlogHoverContainer";

interface ArticlesAndLatestNewsProps {
  data?: any;
  title: string;
  secondaryTitle: string;
}
const ArticlesAndLatestNews: React.FC<ArticlesAndLatestNewsProps> = ({
  data = {},
  title,
  secondaryTitle,
}) => {
  const [hoveredContainerItem, setHoveredContainerItem] = useState(
    data[0]?.topArticlesAndBlogsAndLatestNews?.subcategories["Latest News"]
      ?.results[0]
  );
  // console.log(
  //   "ArticlesAndLatestNews",
  //   data[0]?.topArticlesAndBlogsAndLatestNews?.subcategories["Latest News"]
  // );

  const getCurrentHoveredBlog = (param: any) => {
    // console.log("first", param);
    setHoveredContainerItem(param);
  };
  return (
    <div className="container grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 sm:gap-y-0 ">
      <div className="flex flex-col hidden lg:border-r-2 lg:pr-4 md:flex">
        <TitleCard title={title} bgColor="#00CC99" color="#ffffff" />
        <div className="flex flex-col justify-between flex-1 divide-y-2 divide-grey-3">
          {data[0]?.topArticlesAndBlogsAndLatestNews?.subcategories[
            "Articles & Blogs"
          ]?.results
            ?.slice(0, 5)
            ?.map((blogCard: any, item: any) => (
              <BlogInfoCard
                dateValue={false}
                rowReverse={false}
                blogInfo={blogCard}
                getCurrentHoveredItem={getCurrentHoveredBlog}
                height="auto"
                categoryOrderReverse={false}
                borderBottom={true}
                imageField={true}
              />
            ))}
        </div>
      </div>
      <div className="visible col-span-2 lg:border-r-2 lg:pr-4 max-sm:hidden">
        <BlogHoverContainer
          blogInfo={""}
          hoveredContainerItem={hoveredContainerItem}
        />
      </div>
      <div className="flex flex-col">
        {" "}
        <TitleCard title={secondaryTitle} bgColor="#00CC99" color="#ffffff" />
        <div className="flex flex-col justify-between flex-1 divide-y-2 divide-grey-3">
          {data[0]?.topArticlesAndBlogsAndLatestNews.subcategories[
            "Latest News"
          ]?.results
            ?.slice(0, 5)
            ?.map((blogCard: any, item: any) => (
              <BlogInfoCard
                blogInfo={blogCard}
                dateValue={true}
                rowReverse={false}
                getCurrentHoveredItem={getCurrentHoveredBlog}
                height="auto"
                categoryOrderReverse={false}
                borderBottom={true}
                imageField={true}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ArticlesAndLatestNews;
