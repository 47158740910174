import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseURL from "../../services/api";
import registrationFormSlice from "../slices/registrationFormSlice";

interface SendOtpPayload {
  mobile: string;
}

interface VerifyOtpPayload {
  mobile: string;
  otp: string;
}

interface SignupPayload {
  // mobile: string;

  streamId: number;
  courseId: number;
  state: number;
  city: number;
}

interface RegisterUserPayload {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  otp: string;
  currentLevelOfStudy: string;
  degree: string;
  stream: string;
  course: string;
  state: string;
  // preferredStates: [];
  [key: string]: any;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  // otp: string;
  currentLevelOfStudy: string;
  lookingForDegree: string;
  lookingForStream: string;
  lookingForCourse: string;
  state: string;
  // preferredStates: [];
  [key: string]: any;
}

interface ApiResponse {
  message: string;
  success: boolean;
  accessToken?: string;
  user?: any;
  refreshToken?: string;
  [key: string]: any;
}

//  sending OTP
export const sendOtp = createAsyncThunk<ApiResponse, SendOtpPayload>(
  "auth/sendOtp",
  async ({ mobile }, { rejectWithValue }) => {
    try {
      const response = await BaseURL.get(
        `/api/auth/mobile-otp-generation?mobileNumber=${mobile}`
      );
      return response?.data;
    } catch (error: any) {
      console.log("Error sending OTP");
      return rejectWithValue(error.response?.data || "Error sending OTP");
    }
  }
);

// verifying OTP
export const verifyOtp = createAsyncThunk<ApiResponse, VerifyOtpPayload>(
  "auth/verifyOtp",
  async ({ mobile, otp }, { rejectWithValue }) => {
    try {
      const response = await BaseURL.post(`/api/auth/verify-otp`, {
        mobileNumber: mobile,
        otp,
      });
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Invalid OTP");
    }
  }
);

// call signup API after OTP verification
export const signupPAFDetail = createAsyncThunk<
  ApiResponse,
  SignupPayload,
  { state: any }
>(
  "auth/signupInfoPafDetails",
  async (payload, { getState, rejectWithValue }) => {
    const { streamId, courseId, state, city } = payload;
    const { auth } = getState();
    const token = auth.accessToken;

    try {
      const response = await BaseURL.post(`api/auth/signup-info`, {
        streamId,
        courseId,
        state,
        city,
      });
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || { message: "Signup failed", success: false }
      );
    }
  }
);

export const Signup = createAsyncThunk<ApiResponse, FormData, { state: any }>(
  "auth/signUp",
  async (payload, { getState, rejectWithValue }) => {
    console.log("Signup", payload);
    const { auth } = getState();
    const token = auth.accessToken;

    try {
      const response = await BaseURL.post(`/api/auth/signup`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Invalid OTP");
    }
  }
);

// Login with OTP
export const Login = createAsyncThunk<ApiResponse, VerifyOtpPayload>(
  "auth/login",
  async ({ mobile, otp }, { rejectWithValue }) => {
    try {
      const response = await BaseURL.post(`/api/auth/login`, {
        mobileNumber: mobile,
        otp,
      });
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Invalid OTP");
    }
  }
);
