import React, { useState } from "react";

interface TagButtonProps {
  icon: string;
  text: string;
}

const TagButton: React.FC<TagButtonProps> = ({ icon, text }) => {
  return (
    <div className="flex gap-1 px-3 py-1 text-sm leading-7 text-white bg-[#173CBA]">
      <img
        loading="lazy"
        src={icon}
        alt=""
        className="object-contain shrink-0 my-auto bg-[#173CBA] aspect-square h-[15px] w-[15px]"
      />
      <div className="basis-auto">{text}</div>
    </div>
  );
};

interface ClassButtonProps {
  className: string;
  isActive: boolean;
  onClick: () => void;
}

const ClassButton: React.FC<ClassButtonProps> = ({
  className,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={`flex gap-1 items-center mt-2 text-sm text-white whitespace-nowrap cursor-pointer ${
        isActive ? "" : ""
      }`}
      onClick={onClick}
    >
      <div
        className={`flex shrink-0 self-stretch my-auto rounded-full border border-solid border-black h-[9px] w-[9px] ${
          isActive ? "bg-[#0C9]" : "bg-white"
        }`}
      />
      <div className="self-stretch my-auto">{className}</div>
    </div>
  );
};

interface SubjectButtonProps {
  subject: string;
}

const SubjectButton: React.FC<SubjectButtonProps> = ({ subject }) => {
  return (
    <div
      className="px-2 py-2  bg-[#A3A2AC] "
      style={{ borderRadius: "2px 2px 0px 0px" }}
    >
      {subject}
    </div>
  );
};

interface UniversityCardProps {
  logo: string;
  alt: string;
}

const UniversityCard: React.FC<UniversityCardProps> = ({ logo, alt }) => {
  return (
    <div className="flex flex-col justify-center items-center px-2 bg-white h-[73px] w-[73px]">
      <img
        loading="lazy"
        src={logo}
        alt={alt}
        className="object-contain aspect-square w-[59px]"
      />
    </div>
  );
};

interface ConnectWithTopUniversitiesProps {}

const ConnectWithTopUniversities: React.FC<
  ConnectWithTopUniversitiesProps
> = () => {
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const subjects = ["PCM", "PCB", "PCBM", "Commerce", "Others"];
  const classes = ["9th", "10th", "11th", "12th"];
  const tags = [
    {
      icon: "/images/applicationToAdmission/tagbutton1.png",
      text: "Plan Ahead",
    },
    {
      icon: "/images/applicationToAdmission/tagbutton2.png",
      text: "Entrance Application",
    },
    {
      icon: "/images/applicationToAdmission/tagbutton3.png",
      text: "Campus Tour",
    },
    {
      icon: "/images/applicationToAdmission/tagbutton4.png",
      text: "Counselling Support",
    },
  ];
  const universities = [
    {
      logo: "/images/applicationToAdmission/university1.png",
      alt: "University logo",
    },
    {
      logo: "/images/applicationToAdmission/university2.png",
      alt: "University logo",
    },
    {
      logo: "/images/applicationToAdmission/university3.png",
      alt: "University logo",
    },
    {
      logo: "/images/applicationToAdmission/university4.png",
      alt: "University logo",
    },
    {
      logo: "/images/applicationToAdmission/university5.png",
      alt: "University logo",
    },
  ];

  return (
    <div className="flex flex-col mb-6">
      <div className="flex relative flex-col justify-center items-center px-20 py-16 w-full min-h-[510px] max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="/images/applicationToAdmission/top-universities-bg.png"
          alt=""
          className="object-cover absolute inset-0 size-full"
        />
        <div className="flex relative flex-col w-full max-w-[1064px] max-md:max-w-full">
          <div className="flex flex-wrap gap-1 self-start">
            {tags.map((tag, index) => (
              <TagButton key={index} icon={tag.icon} text={tag.text} />
            ))}
          </div>
          <div className="max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-[58%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col items-start px-12 py-7 mx-auto w-full bg-[rgba(56,56,56,0.76)]   bg-opacity-80 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-6 max-w-full text-white w-[400px]">
                    <div className="grow text-base leading-none">
                      Current Class & Subject
                    </div>
                    <div className="flex gap-2 text-sm">
                      <div className="gap-7 self-stretch px-2.5 rounded-3xl border border-[#0c9] border-solid">
                        From India
                      </div>
                      <div className="gap-7 self-stretch px-2.5 rounded-3xl border border-[#0c9] border-solid">
                        From Abroad
                      </div>
                    </div>
                  </div>

                  {classes.map((className) => (
                    <ClassButton
                      key={className}
                      className={className}
                      isActive={selectedClass === className}
                      onClick={() => setSelectedClass(className)}
                    />
                  ))}
                  <div className="mt-6 text-xs leading-5 text-white">
                    Your Preferred Subjects in 11th & 12th
                  </div>
                  <div className="flex gap-[2px] mt-2 text-sm leading-none text-white whitespace-nowrap cursor-pointer ">
                    {subjects.map((subject, index) => (
                      <SubjectButton key={index} subject={subject} />
                    ))}
                  </div>

                  <div className="flex gap-4 mt-8 max-w-full w-[205px]">
                    <button className="flex items-center text-sm rounded  leading-none text-white whitespace-nowrap max-w-[46px] bg-[#747474] py-2 px-1.5">
                      Next
                    </button>
                    <button className="px-3 py-2 text-sm  rounded text-white bg-[#0C9] items-center flex">
                      Map My Mentor
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="12"
                        viewBox="0 0 15 12"
                        fill="none"
                        className="ml-2"
                      >
                        <path
                          d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[42%] max-md:ml-0 max-md:w-full ">
                <div className="flex flex-col p-4 mx-auto w-full  max-md:mt-10 bg-[#173CBA] bg-opacity-70">
                  <h2 className="mx-2.5 text-3xl font-light leading-10 text-center text-white">
                    Connect with{" "}
                    <span className="font-semibold text-[#0c9]">
                      100+ Top Universities
                    </span>{" "}
                    for Admissions <br /> India & Abroad
                  </h2>
                  <div className="flex gap-1 mt-6 mx-auto">
                    {universities.map((university, index) => (
                      <UniversityCard
                        key={index}
                        logo={university.logo}
                        alt={university.alt}
                      />
                    ))}
                  </div>

                  <button className="px-3 py-2 text-sm  rounded text-white bg-[#0C9] self-center mt-7 items-center flex">
                    Connect Me
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="12"
                      viewBox="0 0 15 12"
                      fill="none"
                      className="ml-2"
                    >
                      <path
                        d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWithTopUniversities;
