import React, { useState } from "react";
import FAQV2 from "../../../../components/FAQV2";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const KnowledgeHub = () => {
  const scholarshipFaq = useSelector((state: RootState) => state.scholarship.scholarshipFaq);
  const educationalFaq = useSelector((state: RootState) => state.scholarship.educationLoanFaqs);

  const [activeTab, setActiveTab] = useState("scholarship");

  return (
    <>   
    <div className="bg-[#EEF2FF] py-12">
        <div className="container">
     <div className="text-gray-800 font-bold text-[40px] leading-[60px] font-sans">knowledge Hub (FAQ's)</div>
    <div className="pt-4">
      <div className="flex space-x-12 mb-4 ">
        <button
          onClick={() => setActiveTab("scholarship")}
          className={`py-2 px-4 rounded-2xl transition-colors duration-200 border border-[#173CBA] ${
            activeTab === "scholarship" ? "bg-[#173CBA] text-white" : ""
          }`}
        >
          Scholarship FAQ
        </button>
        <button
          onClick={() => setActiveTab("education")}
          className={`py-2 px-4 rounded-2xl transition-colors duration-200 border border-[#173CBA] ${
            activeTab === "education" ? "bg-[#173CBA] text-white" : ""
          }`}
        >
          Educational Loan FAQ
        </button>
      </div>

      <div className="border rounded-lg pt-12 ">
        {activeTab === "scholarship" && <FAQV2 questionsAndAnswers={scholarshipFaq} />}
        {activeTab === "education" && <FAQV2 questionsAndAnswers={educationalFaq} />}
      </div>
    </div></div></div>
    </>
  );
};

export default KnowledgeHub;
