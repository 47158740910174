import { FC, useState } from "react";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import WebinarCard from "../../../../components/blogs-and-articles/WebinarCard";
import BlogCard from "../../../../components/blogs-and-articles/BlogCard";
import VideoCard from "../../../../components/blogs-and-articles/VideoCard";
import Modal from "../../../../components/Modal";
import VideoModal from "../../../../components/blogs-and-articles/VideoModal";
import VisualStory from "../../../../components/VisualStory";
import VisualStoryCard from "../../../../components/blogs-and-articles/VisualStoryCard-new";
import ApplyNowForm from "../../../../components/applynow/ApplyNowForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

interface BlogsAndArticles {
  webinar: any[];
  articles: any[];
  spotLight: any[];
  news: any[];
  visualStories: any[];
}

interface WebinarAndArticlesProps {
  blogsAndArticles: BlogsAndArticles | null;
}

const WebinarAndArticles: React.FC<WebinarAndArticlesProps> = ({blogsAndArticles}) => {
 
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const changeActiveTab = (index: number) => setActiveTabIndex(index);
  const [leadGenerationPopupIsOpen, setLeadGenerationPopupIsOpen] =
    useState(false);
  const [videoModalURL, setVideoModalURL] = useState<string | null>(null);
  const [visualStoryIndex, setVisualStoryIndex] = useState<number>(-1);

  if (!blogsAndArticles) return;

  const isVisualStoryOpen =
    visualStoryIndex >= 0 && visualStoryIndex < blogsAndArticles?.visualStories.length;

  const tabs = [
    {
      name: "Articles",
      content: blogsAndArticles.articles.map((data) => <BlogCard {...data} />),
    },
    {
      name: "Spotlights",
      content: blogsAndArticles.spotLight.map((data) => (
        <VideoCard
          {...data}
          openVideoModal={() => setVideoModalURL(data.link)}
        />
      )),
    },
    { name: "News", content: blogsAndArticles.news.map((data) => <BlogCard {...data} />) },
    {
      name: "Visual Stories",
      content: blogsAndArticles.visualStories.map((data, index) => (
        <VisualStoryCard
          {...data}
          openVisualStory={() => setVisualStoryIndex(index)}
        />
      )),
    },
    {
      name: "Webinar",
      content: blogsAndArticles.webinar.map((data) => (
        <WebinarCard
          {...data}
          handlePopup={() => setLeadGenerationPopupIsOpen(true)}
        />
      )),
    },
   
 
   
  ];

  return (
    <>
      <div className="mt-12">
        <div className="flex justify-center text-grey">
          {tabs.map((tab, index) => (
            <button
              className={`text-xs lg:text-sm px-4 py-2 ${
                activeTabIndex == index
                  ? "bg-blue text-white font-semibold"
                  : ""
              }`}
              onClick={() => changeActiveTab(index)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <div className="bg-blue">
          <div className="container py-12">
            <CustomSwiper
              items={tabs[activeTabIndex].content}
              navigation={"bottom"}
              autoplay={true}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 20 },
                1024: { slidesPerView: 3, spaceBetween: 20 },
                1280: { slidesPerView: 4, spaceBetween: 30 },
              }}
              progressBar={true}
            />
          </div>
        </div>
      </div>
      <LeadGenerationPopup
        isOpen={leadGenerationPopupIsOpen}
        onClose={() => setLeadGenerationPopupIsOpen(false)}
      />
      {videoModalURL && (
        <VideoModal
          link={videoModalURL}
          closeModal={() => setVideoModalURL(null)}
        />
      )}
      <VisualStory
        isOpen={isVisualStoryOpen}
        slides={
          (isVisualStoryOpen &&
            getVisualStorySlides(
              blogsAndArticles?.visualStories[visualStoryIndex].slides
            )) ||
          []
        }
        handleClose={() => {
          setVisualStoryIndex(-1);
        }}
      />
    </>
  );
};

export default WebinarAndArticles;

const getVisualStorySlides = (
  slides: {
    title: string;
    description: string;
    image: string;
  }[]
) => {
  return slides.map((slide) => ({
    image: slide.image,
    content: (
      <div className="p-4">
        <h1 className="text-xl text-white font-bold text-center">
          {slide.title}
        </h1>
        <p className="mt-2 text-base text-white text-justify">
          {slide.description}
        </p>
      </div>
    ),
  }));
};

export const LeadGenerationPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ApplyNowForm />
    </Modal>
  );
};
