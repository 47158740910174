import React from "react";

const ProcessSteps = () => {
  return (
    <div className="w-full bg-[#061958] flex flex-col gap-10 pb-10">
      <div className="flex  max-md:flex-row justify-between items-center text-5xl font-light leading-none text-center text-[#0C9] py-10 px-[50px] max-md:text-4xl">
        <div className="shrink-0 self-stretch my-auto h-px border border-solid border-white border-opacity-50 max-md:w-[40px] md:w-[514px]" />
        <h2 className="self-stretch my-auto max-md:text-4xl">The Process</h2>
        <div className="shrink-0 self-stretch my-auto h-px border border-solid border-white border-opacity-50 max-md:w-[40px] md:w-[546px]" />
      </div>
      <div className="flex flex-wrap items-center md:px-[100px] gap-5 py-10">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
          <h3 className="text-4xl font-semibold leading-none text-[#0c9] ">
            Pre-Tour Preparation
          </h3>
          <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
            <div className="max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                Review Your Goals
              </span>
              : Your College mentor can help you clarify what you're looking for
              in a college, whether it's specific academic programs, campus
              culture, or extracurricular activities.
            </div>
            <div className="mt-4 max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                Research Colleges Together
              </span>
              : Use your mentor's experience to research colleges, discuss
              potential options, and prioritize which campuses to visit based on
              your interests and goals.
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl">
          1
        </div>
        <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] w-[572px] max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/applicationToAdmission/process-step1.png"
            alt="Pre-tour preparation visual"
            className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
          />
        </div>
      </div>

      <div className="flex flex-wrap items-center md:px-[100px] gap-5 py-10">
        <div className="gap-14 flex flex-row max-md:flex-col">
          <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] md:w-[572px] max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/applicationToAdmission/process-step2.png"
              alt="Pre-tour preparation visual"
              className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
            <h3 className="text-4xl font-semibold leading-none text-[#0c9] ">
              Develop a Customized Tour Plan
            </h3>
            <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
              <div className="max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  Create an Itinerary :
                </span>
                Work with your mentor to develop a detailed tour schedule,
                including guided tours, information sessions, and any meetings
                with faculty or admissions staff.
              </div>
              <div className="mt-4 max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  List Key Questions :
                </span>
                Your mentor can help you come up with insightful questions to
                ask during the tour, tailored to your academic and personal
                interests.
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl">
          2
        </div>
      </div>
      <div className="flex flex-wrap gap-5 items-center md:px-[100px] py-10">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
          <h3 className="text-4xl font-semibold leading-none text-[#0c9] ">
            Maximize Your Campus Visit
          </h3>
          <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
            <div className="max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                Focus on Priorities :
              </span>
              With your college mentor, determine which aspects of the campus
              visit are most important to you and ensure these are covered
              during the tour.
            </div>
            <div className="mt-4 max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                Engage Effectively :
              </span>
              Practice asking questions and engaging with tour guides and
              current Buddy mentor students based on advice from your mentor to
              get the most out of your interactions.
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl">
          3
        </div>
        <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] w-[572px] max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/applicationToAdmission/process-step3.png"
            alt="Pre-tour preparation visual"
            className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
          />
        </div>
      </div>
      <div className="flex flex-wrap items-center md:px-[100px] gap-5 py-10">
        <div className="gap-14 flex flex-row max-md:flex-col">
          <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] md:w-[572px] max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/applicationToAdmission/process-step4.png"
              alt="Pre-tour preparation visual"
              className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
            <h3 className="text-4xl font-semibold leading-none text-[#0c9] ">
              Evaluate and Reflect
            </h3>
            <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
              <div className="max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  Discuss Impressions :
                </span>
                After each campus visit, have a debriefing session with your
                mentor to discuss your impressions, feelings, and any notable
                observations.
              </div>
              <div className="mt-4 max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  Compare Notes :
                </span>
                Use your mentor’s guidance to help compare the colleges you’ve
                visited, focusing on how each aligns with your academic and
                personal goals. Consider Follow-Up Actions:
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl">
          4
        </div>
      </div>
      <div className="flex flex-wrap gap-5 items-center md:px-[100px] py-10">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
          <h3 className="text-4xl font-semibold leading-none text-[#0c9] ">
            Plan Additional Visits
          </h3>
          <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
            <div className="max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">If necessary :</span>
              work with your college mentor to arrange follow-up visits or
              meetings with faculty or current students for deeper insights.
            </div>
            <div className="mt-4 max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                Review Application Requirements :
              </span>
              Your College mentor can help you understand the application
              process for the colleges you’re interested in, including deadlines
              and required materials.
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl">
          5
        </div>
        <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] w-[572px] max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/applicationToAdmission/process-step5.png"
            alt="Pre-tour preparation visual"
            className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
          />
        </div>
      </div>
      <div className="flex flex-wrap items-center md:px-[100px] gap-5 py-10">
        <div className="gap-14 flex flex-row max-md:flex-col">
          <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] md:w-[572px] max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/applicationToAdmission/process-step6.png"
              alt="Pre-tour preparation visual"
              className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
            <h3 className="text-4xl font-semibold leading-none text-[#0c9] ">
              Make a Decision
            </h3>
            <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
              <div className="max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  Weigh Your Options :
                </span>
                Use the feedback and reflections from your mentor to help weigh
                the pros and cons of each college.
              </div>
              <div className="mt-4 max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  Final Decision :
                </span>
                With your mentor’s support, make a final decision based on a
                comprehensive evaluation of each college and how well they meet
                your criteria. Having a college mentor involved in your campus
                tours can provide valuable guidance and ensure you’re making
                well-informed decisions about your future.
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl">
          6
        </div>
      </div>
    </div>
  );
};

export default ProcessSteps;
