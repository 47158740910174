import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FilterActions, FilterCategory } from "../../../components/filter/FilterEnum";

interface ICategory {
  key: string;
  type:
    | FilterCategory.MultiSelect
    | FilterCategory.Radio
    | FilterCategory.SingleSelect;
  showinPath: boolean;
}

interface IMultiSelectFilters {
  [key: string]: Set<string>;
}

interface ISingleSelectFilters {
  [key: string]: string;
}

interface IRadioFilters {
  [key: string]: string;
}

interface ISelectedFilter {
  multiSelectFilters: IMultiSelectFilters;
  singleSelectFilters: ISingleSelectFilters;
  radioFilters: IRadioFilters;
}

interface IFilterDataForApiBody {
  [key: string]: string[] | string;
}

interface IUpdateSelectedFilter {
  (
    category: string,
    action:
      | FilterActions.AddMultiSelect
      | FilterActions.RemoveMultiSelect
      | FilterActions.ChangeSingleSelect
      | FilterActions.ChangeRadio,
    value: string
  ): void;
}

interface IClearFilter {
  (): void;
}

interface IGetPathFromFilterData {
  ({}: { [key: string]: string }): string;
}

interface IGetFilterDataFromPath {
  (path: string | undefined): { [key: string]: string };
}

const useSelectedFilter = (
  categories: ICategory[],
  basePath: string,
  getPathName: IGetPathFromFilterData,
  getPathData: IGetFilterDataFromPath
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [multiSelectFilters, setMultiSelectFilters] =
    useState<IMultiSelectFilters>({});
  const [singleSelectFilters, setSingleSelectFilters] =
    useState<ISingleSelectFilters>({});
  const [radioFilters, setRadioFilters] = useState<IRadioFilters>({});
  const [isURLParamsScanned, setIsUrlParamsScanned] = useState(false);
  const { pageTitle } = useParams();
  const navigate = useNavigate();

  const filterDataForApiBody: IFilterDataForApiBody | null = useMemo(() => {
    // not to fetch from api till url params are scanned initially
    if (!isURLParamsScanned) return null;

    const newFilterDataForApiBody: IFilterDataForApiBody = {};
    // Object.entries(multiSelectFilters).forEach(([category, values]) => {
    //   newFilterDataForApiBody[category] = Array.from(values);
    // });
    // Object.entries(singleSelectFilters).forEach(([category, value]) => {
    //   newFilterDataForApiBody[category] = Array.of(value);
    // });
    Object.entries(multiSelectFilters).forEach(([category, values]) => {
      newFilterDataForApiBody[category] = Array.from(values).join(', '); // Join values into a string
    });
    
    Object.entries(singleSelectFilters).forEach(([category, value]) => {
      newFilterDataForApiBody[category] = value; // Directly assign the single value as a string
    });
    Object.entries(radioFilters).forEach(([category, value]) => {
      newFilterDataForApiBody[category] = value;
    });
    return newFilterDataForApiBody;
  }, [multiSelectFilters, singleSelectFilters, radioFilters]);

  // on first laod, store url params in state
  useEffect(() => {
    const multiSelectFilters: IMultiSelectFilters = {};
    const singleSelectFilters: ISingleSelectFilters = {};
    const radioFilters: IRadioFilters = {};

    categories.forEach(({ key, type }) => {
      const pathParams = getPathData(pageTitle);
      if (pathParams[key]) {
        if (type == FilterCategory.MultiSelect)
          multiSelectFilters[key] = new Set([pathParams[key]]);
        else if (type == FilterCategory.SingleSelect)
          singleSelectFilters[key] = pathParams[key];
        else if (type == FilterCategory.Radio)
          radioFilters[key] = pathParams[key];
      } else if (searchParams.has(key)) {
        if (type == FilterCategory.MultiSelect)
          multiSelectFilters[key] = new Set(searchParams.get(key)?.split(","));
        else if (type == FilterCategory.SingleSelect)
          singleSelectFilters[key] = searchParams.get(key) || "";
        else if (type == FilterCategory.Radio)
          radioFilters[key] = searchParams.get(key) || "";
      }
    });

    setMultiSelectFilters(multiSelectFilters);
    setSingleSelectFilters(singleSelectFilters);
    setRadioFilters(radioFilters);
    setIsUrlParamsScanned(true);
  }, []);

  // update url on filter change
  useEffect(() => {
    if (isURLParamsScanned) {
      const pathFilterData: { [key: string]: string } = {};
      const newParams = new URLSearchParams(searchParams);

      categories.forEach(({ key, type }) => {
        const showInPath = categories.find(
          (category) => category.key == key
        )?.showinPath;

        if (
          !multiSelectFilters[key] &&
          !singleSelectFilters[key] &&
          !radioFilters[key]
        )
          newParams.delete(key);
        else if (type == FilterCategory.MultiSelect) {
          if (showInPath && multiSelectFilters[key].size == 1) {
            pathFilterData[key] = Array.from(multiSelectFilters[key])[0];
            newParams.delete(key);
          } else
            newParams.set(key, Array.from(multiSelectFilters[key]).join(","));
        } else if (type == FilterCategory.SingleSelect) {
          if (showInPath) {
            pathFilterData[key] = singleSelectFilters[key];
            newParams.delete(key);
          } else newParams.set(key, singleSelectFilters[key]);
        } else if (type == FilterCategory.Radio) {
          if (showInPath) {
            pathFilterData[key] = radioFilters[key];
            newParams.delete(key);
          } else newParams.set(key, radioFilters[key]);
        }
      });

      const newPageTitle = getPathName(pathFilterData);
      navigate(`/${basePath}/${newPageTitle}?${newParams.toString()}`);
    }
    // setSearchParams((prevParams) => {
    //   return newParams;
    // });
  }, [multiSelectFilters, singleSelectFilters, radioFilters]);

  const updateSelectedFilter: IUpdateSelectedFilter = (
    category,
    action,
    value
  ) => {
    if (action == FilterActions.ChangeRadio) {
      setRadioFilters((prev) => ({
        ...prev,
        [category]: value,
      }));
    } else if (action == FilterActions.AddMultiSelect) {
      addToMultiSelect(category, value, setMultiSelectFilters);
    } else if (action == FilterActions.RemoveMultiSelect) {
      removeFromMultiSelect(category, value, setMultiSelectFilters);
    } else if (action == FilterActions.ChangeSingleSelect) {
      changeSingleSelect(category, value, setSingleSelectFilters);
    }
  };

  const clearFilter: IClearFilter = () => {
    setMultiSelectFilters({});
    setSingleSelectFilters({});
  };

  return {
    selectedFilter: { multiSelectFilters, singleSelectFilters, radioFilters },
    updateSelectedFilter,
    clearFilter,
    filterDataForApiBody,
  };
};

const addToMultiSelect = (
  category: string,
  value: string,
  setMultiSelectFilters: Dispatch<SetStateAction<IMultiSelectFilters>>
) => {
  setMultiSelectFilters((prev) => {
    var newSet = new Set<string>();
    if (prev[category]) {
      prev[category].forEach((item) => newSet.add(item));
    }
    newSet.add(value);
    return {
      ...prev,
      [category]: newSet,
    };
  });
};

const removeFromMultiSelect = (
  category: string,
  value: string,
  setMultiSelectFilters: Dispatch<SetStateAction<IMultiSelectFilters>>
) => {
  setMultiSelectFilters((prev) => {
    var newSet = new Set<string>();
    if (prev[category]) {
      prev[category].forEach((item) => {
        if (item != value) newSet.add(item);
      });
    }

    if (newSet.size > 0)
      return {
        ...prev,
        [category]: newSet,
      };
    else {
      const newFilters = { ...prev };
      delete newFilters[category];
      return newFilters;
    }
  });
};

function changeSingleSelect(
  category: string,
  value: string,
  setSingleSelectFilters: Dispatch<SetStateAction<ISingleSelectFilters>>
) {
  setSingleSelectFilters((prev) => {
    if (prev[category] && prev[category] == value) {
      const newFilters = { ...prev };
      delete newFilters[category];
      return newFilters;
    } else return { ...prev, [category]: value };
  });
}

export default useSelectedFilter;

export type {
  ICategory,
  ISelectedFilter,
  IUpdateSelectedFilter,
  IClearFilter,
  IFilterDataForApiBody,
  IGetPathFromFilterData,
  IGetFilterDataFromPath,
};
