import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  nextStep,
  updateForm,
} from "../../../../store/slices/registrationFormSlice";
import OTPInput, { InputProps } from "react-otp-input";
import { GetWindowDimensions } from "../../../../hooks/window-hooks/WindowHooks";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import { RegisterAPI } from "../../../../services/authService";
import {
  sendOtp,
  signupPAFDetail,
  verifyOtp,
} from "../../../../store/thunks/authThunks";
// import { sendOtp } from "../../../../store/slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader/Loader";
import UserIcon from "../../../../images/authentication/user-circle-icon.svg";
import MailIcon from "../../../../images/authentication/mail-icon.svg";
import PhoneIcon from "../../../../images/authentication/phone-icon.svg";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckSquare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

interface PersonalInfoFormInputProps {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  otp: string;
}

const PersonalInfoForm = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { width } = GetWindowDimensions();

  const { firstName, lastName, email, mobileNumber, otp } = useSelector(
    (state: RootState) => state.registrationFormSlice.data
  );

  const { otpSent, error, message, loading, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const [isVerifyOtp, setIsVerifyOtp] = useState(false);
  const [otpValue, setOtp] = useState(otp);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useForm<PersonalInfoFormInputProps>();

  // const onSubmit: SubmitHandler<PersonalInfoFormInputProps> = (data) => {
  //   console.log("SubmitHandler", data);
  //   dispatch(nextStep());
  // };

  const onSubmit = (data: any) => {
    dispatch(updateForm({ ...data, otp: otpValue }));
    dispatch(nextStep());
  };

  const mobile = watch("mobileNumber");

  const handleVerifyOtp = () => {
    let SignUpPAFInfoPayload = {
      streamId: 0,
      courseId: 0,
      state: 0,
      city: 0,
    };
    // add logic for otp verify
    if (otpValue) {
      dispatch(verifyOtp({ mobile, otp: otpValue }))
        .unwrap()
        .then(() => {
          dispatch(signupPAFDetail(SignUpPAFInfoPayload));
        })
        .catch((err: any) => {
          console.error("OTP verification failed:", err);
        });
    }
    setIsVerifyOtp(!isVerifyOtp);
  };

  const handleSendOTP = () => {
    if (mobile?.length == 10) {
      dispatch(sendOtp({ mobile }));
    } else {
      console.log("Enter 10 digit Mobile number");
    }
  };

  // const toastId = useRef<number | string | null>(null);

  // useEffect(() => {
  //   if (message) {
  //     toast.success(message);
  //   }
  // }, [message]);

  // useEffect(() => {
  //   if (error) {
  //     toast.error(error);
  //   }
  // }, [error]);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleNext = () => {
    dispatch(nextStep());
  };
  return (
    <div className="flex flex-col w-full gap-2 max-3xl">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={true}
        showBack={false}
        stepName="Register"
        stepDescription="We at CollegeMentor aim to bridge the gap between colleges and students through mentorship"
      >
        <form
          className="flex flex-col w-full max-w-lg gap-2 mb-6 md:h-21rem"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex gap-3 max-sm:flex-wrap md:flex-nowrap">
            <div className="w-full md:w-1/2 md:mb-0">
              {/* <label
                className="block mb-2 text-xs font-bold tracking-wide text-black-700"
                htmlFor="grid-first-name"
              >
                First Name
              </label>
              <div>
                <div className="flex items-center px-3 py-3 border-2 border-grey-300 rounded focus-within:border-[#113cc0]">
                  <img src={UserIcon} width="20" height="20" />

                  <input
                    className="block w-full pl-2 leading-tight text-gray-700 outline-none "
                    id="grid-first-name"
                    type="text"
                    placeholder="First name"
                    defaultValue={firstName}
                    {...register("firstName")}
                  />
                </div>
              </div> */}
              <div className="">
                <label
                  htmlFor="input-field"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <img src={UserIcon} width="20" height="20" />
                  </div>
                  <input
                    type="text"
                    id="input-field"
                    className="block w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="First name"
                    defaultValue={firstName}
                    {...register("firstName")}
                  />
                </div>
              </div>

              {/* old input field */}
              {/* <input
                className="pl-10 block w-full px-4 py-3 leading-tight text-gray-700 border-2 rounded appearance-none focus:outline-none focus:bg-white focus:border-[#113cc0]"
                id="grid-first-name"
                type="text"
                placeholder="First name"
                defaultValue={firstName}
                {...register("firstName")}
              /> */}
            </div>
            <div className="w-full md:w-1/2">
              {/* <label
                className="block mb-2 text-xs font-bold tracking-wide text-black-700 "
                htmlFor="grid-last-name"
              >
                Last Name
              </label>

              <div className="flex items-center px-3 py-3 border-2 border-gray-300 rounded focus-within:border-[#113cc0]">
                <img src={UserIcon} width="20" height="20" />

                <input
                  className="block w-full pl-2 leading-tight text-gray-700 outline-none "
                  id="grid-last-name"
                  type="text"
                  placeholder="Last name"
                  defaultValue={lastName}
                  {...register("lastName")}
                /> */}

              <label
                htmlFor="input-field"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <img src={UserIcon} width="20" height="20" />
                </div>
                <input
                  type="text"
                  id="input-field"
                  className="block w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Last name"
                  defaultValue={lastName}
                  {...register("lastName")}
                />
              </div>

              {/* <input
                className="block w-full px-4 py-3 leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-[#113cc0]"
                id="grid-last-name"
                type="text"
                placeholder="Last name"
                defaultValue={lastName}
                {...register("lastName")}
              /> */}
            </div>
          </div>

          <div className="w-full ">
            {/* <label
              htmlFor="email"
              className="block mb-2 text-xs font-bold tracking-wide text-black-700"
            >
              Email
            </label>
            <div className="flex items-center px-3 py-3 border-2 border-gray-300 rounded focus-within:border-[#113cc0]">
              <img src={MailIcon} width="20" height="20" />

              <input
                className="block w-full pl-2 leading-tight text-gray-700 outline-none "
                type="email"
                id="email"
                placeholder="name@company.com"
                {...register("email")}
                defaultValue={email}
              /> */}

            <label
              htmlFor="input-field"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <img src={MailIcon} width="20" height="20" />
              </div>
              <input
                type="email"
                id="email"
                placeholder="name@company.com"
                {...register("email")}
                defaultValue={email}
                className="block w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* <p className="text-xs italic text-gray-600">
              Make it as long and as crazy as you'd like
            </p> */}
          </div>

          <div className="flex flex-col gap-3 mb-2 md:flex-row xs:flex-col sm:flex md:flex-nowrap">
            <div className="md:w-3/3 sm:w-full">
              <label
                htmlFor="mobileNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Mobile Number
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <img src={PhoneIcon} width="20" height="20" />
                </div>
                <input
                  type="number"
                  id="mobileNumber"
                  placeholder="123456789"
                  {...register("mobileNumber", {
                    required: "Mobile number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Mobile number must be exactly 10 digits",
                    },
                  })}
                  defaultValue={mobileNumber}
                  onChange={async (e) => {
                    setValue("mobileNumber", e.target.value);
                    await trigger("mobileNumber");
                  }}
                  className="block w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              {/* <label
                htmlFor="mobileNumber"
                className="block mb-2 text-xs font-bold tracking-wide text-black-700"
              >
                Mobile Number
              </label>

              <div className="flex items-center px-3 py-3 border-2 border-grey-700 rounded focus-within:border-[#113cc0]">
                <img src={PhoneIcon} width="20" height="20" />

                <input
                  className="block w-full pl-2 leading-tight text-gray-700 outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none "
                  type="number"
                  id="mobileNumber"
                  placeholder="123456789"
                  {...register("mobileNumber", {
                    required: "Mobile number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Mobile number must be exactly 10 digits",
                    },
                  })}
                  defaultValue={mobileNumber}
                  onChange={async (e) => {
                    setValue("mobileNumber", e.target.value);
                    await trigger("mobileNumber");
                  }}
                />
              </div> */}

              <p className="text-xs italic text-red-600">
                {errors.mobileNumber && <p>{errors.mobileNumber.message}</p>}
              </p>
              {width < 700 && (
                <p className="flex justify-end text-xs italic text-gray-600">
                  <button
                    className="text-nowrap px-4 py-2 text-sm text-center text-[#113cc0] transition-all border border-transparent rounded-md text-slate-600 hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button"
                    onClick={handleSendOTP}
                  >
                    {loading ? <Loader /> : "Send OTP"}
                  </button>
                </p>
              )}
            </div>
            {mobile?.length == 10 && (
              <>
                {width > 700 && (
                  <div className="flex items-end justify-center md:w-1/3">
                    <button
                      className="text-nowrap  font-semibold px-4 py-2 text-sm text-center text-[#113cc0] transition-all border border-transparent rounded-md text-slate-600 hover:bg-slate-100 focus:bg-slate-100 active:bg-slate-100 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                      onClick={handleSendOTP}
                    >
                      Send OTP
                    </button>
                  </div>
                )}
              </>
            )}
          </div>

          {/* <div className="mb-4">
            <label
              // for="input"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Your Label
            </label>

            <div className="flex items-center p-2 border border-gray-300 rounded-lg">
              <img src={UserIcon} width="20" height="20" />

              <input
                id="input"
                type="text"
                className="flex-1 p-2 text-gray-700 outline-none"
                placeholder="Enter your text"
              />
            </div>
          </div> */}
          {otpSent && !isAuthenticated && (
            <div className="flex justify-center mb-2 sm:justify-normal">
              <OTPInput
                value={otpValue}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={false}
                renderInput={(props) => (
                  <input
                    {...props}
                    // id="otp"
                    className="mr-2"
                    // placeholder=""
                    // {...register("otp")}
                    // defaultValue={otp}
                  />
                )}
                inputStyle={{
                  border: "none",
                  justifyContent: "center",
                  borderRadius: "8px",
                  width: width > 700 ? "54px" : "34px",
                  height: width > 700 ? "54px" : "34px",
                  fontSize: "1.5rem",
                  fontWeight: 800,
                  backgroundColor: "#f1f1f1",
                  color: "#000",
                  display: "flex",
                  // caretColor: "blue",
                  outline: "none",
                }}
              />
            </div>
          )}

          {otpSent && !isAuthenticated && (
            <button
              type="button"
              onClick={handleVerifyOtp}
              className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
              style={{ padding: "8px 18px" }}
              // disabled={loading ? true : false}
            >
              Verify OTP
            </button>
          )}
          <div className="flex justify-center mt-10">
            <button
              type="button"
              onClick={handleNext}
              className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
              style={{ padding: "8px 18px" }}
            >
              Register
            </button>
          </div>
          <div className="flex justify-center">
            Already have an account?{" "}
            <button
              className="text-[#113cc0] font-semibold bg-transparent hover:underline focus:outline-none"
              onClick={(e) => navigate("/secure/login")}
            >
              Log In
            </button>
          </div>

          <footer className="bg-transparent-800">
            <div className="flex flex-row">
              <div
                className={`mr-2 w-5 h-5 border-2 rounded-md flex items-center justify-center cursor-pointer transition ${
                  isChecked ? "border-[#00c798]" : "border-gray-500"
                }`}
                onClick={handleCheckboxChange}
              >
                {isChecked ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-[#00c798]"
                    size="sm"
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="text-sm">
                By continuing, you agree to our
                <a
                  href="/terms-of-service"
                  className="font-semibold text-blue-600 underline-none hover:no-underline"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  href="/privacy-policy"
                  className="font-semibold text-blue-600 underline-none hover:no-underline"
                >
                  Privacy Policy
                </a>
                .
              </div>
            </div>
          </footer>
        </form>
      </StepNavigationWrapper>
    </div>
  );
};

export default PersonalInfoForm;
