import "./Banner.scss";
import { useState } from "react";
import banner from "../image/banner.png"
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useNavigate } from 'react-router-dom';

interface Option {
  key: string;
  option: string;
}

interface WhiteBoxProps {
  optionOne: Option[]; 
  optionTwo: Option[]; 
  boxNumber: number;
}
 const data = {
    searchScholarship: {
      education: [
        {
          key: " ",
          option: "B.tech"
        }
      ],
      destination: [
        {
          key: " ",
          option: "India"
        }
      ]
    },
    searchLoan: {
      estimatedLoan: [
        {
          key: " ",
          option: "up to 1 lakhs"
        }
      ],
      degrees: [
        {
          key: " ",
          option: "B.tech"
        }
      ]
    }
  }
  const WhiteBox: React.FC<WhiteBoxProps> = ({boxNumber, optionOne,optionTwo }) => {
    const navigate = useNavigate();

     const [selectedEducationLevel, setSelectedEducationLevel] = useState('');
  const [selectedDestination, setSelectedDestination] = useState('');
  const labelTwo = boxNumber === 1 ? "Destination" : "Degrees";
  const labelOne = boxNumber === 1 ? "Education" : "Estimated Loan";
  const dropOne = boxNumber === 1 ? "Education" : "Select";
  const dropTwo = boxNumber === 1 ? "Select Destination" : "Fields Interested";
  const button = boxNumber === 1 ? "Search Scholarship" : "Search Loan";
  const aLink = boxNumber === 1 ? "/scholarships/a" : "/education-loan-details";

  const handleNavigation = () => {
    navigate(aLink);

  };
  return (
    <div className="white-box bg-white flex flex-col md:flex-row p-4">
      <div className="flex flex-col pr-4">
        <label htmlFor="education" className="mb-2 font-medium text-[14px]">{labelOne}</label>
        <select
          id="education"
          className="border border-gray-300 rounded px-3 py-2 bg-green text-white text-[14px]" 
          value={selectedEducationLevel}
          onChange={(e) => setSelectedEducationLevel(e.target.value)}
        >
          <option value="">{dropOne}</option>
          {optionOne.map((item) => (
            <option key={item.key} value={item.option}>{item.option}</option>
          ))}
        </select>
      </div>
      <div className="flex flex-col pr-4">
        <label htmlFor="destination" className="mb-2 font-medium text-[14px]">{labelTwo}</label>
        <select
          id="destination"
          className="border border-gray-300 rounded px-3 py-2 bg-green text-white text-[14px]" 
          value={selectedDestination}
          onChange={(e) => setSelectedDestination(e.target.value)}
        >
          <option value="">{dropTwo}</option> 
          {optionTwo.map((item) => (
            <option key={item.key} value={item.option}>{item.option}</option>
          ))}
        </select>
      </div>
      <div className="flex flex-col">
        <div className="mb-7 "></div>
        <div className="flex whitespace-nowrap items-center"> 
  <button onClick={handleNavigation} className="bg-green rounded-lg px-4 py-1.5 text-[16px] flex items-center text-white">
    {button}
    <svg 
      width="15" 
      height="14" 
      viewBox="0 0 15 14" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block align-middle ml-1"
    >
      <path 
        d="M13.582 7.03008L0.854757 7.03008M13.582 7.03008L8.12749 1.72705M13.582 7.03008L8.12749 12.3331" 
        stroke="white" 
        stroke-width="1.59091" 
        stroke-linecap="round" 
        stroke-linejoin="round"
      />
    </svg>
  </button>
</div>
      </div>
    </div>
  );
};

const Banner = () => {
  // const data = useSelector(
  //   (state: RootState) => state.scholarship.banner
  // );
  if (!data) {
    return null; 
  }
console.log(data);
  const educationLevels = data.searchScholarship.education;

  const destinationOptions = data.searchScholarship.destination;
  const estimatedLoan = data.searchLoan.estimatedLoan;
  const degrees  = data.searchLoan.degrees;
    return (

        <>
            <div
                className="py-16"
                style={{
                    background: "linear-gradient(180deg, #EDFCFF 0%, #89C9ED 100%)",
                }}
            > <div className="container mx-auto">
                    <div className="flex flex-col lg:flex-row w-full">
                        <div className="flex flex-col w-full lg:w-[50%] gap-4 lg:pr-16">
                            <div className="flex dream-big">
                                <span className=" px-1 py-1 bg-[#173CBA] text-[#ffffff] text-center justify-center items-center">
                                    DREAM                  </span>
                                <span className="px-1 py-1  font-bold bg-green text-[#ffffff]">
                                    {" "}
                                    BIG!                  </span>
                            </div>
                            <div className="text-[#383838] font-roboto text-3xl font-medium leading-[50px]">
                                Achieve your Dream College with the Right Financial Support
                            </div>
                            <div className="text-[#383838] text-[20px] font-normal leading-[32px]">
                                Discover and Apply to Thousands of Scholarships and get the Best Education Loan Matching your Profile
                            </div>
                            <div>
                            <WhiteBox boxNumber={1} optionOne={educationLevels} optionTwo={destinationOptions} /></div>
                            <div>
                            <WhiteBox boxNumber={2} optionOne={estimatedLoan} optionTwo={degrees} /></div>
                        </div>
                        
                        <div className="mt-4 lg:mt-0 w-full lg:w-1/2 lg:p-12">
<img className="" src={banner}/>
                        </div>
                        </div>


                </div>
            </div>    </>
    )
}
export default Banner;