import Banner from "./components/Banner"
import FeaturedScholarships from "./components/FeaturedScholarships"
import DreamstoReality from "./components/DreamstoReality"
import OneStopSolution from "./components/OneStopSolution";

import { useEffect } from "react";
import { fetchLandingData } from "./scholarshipThunk";
import RightScholarship from "./components/RightScholarship";
  import TypeOfScholarships from "./components/TypeOfScholarships";
  import InspiringStories from "./components/InspiringStories";
  import Mentors from "./components/Mentors";

  import WebinarAndArticles from "../../colleges-new/landing/components/WebinarAndArticles";
  import CreditScore from "./components/CreditScore";
  import Tabs from "./components/Tabs";
  import FAQV2 from "../../../components/FAQV2";
  import KnowledgeHub from "./components/KnowledgeHub";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
const ScholarshipLanding = () =>
{
    const dispatch = useDispatch<AppDispatch>();
    const blogsData = useSelector((state: RootState) => state.collegeLanding.blogsAndArticles);

  useEffect(() => {
    dispatch(fetchLandingData());
  }, []);
return (

    <>
    <div>
<Banner/>
<FeaturedScholarships/>
<DreamstoReality/>
<RightScholarship/>
<TypeOfScholarships/>
<OneStopSolution/>
<Tabs/>
<Mentors/>
<InspiringStories/>
<WebinarAndArticles  blogsAndArticles={blogsData}/>
<KnowledgeHub/>
<CreditScore/>
 </div>
    </>
)
}

export default ScholarshipLanding