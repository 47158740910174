import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import SuccessStoryImg from "../../images/study-abroad/SuccessStory_img-1.png";

interface SuccessStory {
  courseName: string;
  description: string;
  storyBy: string;
  imageUrl: string;
}

interface SuccessStoriesProps {
  successStories: SuccessStory[];
}

const SuccessStories: React.FC<SuccessStoriesProps> = ({ successStories }) => {
  const [activeStoryIndex, setActiveStoryIndex] = useState(0);

  const handleStoryClick = (index: number) => {
    setActiveStoryIndex(index);
  };

  if (!successStories || successStories.length === 0) {
    return <p>No success stories available.</p>;
  }

  return (
    <section className="py-10">
      <div className="container mx-auto px-4">
        <h3 className="text-4xl lg:text-5xl font-light text-blue mb-6">
          Success Stories
        </h3>

        <div className="flex flex-col lg:flex-row gap-4 items-stretch">
          <div className="w-full lg:w-2/5 flex">
            <div className="bg-white rounded-lg shadow-md overflow-hidden w-4/5 lg:w-full h-full lg:flex-grow mx-auto relative z-10">
              <img
                src={
                  successStories[activeStoryIndex]?.imageUrl
                    ? successStories[activeStoryIndex]?.imageUrl
                    : SuccessStoryImg
                }
                alt={successStories[activeStoryIndex]?.storyBy}
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          <div className="w-full lg:w-3/5 flex flex-col">
            <div className="flex flex-col justify-center bg-[#F1F1F1] rounded-lg pb-9 pt-16 px-6 lg:p-11 shadow-md h-full mb-4 relative max-lg:-mt-11 z-0">
              <h4 className="text-2xl font-semibold mb-2">
                {successStories[activeStoryIndex]?.storyBy}
              </h4>
              <p className="text-lg mb-4">
                {successStories[activeStoryIndex]?.courseName}
              </p>
              <p className="text-base">
                {successStories[activeStoryIndex]?.description}
              </p>
            </div>

            <div className="block relative">
              <div className="absolute top-1/2 transform -translate-y-1/2 left-0 z-10">
                <button className="swiper-button-prev bg-blue-500 text-white p-2 rounded-full">
                  &lt;
                </button>
              </div>
              <div className="absolute top-1/2 transform -translate-y-1/2 right-0 z-10">
                <button className="swiper-button-next bg-blue-500 text-white p-2 rounded-full">
                  &gt;
                </button>
              </div>

              <Swiper
                spaceBetween={16}
                slidesPerView={1.2}
                centeredSlides={true}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{ clickable: true }}
                modules={[Navigation]}
                className="study-success-stories-swiper"
                breakpoints={{
                  640: {
                    slidesPerView: 3,
                    centeredSlides: false,
                  },
                }}
              >
                {successStories.map((story, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className={`w-full h-full xl:w-64 xl:h-64 rounded-lg overflow-hidden cursor-pointer ${
                        activeStoryIndex === index
                          ? "border-blue-500"
                          : "border-transparent"
                      }`}
                      onClick={() => handleStoryClick(index)}
                    >
                      <img
                        src={story.imageUrl ? story.imageUrl : SuccessStoryImg}
                        alt={story.storyBy}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStories;
