import React from "react";
import Arrow from "../../../images/Arrow.svg";

interface Props {
  image?: string;
  imageUrls?: string[];
  title: string;
  description: string;
}

const CareerCards: React.FC<Props> = ({
  image,
  imageUrls,
  title,
  description,
}) => {
  const displayImage = image || (imageUrls && imageUrls[0]);

  return (
    <div className="block rounded-xl overflow-hidden">
      <div className="flex flex-col gap-5 overflow-hidden">
        {displayImage && (
          <img
            src={displayImage}
            alt={title}
            className="w-full h-full rounded-xl object-cover"
          />
        )}
      </div>
      <div className="flex">
        <div className="relative w-full flex flex-col p-3.5 rounded-2xl gap-2.5">
          <h4 className="text-base font-bold text-white line-clamp-2">
            {title}
          </h4>
          <p className="text-sm text-white line-clamp-3">{description}</p>
          <div className="pt-1">
            <img src={Arrow} className="arrow-icon" alt="arrow" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerCards;
