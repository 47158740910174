const captilizeFirstLetter = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const ReponsiveDeviceList: any = {
  Mobile: "Mobile",
  Desktop: "Desktop",
};
const splitAndCapitilize = (word: string) =>
  word
    ?.split("-")
    ?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1))
    ?.join(" ");

const generateListofItems = (
  list: [],
  type = "Desktop",
  countsToShow: number
) => {
  return list.slice(0, countsToShow);
};

export { captilizeFirstLetter, generateListofItems, splitAndCapitilize };
