import React from "react";
import PremiumBlogImage from "../../../../images/blog-article/exam-section-image2.jpeg";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import ExamBlogsImage1 from "../../../../images/blog-article/exam-section-image1.jpg";
import ExamBlogsImage2 from "../../../../images/blog-article/exam-section-image2.jpeg";
import ExamBlogsImage3 from "../../../../images/blog-article/exam-section-image3.jpg";
import PlusSquareIcon from "../../../../images/blog-article/plus-square.svg";
import { Link } from "react-router-dom";
import { getBlogOrNewsUrl } from "../../utils";
interface PremiumBlogsProps {
  premiumBlogs: any;
}
const PremiumBlogs: React.FC<PremiumBlogsProps> = ({ premiumBlogs }) => {
  return (
    <div className="container grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 py-4 gap-4 ">
      <div className="col-start-1">
        {
          <CustomSwiper
            className="!px-2"
            items={premiumBlogs[0]?.["Premium Blogs"]?.subcategories[
              "Articles & Blogs"
            ]?.results
              ?.slice(4, 8)
              ?.map((item: any) => (
                <div className="relative">
                  <div className="premium-image-container">
                    <img
                      src={item?.imageUrl}
                      style={{
                        objectFit: "cover",
                        height: "24rem",
                        width: "100%",
                      }}
                    />
                    <div className="premium-blog-link absolute flex w-full bottom-0 left-0 text-white text-sm ">
                      <Link
                        // to={`/blogs/${hoveredItem.category?.toLocaleLowerCase()}/${hoveredItem?.title?.toLocaleLowerCase()}`}
                        to={getBlogOrNewsUrl(item)}
                        state={{
                          from: "BlogHoverContainer",
                          blogDetail: item,
                        }}
                        className="w-full p-4 m-4 bg-[#0EB78F] font-semibold text-lg flex justify-center items-center"
                      >
                        <div>
                          {item?.title ||
                            "Interesting premium blog only paid customers"}
                        </div>
                        <div className="flex grow justify-end">
                          <img src={PlusSquareIcon} alt="" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            progressBar={true}
            navigation={"bottom"}
            autoplay={true}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 1, spaceBetween: 20 },
              1024: { slidesPerView: 1, spaceBetween: 18 },
              1280: { slidesPerView: 1, spaceBetween: 30 },
            }}
          />
        }
        {/* <div className="relative">
          <div className="premium-image-container">
            <img
              src={PremiumBlogImage}
              style={{ objectFit: "cover", height: "24rem", width: "100%" }}
            />
            <div className="premium-blog-link absolute flex w-full bottom-0 left-0 text-white text-sm ">
              <a
                href=""
                className="w-full p-4 m-4 bg-[#0EB78F] font-semibold text-lg inline-block"
              >
                Interesting premium blog only paid customers
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PremiumBlogs;
