import React from "react";
import PredictorResultCollegeCard from "../components/PredictorResultCollegeCard";
interface Course {
  lastYearCutoffAiRank: number;
  courseName: string;
}

interface College {
  collegeName: string;
  ownerShip: string;
  courseVo: Course[];
}

interface CollegesData {
  colleges: College[];
}
const PredictorResultCard: React.FC<{ data?: CollegesData }> = ({ data }) => {
  return (<><div>
              {data?.colleges.map((college, index) => (

    <div className="w-full bg-white border border-black shadow dark:bg-gray-800 dark:border-gray-700 mt-4">
        <div key={index}>
          <div className="flex flex-col gap-3 p-4 border-b-2 border-black">
            <span>{college.collegeName}</span>
            <span>Ownership: {college.ownerShip}</span>
          </div>
          <div className="p-4">
            <span>Your chances are GOOD in {college.courseVo.length} Courses</span>
            <div className="flex flex-row overflow-hidden gap-4">
            {college.courseVo.map((course, courseIndex) => (
              <PredictorResultCollegeCard key={courseIndex} course={course} />
            ))}       </div>     </div>
          <div className="flex flex-row p-4">
            <div className="flex gap-2 grow">
              <button className="">View Cutoffs</button>
              <button className="">Download Brochure</button>
            </div>
            <div>
              <button className="">Add to Compare</button>
            </div>
          </div>
        </div></div>
      ))}
    </div> </>
  );
};

export default PredictorResultCard;
