import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../util/config";

export const fetchLandingData = createAsyncThunk(
  "blogSlice/fetchLandingData",
  async () => {
    const response = await axios.get(config.apiUrl + "/api/blogs/blogsandnews");
    return response.data;
  }
);
