// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-content a {
  color: #1e90ff;
  text-decoration: underline;
  transition: color 0.2s ease-in-out, -webkit-text-decoration 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out, -webkit-text-decoration 0.2s ease-in-out;
  width: 100%;
  word-wrap: break-word;
}
.blog-content a:hover {
  color: #1c3d7d;
  text-decoration: none;
}
.blog-content h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
.blog-content h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}`, "",{"version":3,"sources":["webpack://./src/screens/blogs-articles/blogs-articles-details/BlogsCmsStyle.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,0BAAA;EACA,4EAAA;EAAA,oEAAA;EAAA,8GAAA;EACA,WAAA;EACA,qBAAA;AAAJ;AAEI;EACE,cAAA;EACA,qBAAA;AAAN;AAIE;EACE,cAAA;EACA,cAAA;EACA,0BAAA;EACA,wBAAA;EACA,wBAAA;EACA,sBAAA;EACA,iBAAA;EACA,qBAAA;AAFJ;AAIE;EACE,cAAA;EACA,gBAAA;EACA,0BAAA;EACA,wBAAA;EACA,wBAAA;EACA,sBAAA;EACA,iBAAA;EACA,qBAAA;AAFJ","sourcesContent":[".blog-content {\n  a {\n    color: #1e90ff;\n    text-decoration: underline;\n    transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out;\n    width: 100%;\n    word-wrap: break-word;\n\n    &:hover {\n      color: #1c3d7d;\n      text-decoration: none;\n    }\n  }\n\n  h1 {\n    display: block;\n    font-size: 2em;\n    margin-block-start: 0.67em;\n    margin-block-end: 0.67em;\n    margin-inline-start: 0px;\n    margin-inline-end: 0px;\n    font-weight: bold;\n    unicode-bidi: isolate;\n  }\n  h2 {\n    display: block;\n    font-size: 1.5em;\n    margin-block-start: 0.83em;\n    margin-block-end: 0.83em;\n    margin-inline-start: 0px;\n    margin-inline-end: 0px;\n    font-weight: bold;\n    unicode-bidi: isolate;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
