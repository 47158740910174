import React from "react";

interface Props {
  image: string;
  title: string;
  description: string;
  pushlishDate?: string;
  postedOn?: string;
  onCardClick: () => void;
}

const CareerVisualStoriesCards: React.FC<Props> = ({
  image,
  title,
  description,
  pushlishDate,
  postedOn,
  onCardClick,
}) => {
  return (
    <div
      className="block rounded-xl overflow-hidden cursor-pointer"
      onClick={onCardClick}
    >
      <div className="flex flex-col gap-5 overflow-hidden">
        {image && (
          <img
            src={image}
            alt={title}
            className="w-full h-[200px] object-cover rounded-xl"
          />
        )}
      </div>
      <div className="flex">
        <div className="relative w-full flex flex-col  p-3.5 z-2 rounded-2xl gap-2.5">
          {pushlishDate && (
            <p className="text-sm text-white mb-2">
              Published on: {pushlishDate}
            </p>
          )}
          {postedOn && (
            <p className="text-sm text-white mb-2">Posted on: {postedOn}</p>
          )}
          <h4 className="text-base font-bold text-white line-clamp-2">
            {title}
          </h4>
          <p className="text-sm text-white line-clamp-3">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default CareerVisualStoriesCards;
