import React, { useState } from "react";

const FAQSection: React.FC<{ faq: any }> = ({ faq }) => {
  const [visibleQuestions, setVisibleQuestions] = useState(3);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleQuestionsVisibility = () => {
    if (isExpanded) {
      setVisibleQuestions(3);
    } else {
      setVisibleQuestions(faq.description.length);
    }
    setIsExpanded(!isExpanded);
  };

  if (!faq) return null;

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col gap-6">
            <div className="flex max-md:flex-col max-md:w-full">
              <h3 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                {faq.title}
              </h3>
            </div>
            <div className="flex flex-col gap-6 px-2 py-8 md:py-5 md:px-9 w-full lg:w-9/12">
              <ul className="list-none flex flex-col gap-8 transition-opacity duration-300">
                {faq.description
                  .slice(0, visibleQuestions)
                  .map((item: any, index: number) => (
                    <li
                      key={index}
                      className={`text-base text-[#4C495C] leading-7 p-6 rounded-2xl flex gap-4 items-center border`}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFF9E5" : "#EEF2FF",
                        borderColor: index % 2 === 0 ? "#FFD540" : "#173CBA",
                      }}
                    >
                      <div className="flex flex-col gap-6">
                        <h4 className="text-lg text-[#25242B] font-semibold">
                          Question: {item.question}
                        </h4>
                        <p className="text-base text-[#4C495C] font-normal">
                          <strong>Answer:</strong> {item.answer}
                        </p>
                      </div>
                    </li>
                  ))}
              </ul>
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 text-white bg-[#173CBA] rounded hover:bg-[#00C798] transition duration-300 flex items-center gap-2"
                  onClick={toggleQuestionsVisibility}
                >
                  {isExpanded ? "Show Less" : "Show More"}{" "}
                  <svg
                    width="13"
                    height="7"
                    viewBox="0 0 13 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`transform transition-transform duration-300 ${
                      isExpanded ? "rotate-180" : ""
                    }`}
                  >
                    <path
                      d="M1.2915 0.963867L6.49984 6.1722L11.7082 0.963867"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
