import { createSlice } from "@reduxjs/toolkit";
import { fetchDetailData } from "./educationLoanThunk";
import { fetchLandingData } from "../college-compare/collegeCompareThunk";

interface IData {
  loans: {
    bankName: string;
    bankLogo: string;
    description: string;
  }[];
  faqs: { question: string; answer: string }[];
  courses: { courseName: string }[];
  loading: boolean;
  error: string | null;
}

const initialState: IData = {
  loans: [],
  faqs: [],
  courses: [],
  loading: false,
  error: null,
};

const educationLoan = createSlice({
  name: "educationLoan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDetailData.pending, (state) => {
        console.log("loading loan data");
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDetailData.fulfilled, (state, action) => {
        console.log("fulfilled loan data");
        state.loading = false;
        state.loans = action.payload?.educationLoans;
        state.faqs = action.payload?.educationLoanFaqs;
        state.courses = action.payload?.educationLoanCourses;
      })
      .addCase(fetchDetailData.rejected, (state) => {
        state.loading = false;
        state.error = "Error loading loan detail data";
      });
  },
});

export default educationLoan.reducer;
