import { UseFormRegister } from "react-hook-form";

export enum FormInput {
  Text = "TEXT",
  Date = "DATE",
  Radio = "RADIO",
  Dropdown = "DROPDOWN",
}

interface FormData {
  [field: string]: any;
}

export const FieldInput = ({
  fieldKey,
  name,
  type,
  required,
  options,
  validationRegex,
  register,
  errors,
}: {
  fieldKey: string;
  name: string;
  type: FormInput;
  required: boolean;
  options?: { key: string | number; name: string }[];
  validationRegex?: RegExp;
  register: UseFormRegister<FormData>;
  errors: string;
}) => {
  const requiredProp = required ? `${name} is required` : false;

  switch (type) {
    case FormInput.Text:
      return (
        <>
          <input
            {...register(fieldKey, {
              required: requiredProp,
              pattern: validationRegex && {
                value: validationRegex,
                message: `Invalid ${name}`,
              },
            })}
            className="w-full p-2 rounded border border-grey-3"
          />
          <p className="text-red-500">{errors}</p>
        </>
      );
    case FormInput.Date:
      return (
        <>
          <input
            type="date"
            {...register(fieldKey, {
              required: requiredProp,
            })}
          />
          <p className="text-red-500">{errors}</p>
        </>
      );
    case FormInput.Radio:
      return (
        <>
          <div className="flex gap-4">
            {options?.map((option) => (
              <label className="flex items-center gap-1">
                <input
                  type="radio"
                  value={option.key}
                  className="text-sm"
                  {...register(fieldKey, {
                    required: requiredProp,
                  })}
                />
                {option.name}
              </label>
            ))}
          </div>
          <p className="text-red-500">{errors}</p>
        </>
      );
    case FormInput.Dropdown:
      return (
        <>
          <select
            className="w-full p-2 rounded border border-grey-3"
            {...register(fieldKey, {
              required: requiredProp,
            })}
          >
            {options?.map((option) => (
              <option key={option.key} value={option.key}>
                {option.name}
              </option>
            ))}
          </select>
          <p className="text-red-500">{errors}</p>
        </>
      );
  }
};
