import React, { useState } from 'react';

interface TabContentProps {
  activeTab: number;
}
interface Tab {
    label: string;
  }
const TabContent: React.FC<TabContentProps> = ({ activeTab }) => {
  const [principal, setPrincipal] = useState(0);
  const [interest, setInterest] = useState(0);
  const [tenure, setTenure] = useState(0);
  const headPara = {
    headings: [
      "College Mentor Education Loan Program",
      "Education Loans Have Different Interest Rates",
      "Get Up to 100% Financing",
      "Flexible Repayment Options",
      "Instant Student Loans",
      "Customizable Loans",
      "Doorstep Service"
    ],
    paragraphs: [
      "A College Mentor initiative in partnership with multiple banks and NBFCs, providing financial support for students admitted to higher educational institutions in India and abroad. We’re here to assist you in securing the most favorable interest rates for your education loans.",
      "Rates vary by loan type, lender, and your financial profile. Compare options to find the best rate.",
      "We offer comprehensive education financing solutions that cover the full cost of your education.",
      "Choose how and when to repay your loan with options tailored to your unique financial needs.",
      "Apply for a fast-track student loan and receive funds within 3 working days!",
      "Our education financing solutions are designed to fit your specific financial requirements.",
      "Need help with your student loan? Apply online, and we’ll send an executive to assist with the paperwork."
    ],}
  const calculateEMI = () => {
    const monthlyInterest = interest / 12 / 100;
    const totalPayments = tenure * 12;
    const emi = 
      (principal * monthlyInterest * Math.pow(1 + monthlyInterest, totalPayments)) / 
      (Math.pow(1 + monthlyInterest, totalPayments) - 1);
    return emi.toFixed(2);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("Form submitted!"); 
  }

  return (
    <div className="mt-4">
      {activeTab === 0 && (
        <div className="flex">
          <div className="w-1/2 pr-4 mt-4 ml-4">
            <h2 className="text-[#173CBA] font-roboto text-3xl font-medium leading-[50px]">College Mentor<br></br> Education Loan Program</h2>
            <img src="\images\scholarship\landing\loan.png" alt="Image" className="mb-4" />
            
          </div>
          <div className="w-1/2 pl-4">
          {headPara.headings.map((heading, index) => (
        <div key={index}>
          <div className='text-black font-roboto text-[14px] font-semibold leading-[36px]'>{heading}</div>
          <div className='text-black font-roboto text-[12px] leading-[24px]'>{headPara.paragraphs[index]}</div>
        </div>
      ))}
            <select className="border-gray-300 rounded-md p-2 mb-2">
            </select>
            <select className="border-gray-300 rounded-md p-2 mb-4">
            </select>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Button
            </button>

          </div>
        </div>
      )}
      {activeTab === 1 && (
        <div className="flex">
          <div className="w-1/2 pr-4">
            <label htmlFor="loanAmount" className="block mb-2">Loan Amount:</label>
            <input 
              type="range" 
              id="loanAmount" 
              min="0" 
              max="1000000" 
              value={principal} 
              onChange={(e) => setPrincipal(parseInt(e.target.value))} 
              className="w-full" 
            />
            <label htmlFor="interestRate" className="block mb-2">Annual Interest Rate:</label>
            <input 
              type="range" 
              id="interestRate" 
              min="0" 
              max="20" 
              value={interest} 
              onChange={(e) => setInterest(parseInt(e.target.value))}
              className="w-full" 
            />
            <label htmlFor="tenure" className="block mb-2">Tenure (in years):</label>
            <input 
              type="range" 
              id="tenure" 
              min="1" 
              max="30" 
              value={tenure} 
              onChange={(e) => setTenure(parseInt(e.target.value))} 
              className="w-full" 
            />
            <button 
              onClick={calculateEMI} 
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
            >
              Calculate EMI
            </button>
            <p className="mt-4">EMI: {calculateEMI()}</p>
          </div>
          <div className="w-1/2 pl-4">
            <div className="relative">
              <div className="w-48 h-48 rounded-full bg-gray-200 flex items-center justify-center">
                <div className="w-32 h-32 rounded-full bg-blue-500 relative">
                  <div 
                    className="absolute top-0 left-0 w-full h-full rounded-full bg-green-500" 
                    style={{ clipPath: `polygon(0 0, 100% 0, 100% ${100 - (interest / (interest + principal) * 100)}%, 0 ${100 - (interest / (interest + principal) * 100)}%)` }} 
                  ></div>
                  <p className="text-white font-bold text-lg">{principal + interest}</p>
                </div>
              </div>
              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-500 font-bold">Principal</p>
              <p className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 text-green-500 font-bold">Interest</p>
            </div>
          </div>
        </div>
      )}
      {activeTab === 2 && (
        <div className="flex">
          <div className="w-1/2 pr-4">
            <h3 className="text-xl font-bold mb-2">Question</h3>
            <p>Answer in a paragraph</p>
          </div>
          <div className="w-1/2 pl-4">
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="input1">
                  Input 1
                </label>
                <input 
                  className="shadow appearance-none border-b rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                  id="input1" 
                  type="number" 
                  placeholder="Enter a number" 
                />
              </div>
              <div className="flex items-center justify-between">
                <button 
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {activeTab === 3 && (
        <div className="flex">
          <div className="w-1/2 pr-4">
            <h3 className="text-xl font-bold mb-2">Another Question</h3>
            <p>Another answer in a paragraph</p>
          </div>
          <div className="w-1/2 pl-4">
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="input1">
                  Input 1
                </label>
                <input 
                  className="shadow appearance-none border-b rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                  id="input1" 
                  type="number" 
                  placeholder="Enter a number" 
                />
              </div>
              <div className="flex items-center justify-between">
                <button 
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const Tabs: React.FC = () => {
    const [activeTab, setActiveTab] = useState(0);
  
    const tabs: Tab[] = [
      { label: 'Education Bank Loan' },
      { label: 'Education Loan Calculator'},
      { label: 'College Course Expense Calculator' },
      { label: 'Eligibility Calculator ' },
    ];
  
    return (
      <>
        <div className="w-full bg-[#00CC99] p-4 ">
          <div className="pl-10 flex mb-4 py-4">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={` px-4 border-r border-white ${
                  activeTab === index ? 'bg-blue-500 text-white' : ' text-white'
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div className="bg-white mx-12 mb-12 h-[80vh]">
          <TabContent activeTab={activeTab} />
          </div>
        </div>
      </>
    );
  };

export default Tabs;