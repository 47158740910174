import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import BlogsHeader from "./BlogsHeader";
import { HeaderAPIResponse } from "../data/UtilsData";
import axios from "axios";
import config from "../../../util/config";
import { BiLogoMessenger } from "react-icons/bi";
import BlogsMegaMenu from "./BlogsMegaMenu";
import BlogFooter from "./BlogFooter";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { fetchLandingData } from "../../../store/thunks/blogThunk";

const BlogsLayout = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [headerResponse, setHeaderResponse] = useState<any>([]);
  const getHeaderData = async () => {
    await axios
      .get(config.apiUrl + "/api/blogs/allsections")
      .then((response: { data: any }) => {
        // console.log("response", response);
        // const allSectionData = response.data;
        // console.log("allSectionData", allSectionData);
        setHeaderResponse(response.data);
        // setSectionWiseData(allSectionData?.sections[0]);
      })
      .catch((error: any) => console.log("error", error));
  };
  useEffect(() => {
    getHeaderData();
    dispatch(fetchLandingData());
  }, []);
  return (
    <>
      {/* <BlogsHeader
        headerResponse={headerResponse?.categories}
        sectionsList={headerResponse?.sectionsList}
      /> */}
      <BlogsMegaMenu
        categories={headerResponse?.categories}
        sectionsList={headerResponse?.sectionsList}
      />
      <Outlet />
      <BlogFooter sectionsList={headerResponse?.sectionsList} />
    </>
  );
};

export default BlogsLayout;
