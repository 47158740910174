import React from "react";
import BlogInfoCard from "./BlogInfoCard";
import MentorImage from "../../../images/blog-article/MentorImage.jpeg";
import DefaultThumbNail from "../../../images/blog-article/default.png";

interface MentorCardProps {
  authorImageUrl: string;
  authorName: string;
  blogs: any;
}
const MentorCard: React.FC<MentorCardProps> = ({
  authorImageUrl,
  authorName,
  blogs,
}) => {
  const getCurrentHoveredBlog = (param: any) => {
    console.log("first", param);
  };
  return (
    <>
      <div className="flex flex-col items-center border-b-2 border-[#CCCCCC] group">
        <img src={authorImageUrl || DefaultThumbNail} className="w-32 h-32 object-cover mb-4 bg-gray-300 rounded-full shrink-0">
        </img>
        <h1 className="text-xl font-bold mb-2 group-hover:text-[#173CBA] line-clamp-1">
          {authorName}
        </h1>
      </div>

      <div className="flex flex-col">
        <div className="">
          {blogs?.results?.slice(0, 3)?.map((blogInfo: any) => (
            <BlogInfoCard
              dateValue={false}
              rowReverse={true}
              blogInfo={blogInfo}
              getCurrentHoveredItem={getCurrentHoveredBlog}
              height=""
              categoryOrderReverse={true}
              borderBottom={false}
              imageField={true}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default MentorCard;
