import config from '../../util/config';

export const coursesMockData = [
  {
    courseName: "Aerospace Engineering",
    courseUrl:
      config.apiUrl + "/course-details/aerospace-engineering",
  },
  {
    courseName: "Mechanical Engineering",
    courseUrl:
      config.apiUrl + "/course-details/mechanical-engineering",
  },
  {
    courseName: "Electrical Engineering",
    courseUrl:
      config.apiUrl + "/course-details/electrical-engineering",
  },
  {
    courseName: "Financial Accounting",
    courseUrl:
      config.apiUrl + "/course-details/financial-accounting",
  },
  {
    courseName: "Automation and Robotics",
    courseUrl:
      config.apiUrl + "/course-details/automation-and-robotics",
  },
  {
    courseName: "Automobile Engineering",
    courseUrl:
      config.apiUrl + "/course-details/automobile-engineering",
  },
  {
    courseName: "Computer Science Engineering",
    courseUrl:
      config.apiUrl + "/course-details/computer-science-engineering",
  },
  {
    courseName: "IT Engineering",
    courseUrl:
      config.apiUrl + "/course-details/it-engineering",
  },
  {
    courseName: "Mech Engineering",
    courseUrl:
      config.apiUrl + "/course-details/mechanical-engineering2",
  },
  {
    courseName: "Computing Engineering",
    courseUrl:
      config.apiUrl + "/course-details/computing-engineering",
  },
  {
    courseName: "Automobile Engineering",
    courseUrl:
      config.apiUrl + "/course-details/automobile-engineering",
  },
  {
    courseName: "Electrical and Electronics Engineering",
    courseUrl:
      config.apiUrl + "/course-details/electrical-and-electronics-engineering",
  },
  {
    courseName: "Electronics and Communication Engineering",
    courseUrl:
      config.apiUrl + "/course-details/electronics-and-communication-engineering",
  },
  {
    courseName: "Anatomy",
    courseUrl: config.apiUrl + "/course-details/anatomy",
  },
  {
    courseName: "Banking",
    courseUrl: config.apiUrl + "/course-details/banking",
  },
  {
    courseName: "Architecture Engineering",
    courseUrl:
      config.apiUrl + "/course-details/architecture-engineering",
  },
  {
    courseName: "Human Resource Management",
    courseUrl:
      config.apiUrl + "/course-details/human-resource-management",
  },
  {
    courseName: "Mechatronics Engineering",
    courseUrl:
      config.apiUrl + "/course-details/mechatronics-engineering",
  },
  {
    courseName: "Law",
    courseUrl: config.apiUrl + "/course-details/law",
  },
  {
    courseName: "Chemical Engineering",
    courseUrl:
      config.apiUrl + "/course-details/chemical-engineering",
  },
  {
    courseName: "Dentistry",
    courseUrl: config.apiUrl + "/course-details/dentistry",
  },
  {
    courseName: "Radiology",
    courseUrl: config.apiUrl + "/course-details/radiology",
  },
  {
    courseName: "Marketing Management",
    courseUrl:
      config.apiUrl + "/course-details/marketing-management",
  },
  {
    courseName: "Ayurveda",
    courseUrl: config.apiUrl + "/course-details/ayurveda",
  },
  {
    courseName: "Operations Management",
    courseUrl:
      config.apiUrl + "/course-details/operations-management",
  },
  {
    courseName: "General Medicine and Surgery",
    courseUrl:
      config.apiUrl + "/course-details/general-medicine-and-surgery",
  },
  {
    courseName: "Otolaryngology ear, nose and throat",
    courseUrl:
      config.apiUrl + "/course-details/otolaryngology-ear,-nose-and-throat",
  },
  {
    courseName: "Endocrinology",
    courseUrl:
      config.apiUrl + "/course-details/endocrinology",
  },
  {
    courseName: "Physiotherapy",
    courseUrl:
      config.apiUrl + "/course-details/physiotherapy",
  },
  {
    courseName: "Aviation Management",
    courseUrl:
      config.apiUrl + "/course-details/aviation-management",
  },
  {
    courseName: "Industrial Design",
    courseUrl:
      config.apiUrl + "/course-details/industrial-design",
  },
  {
    courseName: "Homeopathy",
    courseUrl: config.apiUrl + "/course-details/homeopathy",
  },
  {
    courseName: "Automotive Engineering",
    courseUrl:
      config.apiUrl + "/course-details/automotive-engineering",
  },
  {
    courseName: "Rheumatology",
    courseUrl:
      config.apiUrl + "/course-details/rheumatology",
  },
  {
    courseName: "Petroleum Engineering",
    courseUrl:
      config.apiUrl + "/course-details/petroleum-engineering",
  },
  {
    courseName: "Sales",
    courseUrl: config.apiUrl + "/course-details/sales",
  },
  {
    courseName: "Agricultural and Food Engineering",
    courseUrl:
      config.apiUrl + "/course-details/agricultural-and-food-engineering",
  },
  {
    courseName: "Tourism",
    courseUrl: config.apiUrl + "/course-details/tourism",
  },
  {
    courseName: "Aircraft Manufacturing and Maintenance Engineering",
    courseUrl:
      config.apiUrl + "/course-details/aircraft-manufacturing-and-maintenance-engineering",
  },
  {
    courseName: "Ophthalmology",
    courseUrl:
      config.apiUrl + "/course-details/ophthalmology",
  },
  {
    courseName: "Labour Law",
    courseUrl: config.apiUrl + "/course-details/labour-law",
  },
  {
    courseName: "General Management",
    courseUrl:
      config.apiUrl + "/course-details/general-management",
  },
  {
    courseName: "Commercial Law",
    courseUrl:
      config.apiUrl + "/course-details/commercial-law",
  },
  {
    courseName: "Oncology",
    courseUrl: config.apiUrl + "/course-details/oncology",
  },
  {
    courseName: "Petrochemical Engineering",
    courseUrl:
      config.apiUrl + "/course-details/petrochemical-engineering",
  },
  {
    courseName: "Business Analytics",
    courseUrl:
      config.apiUrl + "/course-details/business-analytics",
  },
  {
    courseName: "Media Management",
    courseUrl:
      config.apiUrl + "/course-details/media-management",
  },
  {
    courseName: "Cyber Law",
    courseUrl: config.apiUrl + "/course-details/cyber-law",
  },
  {
    courseName: "Nuclear Engineering",
    courseUrl:
      config.apiUrl + "/course-details/nuclear-engineering",
  },
  {
    courseName: "Urology",
    courseUrl: config.apiUrl + "/course-details/urology",
  },
  {
    courseName: "Agricultural and Irrigation Engineering",
    courseUrl:
      config.apiUrl + "/course-details/agricultural-and-irrigation-engineering",
  },
  {
    courseName: "Construction Engineering",
    courseUrl:
      config.apiUrl + "/course-details/construction-engineering",
  },
  {
    courseName: "Agricultural Engineering",
    courseUrl:
      config.apiUrl + "/course-details/agricultural-engineering",
  },
  {
    courseName: "Civil Engineering",
    courseUrl:
      config.apiUrl + "/course-details/civil-engineering",
  },
  {
    courseName: "Instrumentation Engineering",
    courseUrl:
      config.apiUrl + "/course-details/instrumentation-engineering",
  },
  {
    courseName: "Electronics Engineering",
    courseUrl:
      config.apiUrl + "/course-details/electronics-engineering",
  },
  {
    courseName: "Genetic Engineering",
    courseUrl:
      config.apiUrl + "/course-details/genetic-engineering",
  },
  {
    courseName: "Textile Engineering",
    courseUrl:
      config.apiUrl + "/course-details/textile-engineering",
  },
  {
    courseName: "Physiology",
    courseUrl: config.apiUrl + "/course-details/physiology",
  },
  {
    courseName: "Environmental Engineering",
    courseUrl:
      config.apiUrl + "/course-details/environmental-engineering",
  },
  {
    courseName: "Dairy Technology",
    courseUrl:
      config.apiUrl + "/course-details/dairy-technology",
  },
  {
    courseName: "Pathology",
    courseUrl: config.apiUrl + "/course-details/pathology",
  },
  {
    courseName: "Industrial Marketing",
    courseUrl:
      config.apiUrl + "/course-details/industrial-marketing",
  },
  {
    courseName: "Metallurgical Engineering",
    courseUrl:
      config.apiUrl + "/course-details/metallurgical-engineering",
  },
  {
    courseName: "Mining Engineering",
    courseUrl:
      config.apiUrl + "/course-details/mining-engineering",
  },
  {
    courseName: "Logistics and Supply Chain Management",
    courseUrl:
      config.apiUrl + "/course-details/logistics-and-supply-chain-management",
  },
  {
    courseName: "Energy Management",
    courseUrl:
      config.apiUrl + "/course-details/energy-management",
  },
  {
    courseName: "Agriculture Business Management",
    courseUrl:
      config.apiUrl + "/course-details/agriculture-business-management",
  },
  {
    courseName: "Apparel Management",
    courseUrl:
      config.apiUrl + "/course-details/apparel-management",
  },
  {
    courseName: "Water Management",
    courseUrl:
      config.apiUrl + "/course-details/water-management",
  },
  {
    courseName: "Disaster management",
    courseUrl:
      config.apiUrl + "/course-details/disaster-management",
  },
  {
    courseName: "Industrial and Production Engineering",
    courseUrl:
      config.apiUrl + "/course-details/industrial-and-production-engineering",
  },
  {
    courseName: "Financial Services",
    courseUrl:
      config.apiUrl + "/course-details/financial-services",
  },
  {
    courseName: "Mechanical and Automation Engineering",
    courseUrl:
      config.apiUrl + "/course-details/mechanical-and-automation-engineering",
  },
  {
    courseName: "Corporate Management",
    courseUrl:
      config.apiUrl + "/course-details/corporate-management",
  },
  {
    courseName: "Film Making",
    courseUrl:
      config.apiUrl + "/course-details/film-making",
  },
  {
    courseName: "Mechanical and Industrial Engineering",
    courseUrl:
      config.apiUrl + "/course-details/mechanical-and-industrial-engineering",
  },
  {
    courseName: "Technology Management",
    courseUrl:
      config.apiUrl + "/course-details/technology-management",
  },
  {
    courseName: "Tool Engineering",
    courseUrl:
      config.apiUrl + "/course-details/tool-engineering",
  },
  {
    courseName: "Product Management",
    courseUrl:
      config.apiUrl + "/course-details/product-management",
  },
  {
    courseName: "Bio Engineering",
    courseUrl:
      config.apiUrl + "/course-details/bio-engineering",
  },
  {
    courseName: "Rural Management",
    courseUrl:
      config.apiUrl + "/course-details/rural-management",
  },
  {
    courseName: "Geotechnical Engineering",
    courseUrl:
      config.apiUrl + "/course-details/geotechnical-engineering",
  },
  {
    courseName: "Mass Communication",
    courseUrl:
      config.apiUrl + "/course-details/mass-communication",
  },
  {
    courseName: "Polymer Engineering",
    courseUrl:
      config.apiUrl + "/course-details/polymer-engineering",
  },
  {
    courseName: "Hospital Administration",
    courseUrl:
      config.apiUrl + "/course-details/hospital-administration",
  },
  {
    courseName: "Geo Informatics",
    courseUrl:
      config.apiUrl + "/course-details/geo-informatics",
  },
  {
    courseName: "Mathematics and Computing",
    courseUrl:
      config.apiUrl + "/course-details/mathematics-and-computing",
  },
  {
    courseName: "Health Care Management",
    courseUrl:
      config.apiUrl + "/course-details/health-care-management",
  },
  {
    courseName: "Materials Management",
    courseUrl:
      config.apiUrl + "/course-details/materials-management",
  },
  {
    courseName: "Industrial Biotechnology",
    courseUrl:
      config.apiUrl + "/course-details/industrial-biotechnology",
  },
  {
    courseName: "Safety and Occupational Health Engineering",
    courseUrl:
      config.apiUrl + "/course-details/safety-and-occupational-health-engineering",
  },
  {
    courseName: "Product Design",
    courseUrl:
      config.apiUrl + "/course-details/product-design",
  },
  {
    courseName: "Telecom Management",
    courseUrl:
      config.apiUrl + "/course-details/telecom-management",
  },
  {
    courseName: "Computer Science",
    courseUrl:
      config.apiUrl + "/course-details/computer-science",
  },
  {
    courseName: "Textile Management",
    courseUrl:
      config.apiUrl + "/course-details/textile-management",
  },
  {
    courseName: "Mechanical Automobile",
    courseUrl:
      config.apiUrl + "/course-details/mechanical-automobile",
  },
  {
    courseName: "Environment Management",
    courseUrl:
      config.apiUrl + "/course-details/environment-management",
  },
  {
    courseName: "Retail Management",
    courseUrl:
      config.apiUrl + "/course-details/retail-management",
  },
  {
    courseName: "Nano Technology and Robotics",
    courseUrl:
      config.apiUrl + "/course-details/nano-technology-and-robotics",
  },
  {
    courseName: "Power Management",
    courseUrl:
      config.apiUrl + "/course-details/power-management",
  },
  {
    courseName: "Mechanical Production Engineering",
    courseUrl:
      config.apiUrl + "/course-details/mechanical-production-engineering",
  },
  {
    courseName: "Ceramic Engineering",
    courseUrl:
      config.apiUrl + "/course-details/ceramic-engineering",
  },
  {
    courseName: "Forestry",
    courseUrl: config.apiUrl + "/course-details/forestry",
  },
  {
    courseName: "Mechanical Engineering - Design and Manufacturing",
    courseUrl:
      config.apiUrl + "/course-details/mechanical-engineering---design-and-manufacturing",
  },
  {
    courseName: "Infrastructure Management",
    courseUrl:
      config.apiUrl + "/course-details/infrastructure-management",
  },
  {
    courseName: "Strategic Management",
    courseUrl:
      config.apiUrl + "/course-details/strategic-management",
  },
  {
    courseName: "Petroleum Management",
    courseUrl:
      config.apiUrl + "/course-details/petroleum-management",
  },
  {
    courseName: "Public Relations",
    courseUrl:
      config.apiUrl + "/course-details/public-relations",
  },
  {
    courseName: "Leather Technology",
    courseUrl:
      config.apiUrl + "/course-details/leather-technology",
  },
  {
    courseName: "Production Engineering and Management",
    courseUrl:
      config.apiUrl + "/course-details/production-engineering-and-management",
  },
  {
    courseName: "Digital Media",
    courseUrl:
      config.apiUrl + "/course-details/digital-media",
  },
  {
    courseName: "Metallurgical and Materials Engineering",
    courseUrl:
      config.apiUrl + "/course-details/metallurgical-and-materials-engineering",
  },
  {
    courseName: "Chemistry",
    courseUrl: config.apiUrl + "/course-details/chemistry",
  },
  {
    courseName: "Biomedical Engineering",
    courseUrl:
      config.apiUrl + "/course-details/biomedical-engineering",
  },
  {
    courseName: "Food Processing",
    courseUrl:
      config.apiUrl + "/course-details/food-processing",
  },
  {
    courseName: "Nano Technology Engineering",
    courseUrl:
      config.apiUrl + "/course-details/nano-technology-engineering",
  },
  {
    courseName: "Pharmaceutical Technology",
    courseUrl:
      config.apiUrl + "/course-details/pharmaceutical-technology",
  },
  {
    courseName: "Engineering Physics",
    courseUrl:
      config.apiUrl + "/course-details/engineering-physics",
  },
  {
    courseName: "Textile Technology",
    courseUrl:
      config.apiUrl + "/course-details/textile-technology",
  },
  {
    courseName: "Naval Architecture",
    courseUrl:
      config.apiUrl + "/course-details/naval-architecture",
  },
  {
    courseName: "Mineral Engineering",
    courseUrl:
      config.apiUrl + "/course-details/mineral-engineering",
  },
  {
    courseName: "Political Science",
    courseUrl:
      config.apiUrl + "/course-details/political-science",
  },
  {
    courseName: "Pharmacy",
    courseUrl: config.apiUrl + "/course-details/pharmacy",
  },
  {
    courseName: "Psychology",
    courseUrl: config.apiUrl + "/course-details/psychology",
  },
  {
    courseName: "Journalism",
    courseUrl: config.apiUrl + "/course-details/journalism",
  },
  {
    courseName: "Fashion Design",
    courseUrl:
      config.apiUrl + "/course-details/fashion-design",
  },
  {
    courseName: "Biotechnology",
    courseUrl:
      config.apiUrl + "/course-details/biotechnology",
  },
  {
    courseName: "Zoology",
    courseUrl: config.apiUrl + "/course-details/zoology",
  },
  {
    courseName: "Fine Arts",
    courseUrl: config.apiUrl + "/course-details/fine-arts",
  },
  {
    courseName: "Production Engineering",
    courseUrl:
      config.apiUrl + "/course-details/production-engineering",
  },
  {
    courseName: "Safety and Fire Engineering",
    courseUrl:
      config.apiUrl + "/course-details/safety-and-fire-engineering",
  },
  {
    courseName: "Business Design and Innovation Management",
    courseUrl:
      config.apiUrl + "/course-details/business-design-and-innovation-management",
  },
  {
    courseName: "Thermal Engineering",
    courseUrl:
      config.apiUrl + "/course-details/thermal-engineering",
  },
  {
    courseName: "English",
    courseUrl: config.apiUrl + "/course-details/english",
  },
  {
    courseName: "Insurance Law",
    courseUrl:
      config.apiUrl + "/course-details/insurance-law",
  },
  {
    courseName: "Alternative Medicine",
    courseUrl:
      config.apiUrl + "/course-details/alternative-medicine",
  },
  {
    courseName: "Applied Mechanics",
    courseUrl:
      config.apiUrl + "/course-details/applied-mechanics",
  },
  {
    courseName: "Cyber Defense",
    courseUrl:
      config.apiUrl + "/course-details/cyber-defense",
  },
  {
    courseName: "Sculpture",
    courseUrl: config.apiUrl + "/course-details/sculpture",
  },
  {
    courseName: "Apparel Design",
    courseUrl:
      config.apiUrl + "/course-details/apparel-design",
  },
  {
    courseName: "Solid Mechanics",
    courseUrl:
      config.apiUrl + "/course-details/solid-mechanics",
  },
  {
    courseName: "Automotive Electronics",
    courseUrl:
      config.apiUrl + "/course-details/automotive-electronics",
  },
  {
    courseName: "Immigration Law",
    courseUrl:
      config.apiUrl + "/course-details/immigration-law",
  },
  {
    courseName: "Construction Technology",
    courseUrl:
      config.apiUrl + "/course-details/construction-technology",
  },
  {
    courseName: "Smart Infrastructure",
    courseUrl:
      config.apiUrl + "/course-details/smart-infrastructure",
  },
  {
    courseName: "Data Management",
    courseUrl:
      config.apiUrl + "/course-details/data-management",
  },
  {
    courseName: "Control System Engineering",
    courseUrl:
      config.apiUrl + "/course-details/control-system-engineering",
  },
  {
    courseName: "Decorative Arts",
    courseUrl:
      config.apiUrl + "/course-details/decorative-arts",
  },
  {
    courseName: "Genome Engineering",
    courseUrl:
      config.apiUrl + "/course-details/genome-engineering",
  },
  {
    courseName: "Theatre",
    courseUrl: config.apiUrl + "/course-details/theatre",
  },
  {
    courseName: "Cloud Technology",
    courseUrl:
      config.apiUrl + "/course-details/cloud-technology",
  },
  {
    courseName: "Intellectual Property Rights",
    courseUrl:
      config.apiUrl + "/course-details/intellectual-property-rights",
  },
  {
    courseName: "Game Technology",
    courseUrl:
      config.apiUrl + "/course-details/game-technology",
  },
  {
    courseName: "Energy Law",
    courseUrl: config.apiUrl + "/course-details/energy-law",
  },
  {
    courseName: "Information Design",
    courseUrl:
      config.apiUrl + "/course-details/information-design",
  },
  {
    courseName: "Intelligent Systems",
    courseUrl:
      config.apiUrl + "/course-details/intelligent-systems",
  },
  {
    courseName: "Enterprise Mobility",
    courseUrl:
      config.apiUrl + "/course-details/enterprise-mobility",
  },
  {
    courseName: "Computational Sustainability",
    courseUrl:
      config.apiUrl + "/course-details/computational-sustainability",
  },
  {
    courseName: "Embedded Systems",
    courseUrl:
      config.apiUrl + "/course-details/embedded-systems",
  },
  {
    courseName: "Infrastructure Development",
    courseUrl:
      config.apiUrl + "/course-details/infrastructure-development",
  },
  {
    courseName: "Interior Architecture and Design",
    courseUrl:
      config.apiUrl + "/course-details/interior-architecture-and-design",
  },
  {
    courseName: "Heritage Management",
    courseUrl:
      config.apiUrl + "/course-details/heritage-management",
  },
  {
    courseName: "Fashion Business Management",
    courseUrl:
      config.apiUrl + "/course-details/fashion-business-management",
  },
  {
    courseName: "Information Security",
    courseUrl:
      config.apiUrl + "/course-details/information-security",
  },
  {
    courseName: "Android Application Development",
    courseUrl:
      config.apiUrl + "/course-details/android-application-development",
  },
  {
    courseName: "Fluid Mechanics",
    courseUrl:
      config.apiUrl + "/course-details/fluid-mechanics",
  },
  {
    courseName: "Fashion Communication",
    courseUrl:
      config.apiUrl + "/course-details/fashion-communication",
  },
  {
    courseName: "Upstream",
    courseUrl: config.apiUrl + "/course-details/upstream",
  },
  {
    courseName: "Augmented and Virtual Reality",
    courseUrl:
      config.apiUrl + "/course-details/augmented-and-virtual-reality",
  },
  {
    courseName: "Financial Modelling",
    courseUrl:
      config.apiUrl + "/course-details/financial-modelling",
  },
  {
    courseName: "Cost Accountancy",
    courseUrl:
      config.apiUrl + "/course-details/cost-accountancy",
  },
  {
    courseName: "Paediatrics",
    courseUrl:
      config.apiUrl + "/course-details/paediatrics",
  },
  {
    courseName: "Industrial Relations and Labour Legislation",
    courseUrl:
      config.apiUrl + "/course-details/industrial-relations-and-labour-legislation",
  },
  {
    courseName: "Deep Learning",
    courseUrl:
      config.apiUrl + "/course-details/deep-learning",
  },
  {
    courseName: "International Trade Law",
    courseUrl:
      config.apiUrl + "/course-details/international-trade-law",
  },
  {
    courseName: "Rural Studies",
    courseUrl:
      config.apiUrl + "/course-details/rural-studies",
  },
  {
    courseName: "Cardiology Laboratory Techniques",
    courseUrl:
      config.apiUrl + "/course-details/cardiology-laboratory-techniques",
  },
  {
    courseName: "Radio Diagnosis",
    courseUrl:
      config.apiUrl + "/course-details/radio-diagnosis",
  },
  {
    courseName: "Event Management",
    courseUrl:
      config.apiUrl + "/course-details/event-management",
  },
  {
    courseName: "Community Health Administration",
    courseUrl:
      config.apiUrl + "/course-details/community-health-administration",
  },
  {
    courseName: "Managerial Economics",
    courseUrl:
      config.apiUrl + "/course-details/managerial-economics",
  },
  {
    courseName: "System Design",
    courseUrl:
      config.apiUrl + "/course-details/system-design",
  },
  {
    courseName: "Criminal Law",
    courseUrl:
      config.apiUrl + "/course-details/criminal-law",
  },
  {
    courseName: "Tally",
    courseUrl: config.apiUrl + "/course-details/tally",
  },
  {
    courseName: "Microbiology",
    courseUrl:
      config.apiUrl + "/course-details/microbiology",
  },
  {
    courseName: "Company Secretary",
    courseUrl:
      config.apiUrl + "/course-details/company-secretary",
  },
  {
    courseName: "Bartending",
    courseUrl: config.apiUrl + "/course-details/bartending",
  },
  {
    courseName: "Humanities and Social Sciences",
    courseUrl:
      config.apiUrl + "/course-details/humanities-and-social-sciences",
  },
  {
    courseName: "Business Management Studies",
    courseUrl:
      config.apiUrl + "/course-details/business-management-studies",
  },
  {
    courseName: "Mobile App Development",
    courseUrl:
      config.apiUrl + "/course-details/mobile-app-development",
  },
  {
    courseName: "Telecommunication Engineering",
    courseUrl:
      config.apiUrl + "/course-details/telecommunication-engineering",
  },
  {
    courseName: "Beauty and Fitness",
    courseUrl:
      config.apiUrl + "/course-details/beauty-and-fitness",
  },
  {
    courseName: "AutoCAD",
    courseUrl: config.apiUrl + "/course-details/autocad",
  },
  {
    courseName: "Sound Engineering",
    courseUrl:
      config.apiUrl + "/course-details/sound-engineering",
  },
  {
    courseName: "Internet of Things",
    courseUrl:
      config.apiUrl + "/course-details/internet-of-things",
  },
  {
    courseName: "Food Technology",
    courseUrl:
      config.apiUrl + "/course-details/food-technology",
  },
  {
    courseName: "Perfusion Technology",
    courseUrl:
      config.apiUrl + "/course-details/perfusion-technology",
  },
  {
    courseName: "Applied Arts",
    courseUrl:
      config.apiUrl + "/course-details/applied-arts",
  },
  {
    courseName: "Footwear design",
    courseUrl:
      config.apiUrl + "/course-details/footwear-design",
  },
  {
    courseName: "Networking, Hardware and Security",
    courseUrl:
      config.apiUrl + "/course-details/networking,-hardware-and-security",
  },
  {
    courseName: "Hospitality and Travel",
    courseUrl:
      config.apiUrl + "/course-details/hospitality-and-travel",
  },
  {
    courseName: "Massage and Spa Therapy",
    courseUrl:
      config.apiUrl + "/course-details/massage-and-spa-therapy",
  },
  {
    courseName: "Modelling",
    courseUrl: config.apiUrl + "/course-details/modelling",
  },
  {
    courseName: "Visual Merchandising",
    courseUrl:
      config.apiUrl + "/course-details/visual-merchandising",
  },
  {
    courseName: "Ethical Hacking",
    courseUrl:
      config.apiUrl + "/course-details/ethical-hacking",
  },
  {
    courseName: "Nutrition and Dietetics",
    courseUrl:
      config.apiUrl + "/course-details/nutrition-and-dietetics",
  },
  {
    courseName: "Exhibition Design",
    courseUrl:
      config.apiUrl + "/course-details/exhibition-design",
  },
  {
    courseName: "Cyber Security Ethical Hacking",
    courseUrl:
      config.apiUrl + "/course-details/cyber-security-ethical-hacking",
  },
  {
    courseName: "Echo Cardiology and Cardiac Technology",
    courseUrl:
      config.apiUrl + "/course-details/echo-cardiology-and-cardiac-technology",
  },
  {
    courseName: "Vocational Education",
    courseUrl:
      config.apiUrl + "/course-details/vocational-education",
  },
  {
    courseName: "UI UX",
    courseUrl: config.apiUrl + "/course-details/ui-ux",
  },
  {
    courseName: "Food and Beverage Service",
    courseUrl:
      config.apiUrl + "/course-details/food-and-beverage-service",
  },
  {
    courseName: "Bakery and Confectionery",
    courseUrl:
      config.apiUrl + "/course-details/bakery-and-confectionery",
  },
  {
    courseName: "Food Nutrition",
    courseUrl:
      config.apiUrl + "/course-details/food-nutrition",
  },
  {
    courseName: "AI and Robotics",
    courseUrl:
      config.apiUrl + "/course-details/ai-and-robotics",
  },
  {
    courseName: "Medical Transcription",
    courseUrl:
      config.apiUrl + "/course-details/medical-transcription",
  },
  {
    courseName: "Mobile Repairing",
    courseUrl:
      config.apiUrl + "/course-details/mobile-repairing",
  },
  {
    courseName: "Film and Video Design",
    courseUrl:
      config.apiUrl + "/course-details/film-and-video-design",
  },
  {
    courseName: "Liberal Arts",
    courseUrl:
      config.apiUrl + "/course-details/liberal-arts",
  },
  {
    courseName: "Cardiovascular Technology",
    courseUrl:
      config.apiUrl + "/course-details/cardiovascular-technology",
  },
  {
    courseName: "Painting and Drawing",
    courseUrl:
      config.apiUrl + "/course-details/painting-and-drawing",
  },
  {
    courseName: "Visual Arts",
    courseUrl:
      config.apiUrl + "/course-details/visual-arts",
  },
  {
    courseName: "DOEACC O Level",
    courseUrl:
      config.apiUrl + "/course-details/doeacc-o-level",
  },
  {
    courseName: "Clinical Psychology",
    courseUrl:
      config.apiUrl + "/course-details/clinical-psychology",
  },
  {
    courseName: "Transport Logistics",
    courseUrl:
      config.apiUrl + "/course-details/transport-logistics",
  },
  {
    courseName: "Company Law",
    courseUrl:
      config.apiUrl + "/course-details/company-law",
  },
  {
    courseName: "Family Law",
    courseUrl: config.apiUrl + "/course-details/family-law",
  },
  {
    courseName: "Dance and Choreography",
    courseUrl:
      config.apiUrl + "/course-details/dance-and-choreography",
  },
  {
    courseName: "Engineering Design",
    courseUrl:
      config.apiUrl + "/course-details/engineering-design",
  },
  {
    courseName: "Accounting Management",
    courseUrl:
      config.apiUrl + "/course-details/accounting-management",
  },
  {
    courseName: "Clinical Pathology",
    courseUrl:
      config.apiUrl + "/course-details/clinical-pathology",
  },
  {
    courseName: "Architecture, Town and Regional Planning",
    courseUrl:
      config.apiUrl + "/course-details/architecture,-town-and-regional-planning",
  },
  {
    courseName: "Biopharmaceutics",
    courseUrl:
      config.apiUrl + "/course-details/biopharmaceutics",
  },
  {
    courseName: "Competition Law",
    courseUrl:
      config.apiUrl + "/course-details/competition-law",
  },
  {
    courseName: "Communication Engineering",
    courseUrl:
      config.apiUrl + "/course-details/communication-engineering",
  },
  {
    courseName: "Ceramic Design",
    courseUrl:
      config.apiUrl + "/course-details/ceramic-design",
  },
  {
    courseName: "Clinical Toxicology",
    courseUrl:
      config.apiUrl + "/course-details/clinical-toxicology",
  },
  {
    courseName: "Applied Geophysics",
    courseUrl:
      config.apiUrl + "/course-details/applied-geophysics",
  },
  {
    courseName: "Civil Law",
    courseUrl: config.apiUrl + "/course-details/civil-law",
  },
  {
    courseName: "Artificial Intelligence",
    courseUrl:
      config.apiUrl + "/course-details/artificial-intelligence",
  },
  {
    courseName: "Chemical Science and Technology",
    courseUrl:
      config.apiUrl + "/course-details/chemical-science-and-technology",
  },
  {
    courseName: "Consumer Behaviour",
    courseUrl:
      config.apiUrl + "/course-details/consumer-behaviour",
  },
  {
    courseName: "Ayurvedic Medicine and Surgery",
    courseUrl:
      config.apiUrl + "/course-details/ayurvedic-medicine-and-surgery",
  },
  {
    courseName: "Knitwear Design",
    courseUrl:
      config.apiUrl + "/course-details/knitwear-design",
  },
  {
    courseName: "Communication Design",
    courseUrl:
      config.apiUrl + "/course-details/communication-design",
  },
  {
    courseName: "Medical Laboratory Technology",
    courseUrl:
      config.apiUrl + "/course-details/medical-laboratory-technology",
  },
  {
    courseName: "Glass Design",
    courseUrl:
      config.apiUrl + "/course-details/glass-design",
  },
  {
    courseName: "Consumer Law",
    courseUrl:
      config.apiUrl + "/course-details/consumer-law",
  },
  {
    courseName: "Communication Management",
    courseUrl:
      config.apiUrl + "/course-details/communication-management",
  },
  {
    courseName: "Computer Aided Design",
    courseUrl:
      config.apiUrl + "/course-details/computer-aided-design",
  },
  {
    courseName: "Furniture Design",
    courseUrl:
      config.apiUrl + "/course-details/furniture-design",
  },
  {
    courseName: "Structural Engineering",
    courseUrl:
      config.apiUrl + "/course-details/structural-engineering",
  },
  {
    courseName: "Digital Marketing",
    courseUrl:
      config.apiUrl + "/course-details/digital-marketing",
  },
  {
    courseName: "Public Policy",
    courseUrl:
      config.apiUrl + "/course-details/public-policy",
  },
  {
    courseName: "Management",
    courseUrl: config.apiUrl + "/course-details/management",
  },
  {
    courseName: "Automotive Design",
    courseUrl:
      config.apiUrl + "/course-details/automotive-design",
  },
  {
    courseName: "Microbiology and Immunology Engineering",
    courseUrl:
      config.apiUrl + "/course-details/microbiology-and-immunology-engineering",
  },
  {
    courseName: "Astrology",
    courseUrl: config.apiUrl + "/course-details/astrology",
  },
  {
    courseName: "Language",
    courseUrl: config.apiUrl + "/course-details/language",
  },
  {
    courseName: "Power Electronics",
    courseUrl:
      config.apiUrl + "/course-details/power-electronics",
  },
  {
    courseName: "Medical Radiography",
    courseUrl:
      config.apiUrl + "/course-details/medical-radiography",
  },
  {
    courseName: "Hospitality",
    courseUrl:
      config.apiUrl + "/course-details/hospitality",
  },
  {
    courseName: "E-Commerce",
    courseUrl: config.apiUrl + "/course-details/e-commerce",
  },
  {
    courseName: "Aircraft Maintenance Engineering",
    courseUrl:
      config.apiUrl + "/course-details/aircraft-maintenance-engineering",
  },
  {
    courseName: "Archaeology",
    courseUrl:
      config.apiUrl + "/course-details/archaeology",
  },
  {
    courseName: "Robotics Engineering",
    courseUrl:
      config.apiUrl + "/course-details/robotics-engineering",
  },
  {
    courseName: "Cost Management Accounting",
    courseUrl:
      config.apiUrl + "/course-details/cost-management-accounting",
  },
  {
    courseName: "Cloud Computing",
    courseUrl:
      config.apiUrl + "/course-details/cloud-computing",
  },
  {
    courseName: "Export Import Management",
    courseUrl:
      config.apiUrl + "/course-details/export-import-management",
  },
  {
    courseName: "Film Acting",
    courseUrl:
      config.apiUrl + "/course-details/film-acting",
  },
  {
    courseName: "Foreign Trade",
    courseUrl:
      config.apiUrl + "/course-details/foreign-trade",
  },
  {
    courseName: "Earthquake Engineering",
    courseUrl:
      config.apiUrl + "/course-details/earthquake-engineering",
  },
  {
    courseName: "Business Law",
    courseUrl:
      config.apiUrl + "/course-details/business-law",
  },
  {
    courseName: "ENT",
    courseUrl: config.apiUrl + "/course-details/ent",
  },
  {
    courseName: "Pilot Training",
    courseUrl:
      config.apiUrl + "/course-details/pilot-training",
  },
  {
    courseName: "Avionics",
    courseUrl: config.apiUrl + "/course-details/avionics",
  },
  {
    courseName: "Medical Operation Theatre Technology",
    courseUrl:
      config.apiUrl + "/course-details/medical-operation-theatre-technology",
  },
  {
    courseName: "Culinary Arts",
    courseUrl:
      config.apiUrl + "/course-details/culinary-arts",
  },
  {
    courseName: "Dental Materials",
    courseUrl:
      config.apiUrl + "/course-details/dental-materials",
  },
  {
    courseName: "Neonatology",
    courseUrl:
      config.apiUrl + "/course-details/neonatology",
  },
  {
    courseName: "Pharmaceutical Engineering and Technology",
    courseUrl:
      config.apiUrl + "/course-details/pharmaceutical-engineering-and-technology",
  },
  {
    courseName: "Finance",
    courseUrl: config.apiUrl + "/course-details/finance",
  },
  {
    courseName: "System Analysis",
    courseUrl:
      config.apiUrl + "/course-details/system-analysis",
  },
  {
    courseName: "Banking Law",
    courseUrl:
      config.apiUrl + "/course-details/banking-law",
  },
  {
    courseName: "Electronics and Instrumentation Engineering",
    courseUrl:
      config.apiUrl + "/course-details/electronics-and-instrumentation-engineering",
  },
  {
    courseName: "Cyber Security",
    courseUrl:
      config.apiUrl + "/course-details/cyber-security",
  },
  {
    courseName: "Thermal Science and Engineering",
    courseUrl:
      config.apiUrl + "/course-details/thermal-science-and-engineering",
  },
  {
    courseName: "Electronics and Electrical Communication Engineering",
    courseUrl:
      config.apiUrl + "/course-details/electronics-and-electrical-communication-engineering",
  },
  {
    courseName: "Space Technology",
    courseUrl:
      config.apiUrl + "/course-details/space-technology",
  },
  {
    courseName: "Catering",
    courseUrl: config.apiUrl + "/course-details/catering",
  },
  {
    courseName: "Data Science",
    courseUrl:
      config.apiUrl + "/course-details/data-science",
  },
  {
    courseName: "Software Engineering",
    courseUrl:
      config.apiUrl + "/course-details/software-engineering",
  },
  {
    courseName: "Medical Record and Health Information Management",
    courseUrl:
      config.apiUrl + "/course-details/medical-record-and-health-information-management",
  },
  {
    courseName: "Naval Architecture and Ocean Engineering",
    courseUrl:
      config.apiUrl + "/course-details/naval-architecture-and-ocean-engineering",
  },
  {
    courseName: "Medical Imaging",
    courseUrl:
      config.apiUrl + "/course-details/medical-imaging",
  },
  {
    courseName: "VLSI Design",
    courseUrl:
      config.apiUrl + "/course-details/vlsi-design",
  },
  {
    courseName: "Machine Learning",
    courseUrl:
      config.apiUrl + "/course-details/machine-learning",
  },
  {
    courseName: "Visual Effects",
    courseUrl:
      config.apiUrl + "/course-details/visual-effects",
  },
  {
    courseName: "Manufacturing Science and Engineering",
    courseUrl:
      config.apiUrl + "/course-details/manufacturing-science-and-engineering",
  },
  {
    courseName: "Radiotherapy",
    courseUrl:
      config.apiUrl + "/course-details/radiotherapy",
  },
  {
    courseName: "Business Administration",
    courseUrl:
      config.apiUrl + "/course-details/business-administration",
  },
  {
    courseName: "Operation Theatre Techniques",
    courseUrl:
      config.apiUrl + "/course-details/operation-theatre-techniques",
  },
  {
    courseName: "Video Editing",
    courseUrl:
      config.apiUrl + "/course-details/video-editing",
  },
  {
    courseName: "Astronomy",
    courseUrl: config.apiUrl + "/course-details/astronomy",
  },
  {
    courseName: "Power Engineering",
    courseUrl:
      config.apiUrl + "/course-details/power-engineering",
  },
  {
    courseName: "Marine Biology",
    courseUrl:
      config.apiUrl + "/course-details/marine-biology",
  },
  {
    courseName: "Library and Information Science",
    courseUrl:
      config.apiUrl + "/course-details/library-and-information-science",
  },
  {
    courseName: "Religious Studies",
    courseUrl:
      config.apiUrl + "/course-details/religious-studies",
  },
  {
    courseName: "Microelectronics",
    courseUrl:
      config.apiUrl + "/course-details/microelectronics",
  },
  {
    courseName: "Furniture and Interior Design",
    courseUrl:
      config.apiUrl + "/course-details/furniture-and-interior-design",
  },
  {
    courseName: "Linguistics",
    courseUrl:
      config.apiUrl + "/course-details/linguistics",
  },
  {
    courseName: "Data and Computational Science",
    courseUrl:
      config.apiUrl + "/course-details/data-and-computational-science",
  },
  {
    courseName: "Literature",
    courseUrl: config.apiUrl + "/course-details/literature",
  },
  {
    courseName: "Anaesthesiology",
    courseUrl:
      config.apiUrl + "/course-details/anaesthesiology",
  },
  {
    courseName: "Engineering",
    courseUrl:
      config.apiUrl + "/course-details/engineering",
  },
  {
    courseName: "RF and Microwave Engineering",
    courseUrl:
      config.apiUrl + "/course-details/rf-and-microwave-engineering",
  },
  {
    courseName: "Instrumentation and Control Engineering",
    courseUrl:
      config.apiUrl + "/course-details/instrumentation-and-control-engineering",
  },
  {
    courseName: "Applied Geology",
    courseUrl:
      config.apiUrl + "/course-details/applied-geology",
  },
  {
    courseName: "Electronics and Telecommunications Engineering",
    courseUrl:
      config.apiUrl + "/course-details/electronics-and-telecommunications-engineering",
  },
  {
    courseName: "Business Economics",
    courseUrl:
      config.apiUrl + "/course-details/business-economics",
  },
  {
    courseName: "Astrophysics",
    courseUrl:
      config.apiUrl + "/course-details/astrophysics",
  },
  {
    courseName: "Community Medicine",
    courseUrl:
      config.apiUrl + "/course-details/community-medicine",
  },
  {
    courseName: "Advanced Manufacturing",
    courseUrl:
      config.apiUrl + "/course-details/advanced-manufacturing",
  },
  {
    courseName: "Applied Pharmacology",
    courseUrl:
      config.apiUrl + "/course-details/applied-pharmacology",
  },
  {
    courseName: "Game Design",
    courseUrl:
      config.apiUrl + "/course-details/game-design",
  },
  {
    courseName: "Optometry",
    courseUrl: config.apiUrl + "/course-details/optometry",
  },
  {
    courseName: "Financial Management",
    courseUrl:
      config.apiUrl + "/course-details/financial-management",
  },
  {
    courseName: "Cardiology",
    courseUrl: config.apiUrl + "/course-details/cardiology",
  },
  {
    courseName: "Sales and Marketing",
    courseUrl:
      config.apiUrl + "/course-details/sales-and-marketing",
  },
  {
    courseName: "Occupational Therapy",
    courseUrl:
      config.apiUrl + "/course-details/occupational-therapy",
  },
  {
    courseName: "Entrepreneurship",
    courseUrl:
      config.apiUrl + "/course-details/entrepreneurship",
  },
  {
    courseName: "Banking and Insurance",
    courseUrl:
      config.apiUrl + "/course-details/banking-and-insurance",
  },
  {
    courseName: "Interior Design",
    courseUrl:
      config.apiUrl + "/course-details/interior-design",
  },
  {
    courseName: "Animation",
    courseUrl: config.apiUrl + "/course-details/animation",
  },
  {
    courseName: "Transportation Engineering",
    courseUrl:
      config.apiUrl + "/course-details/transportation-engineering",
  },
  {
    courseName: "Graphic Designing",
    courseUrl:
      config.apiUrl + "/course-details/graphic-designing",
  },
  {
    courseName: "Construction Management",
    courseUrl:
      config.apiUrl + "/course-details/construction-management",
  },
  {
    courseName: "Mechanics",
    courseUrl: config.apiUrl + "/course-details/mechanics",
  },
  {
    courseName: "Family Business Management",
    courseUrl:
      config.apiUrl + "/course-details/family-business-management",
  },
  {
    courseName: "Pharmaceutical Management",
    courseUrl:
      config.apiUrl + "/course-details/pharmaceutical-management",
  },
  {
    courseName: "Hotel Management",
    courseUrl:
      config.apiUrl + "/course-details/hotel-management",
  },
  {
    courseName: "Marine Engineering",
    courseUrl:
      config.apiUrl + "/course-details/marine-engineering",
  },
  {
    courseName: "Information Technology",
    courseUrl:
      config.apiUrl + "/course-details/information-technology",
  },
  {
    courseName: "International Business Communication",
    courseUrl:
      config.apiUrl + "/course-details/international-business-communication",
  },
  {
    courseName: "IT and Systems Management",
    courseUrl:
      config.apiUrl + "/course-details/it-and-systems-management",
  },
  {
    courseName: "Textile Chemistry",
    courseUrl:
      config.apiUrl + "/course-details/textile-chemistry",
  },
  {
    courseName: "Plastic Engineering",
    courseUrl:
      config.apiUrl + "/course-details/plastic-engineering",
  },
  {
    courseName: "Risk Management",
    courseUrl:
      config.apiUrl + "/course-details/risk-management",
  },
  {
    courseName: "Systems Science Engineering",
    courseUrl:
      config.apiUrl + "/course-details/systems-science-engineering",
  },
  {
    courseName: "Mining Machinery Engineering",
    courseUrl:
      config.apiUrl + "/course-details/mining-machinery-engineering",
  },
  {
    courseName: "Engineering Chemistry",
    courseUrl:
      config.apiUrl + "/course-details/engineering-chemistry",
  },
  {
    courseName: "Printing Graphics and Packaging",
    courseUrl:
      config.apiUrl + "/course-details/printing-graphics-and-packaging",
  },
  {
    courseName: "Manufacturing Process and Automation Engineering",
    courseUrl:
      config.apiUrl + "/course-details/manufacturing-process-and-automation-engineering",
  },
  {
    courseName: "Paint Technology",
    courseUrl:
      config.apiUrl + "/course-details/paint-technology",
  },
  {
    courseName: "Man Made Fiber Technology",
    courseUrl:
      config.apiUrl + "/course-details/man-made-fiber-technology",
  },
  {
    courseName: "Systems Management",
    courseUrl:
      config.apiUrl + "/course-details/systems-management",
  },
  {
    courseName: "Planning Engineering",
    courseUrl:
      config.apiUrl + "/course-details/planning-engineering",
  },
  {
    courseName: "Industrial Engineering",
    courseUrl:
      config.apiUrl + "/course-details/industrial-engineering",
  },
  {
    courseName: "Web Designing",
    courseUrl:
      config.apiUrl + "/course-details/web-designing",
  },
  {
    courseName: "Geology",
    courseUrl: config.apiUrl + "/course-details/geology",
  },
  {
    courseName: "Jute and Fiber Technology",
    courseUrl:
      config.apiUrl + "/course-details/jute-and-fiber-technology",
  },
  {
    courseName: "Pharmaceutics",
    courseUrl:
      config.apiUrl + "/course-details/pharmaceutics",
  },
  {
    courseName: "Precision Manufacturing",
    courseUrl:
      config.apiUrl + "/course-details/precision-manufacturing",
  },
  {
    courseName: "Pharmaceutical Biotechnology",
    courseUrl:
      config.apiUrl + "/course-details/pharmaceutical-biotechnology",
  },
  {
    courseName: "Pharmaceutical Analysis",
    courseUrl:
      config.apiUrl + "/course-details/pharmaceutical-analysis",
  },
  {
    courseName: "Sugar Technology",
    courseUrl:
      config.apiUrl + "/course-details/sugar-technology",
  },
  {
    courseName: "Carpet and Textile Technology",
    courseUrl:
      config.apiUrl + "/course-details/carpet-and-textile-technology",
  },
  {
    courseName: "Printing and Packaging Technology",
    courseUrl:
      config.apiUrl + "/course-details/printing-and-packaging-technology",
  },
  {
    courseName: "Organisational Behaviour",
    courseUrl:
      config.apiUrl + "/course-details/organisational-behaviour",
  },
  {
    courseName: "Rural Marketing",
    courseUrl:
      config.apiUrl + "/course-details/rural-marketing",
  },
  {
    courseName: "Rubber Technology",
    courseUrl:
      config.apiUrl + "/course-details/rubber-technology",
  },
  {
    courseName: "Nephrology",
    courseUrl: config.apiUrl + "/course-details/nephrology",
  },
  {
    courseName: "Service Management",
    courseUrl:
      config.apiUrl + "/course-details/service-management",
  },
  {
    courseName: "Textile Production",
    courseUrl:
      config.apiUrl + "/course-details/textile-production",
  },
  {
    courseName: "Silk Technology",
    courseUrl:
      config.apiUrl + "/course-details/silk-technology",
  },
  {
    courseName: "Automobile Marketing",
    courseUrl:
      config.apiUrl + "/course-details/automobile-marketing",
  },
  {
    courseName: "Unani Medicine",
    courseUrl:
      config.apiUrl + "/course-details/unani-medicine",
  },
  {
    courseName: "Instrumentation Sciences",
    courseUrl:
      config.apiUrl + "/course-details/instrumentation-sciences",
  },
  {
    courseName: "Oral Surgery",
    courseUrl:
      config.apiUrl + "/course-details/oral-surgery",
  },
  {
    courseName: "Chartered Accountancy",
    courseUrl:
      config.apiUrl + "/course-details/chartered-accountancy",
  },
  {
    courseName: "Acupuncture",
    courseUrl:
      config.apiUrl + "/course-details/acupuncture",
  },
  {
    courseName: "Andrology",
    courseUrl: config.apiUrl + "/course-details/andrology",
  },
  {
    courseName: "Acting",
    courseUrl: config.apiUrl + "/course-details/acting",
  },
  {
    courseName: "Audiology and Speech",
    courseUrl:
      config.apiUrl + "/course-details/audiology-and-speech",
  },
  {
    courseName: "Microbiology and Virology",
    courseUrl:
      config.apiUrl + "/course-details/microbiology-and-virology",
  },
  {
    courseName: "Cosmeceutics",
    courseUrl:
      config.apiUrl + "/course-details/cosmeceutics",
  },
  {
    courseName: "Cardiology and Thoracic Science",
    courseUrl:
      config.apiUrl + "/course-details/cardiology-and-thoracic-science",
  },
  {
    courseName: "Arabic",
    courseUrl: config.apiUrl + "/course-details/arabic",
  },
  {
    courseName: "Veterinary Science",
    courseUrl:
      config.apiUrl + "/course-details/veterinary-science",
  },
  {
    courseName: "Allergy and Immunology",
    courseUrl:
      config.apiUrl + "/course-details/allergy-and-immunology",
  },
  {
    courseName: "Agricultural Science",
    courseUrl:
      config.apiUrl + "/course-details/agricultural-science",
  },
  {
    courseName: "Anesthesiology and Pallative Medicine",
    courseUrl:
      config.apiUrl + "/course-details/anesthesiology-and-pallative-medicine",
  },
  {
    courseName: "Commerce",
    courseUrl: config.apiUrl + "/course-details/commerce",
  },
  {
    courseName: "Comparative Law",
    courseUrl:
      config.apiUrl + "/course-details/comparative-law",
  },
  {
    courseName: "Community Health Nursing",
    courseUrl:
      config.apiUrl + "/course-details/community-health-nursing",
  },
  {
    courseName: "Clinical Epidemiology",
    courseUrl:
      config.apiUrl + "/course-details/clinical-epidemiology",
  },
  {
    courseName: "Community Medicine and Public Health",
    courseUrl:
      config.apiUrl + "/course-details/community-medicine-and-public-health",
  },
  {
    courseName: "Communication",
    courseUrl:
      config.apiUrl + "/course-details/communication",
  },
  {
    courseName: "Cinematography",
    courseUrl:
      config.apiUrl + "/course-details/cinematography",
  },
  {
    courseName: "Clinical Research",
    courseUrl:
      config.apiUrl + "/course-details/clinical-research",
  },
  {
    courseName: "Clinical Embryology",
    courseUrl:
      config.apiUrl + "/course-details/clinical-embryology",
  },
  {
    courseName: "Cosmetics Technology",
    courseUrl:
      config.apiUrl + "/course-details/cosmetics-technology",
  },
  {
    courseName: "Computer Management",
    courseUrl:
      config.apiUrl + "/course-details/computer-management",
  },
  {
    courseName: "Cosmetic Dentistry",
    courseUrl:
      config.apiUrl + "/course-details/cosmetic-dentistry",
  },
  {
    courseName: "Customer Relationship Management",
    courseUrl:
      config.apiUrl + "/course-details/customer-relationship-management",
  },
  {
    courseName: "Creative Writing",
    courseUrl:
      config.apiUrl + "/course-details/creative-writing",
  },
  {
    courseName: "Computer Applications",
    courseUrl:
      config.apiUrl + "/course-details/computer-applications",
  },
  {
    courseName: "Conservative Dentistry and Endodontics",
    courseUrl:
      config.apiUrl + "/course-details/conservative-dentistry-and-endodontics",
  },
  {
    courseName: "Direction and Screenplay Writing",
    courseUrl:
      config.apiUrl + "/course-details/direction-and-screenplay-writing",
  },
  {
    courseName: "Dental Radiography",
    courseUrl:
      config.apiUrl + "/course-details/dental-radiography",
  },
  {
    courseName: "Diabetology",
    courseUrl:
      config.apiUrl + "/course-details/diabetology",
  },
  {
    courseName: "Corporate Secretaryship",
    courseUrl:
      config.apiUrl + "/course-details/corporate-secretaryship",
  },
  {
    courseName: "Critical Care Nursing",
    courseUrl:
      config.apiUrl + "/course-details/critical-care-nursing",
  },
  {
    courseName: "Corporate Law",
    courseUrl:
      config.apiUrl + "/course-details/corporate-law",
  },
  {
    courseName: "Family Medicine",
    courseUrl:
      config.apiUrl + "/course-details/family-medicine",
  },
  {
    courseName: "Dermatology and Venereology",
    courseUrl:
      config.apiUrl + "/course-details/dermatology-and-venereology",
  },
  {
    courseName: "Earth Science",
    courseUrl:
      config.apiUrl + "/course-details/earth-science",
  },
  {
    courseName: "Engineering Science",
    courseUrl:
      config.apiUrl + "/course-details/engineering-science",
  },
  {
    courseName: "Digital Governance",
    courseUrl:
      config.apiUrl + "/course-details/digital-governance",
  },
  {
    courseName: "Environmental Law",
    courseUrl:
      config.apiUrl + "/course-details/environmental-law",
  },
  {
    courseName: "Engineering Management",
    courseUrl:
      config.apiUrl + "/course-details/engineering-management",
  },
  {
    courseName: "Development Studies",
    courseUrl:
      config.apiUrl + "/course-details/development-studies",
  },
  {
    courseName: "Energy Engineering",
    courseUrl:
      config.apiUrl + "/course-details/energy-engineering",
  },
  {
    courseName: "Electronics Sciences",
    courseUrl:
      config.apiUrl + "/course-details/electronics-sciences",
  },
  {
    courseName: "Design",
    courseUrl: config.apiUrl + "/course-details/design",
  },
  {
    courseName: "Economics",
    courseUrl: config.apiUrl + "/course-details/economics",
  },
  {
    courseName: "Computer Science Engineering",
    courseUrl:
      config.apiUrl + "/course-details/computer-science-engineering",
  },
  {
    courseName: "Environmental Studies",
    courseUrl:
      config.apiUrl + "/course-details/environmental-studies",
  },
  {
    courseName: "Education",
    courseUrl: config.apiUrl + "/course-details/education",
  },
  {
    courseName: "Gemology",
    courseUrl: config.apiUrl + "/course-details/gemology",
  },
  {
    courseName: "General Nursing and Midwifery",
    courseUrl:
      config.apiUrl + "/course-details/general-nursing-and-midwifery",
  },
  {
    courseName: "Haematology",
    courseUrl:
      config.apiUrl + "/course-details/haematology",
  },
  {
    courseName: "Gynaecology and Obstetrics",
    courseUrl:
      config.apiUrl + "/course-details/gynaecology-and-obstetrics",
  },
  {
    courseName: "Genetics",
    courseUrl: config.apiUrl + "/course-details/genetics",
  },
  {
    courseName: "General Surgery",
    courseUrl:
      config.apiUrl + "/course-details/general-surgery",
  },
  {
    courseName: "Intensive Care Medicine",
    courseUrl:
      config.apiUrl + "/course-details/intensive-care-medicine",
  },
  {
    courseName: "Internal Medicine",
    courseUrl:
      config.apiUrl + "/course-details/internal-medicine",
  },
  {
    courseName: "Health Informatics",
    courseUrl:
      config.apiUrl + "/course-details/health-informatics",
  },
  {
    courseName: "German",
    courseUrl: config.apiUrl + "/course-details/german",
  },
  {
    courseName: "Military Medicine",
    courseUrl:
      config.apiUrl + "/course-details/military-medicine",
  },
  {
    courseName: "Industrial Pharmacy",
    courseUrl:
      config.apiUrl + "/course-details/industrial-pharmacy",
  },
  {
    courseName: "Hospitality Management",
    courseUrl:
      config.apiUrl + "/course-details/hospitality-management",
  },
  {
    courseName: "Home Science",
    courseUrl:
      config.apiUrl + "/course-details/home-science",
  },
  {
    courseName: "Jewellery Design",
    courseUrl:
      config.apiUrl + "/course-details/jewellery-design",
  },
  {
    courseName: "Hindi",
    courseUrl: config.apiUrl + "/course-details/hindi",
  },
  {
    courseName: "Medical-Surgical Nursing",
    courseUrl:
      config.apiUrl + "/course-details/medical-surgical-nursing",
  },
  {
    courseName: "IP and Technology Law",
    courseUrl:
      config.apiUrl + "/course-details/ip-and-technology-law",
  },
  {
    courseName: "Insurance",
    courseUrl: config.apiUrl + "/course-details/insurance",
  },
  {
    courseName: "Italian",
    courseUrl: config.apiUrl + "/course-details/italian",
  },
  {
    courseName: "Korean",
    courseUrl: config.apiUrl + "/course-details/korean",
  },
  {
    courseName: "Horticulture",
    courseUrl:
      config.apiUrl + "/course-details/horticulture",
  },
  {
    courseName: "Information Science",
    courseUrl:
      config.apiUrl + "/course-details/information-science",
  },
  {
    courseName: "International Business",
    courseUrl:
      config.apiUrl + "/course-details/international-business",
  },
  {
    courseName: "Japanese",
    courseUrl: config.apiUrl + "/course-details/japanese",
  },
  {
    courseName: "Music",
    courseUrl: config.apiUrl + "/course-details/music",
  },
  {
    courseName: "Nuclear Medicine",
    courseUrl:
      config.apiUrl + "/course-details/nuclear-medicine",
  },
  {
    courseName: "Library Science",
    courseUrl:
      config.apiUrl + "/course-details/library-science",
  },
  {
    courseName: "Oncology Nursing",
    courseUrl:
      config.apiUrl + "/course-details/oncology-nursing",
  },
  {
    courseName: "Medical Biotechnology",
    courseUrl:
      config.apiUrl + "/course-details/medical-biotechnology",
  },
  {
    courseName: "Naturopathy",
    courseUrl:
      config.apiUrl + "/course-details/naturopathy",
  },
  {
    courseName: "Nautical Science",
    courseUrl:
      config.apiUrl + "/course-details/nautical-science",
  },
  {
    courseName: "NGO Management",
    courseUrl:
      config.apiUrl + "/course-details/ngo-management",
  },
  {
    courseName: "Oncology and Radiotherapy",
    courseUrl:
      config.apiUrl + "/course-details/oncology-and-radiotherapy",
  },
  {
    courseName: "Ortho and Rehabilitation Nursing",
    courseUrl:
      config.apiUrl + "/course-details/ortho-and-rehabilitation-nursing",
  },
  {
    courseName: "Neurology",
    courseUrl: config.apiUrl + "/course-details/neurology",
  },
  {
    courseName: "Life Science",
    courseUrl:
      config.apiUrl + "/course-details/life-science",
  },
  {
    courseName: "Paediatric Surgery",
    courseUrl:
      config.apiUrl + "/course-details/paediatric-surgery",
  },
  {
    courseName: "Oral Pathology",
    courseUrl:
      config.apiUrl + "/course-details/oral-pathology",
  },
  {
    courseName: "Paediatric Nursing",
    courseUrl:
      config.apiUrl + "/course-details/paediatric-nursing",
  },
  {
    courseName: "Paedodontics and Preventive Dentistry",
    courseUrl:
      config.apiUrl + "/course-details/paedodontics-and-preventive-dentistry",
  },
  {
    courseName: "Oil and Gas Management",
    courseUrl:
      config.apiUrl + "/course-details/oil-and-gas-management",
  },
  {
    courseName: "Mathematics",
    courseUrl:
      config.apiUrl + "/course-details/mathematics",
  },
  {
    courseName: "Orthopaedics",
    courseUrl:
      config.apiUrl + "/course-details/orthopaedics",
  },
  {
    courseName: "Paediatrics and Child Health",
    courseUrl:
      config.apiUrl + "/course-details/paediatrics-and-child-health",
  },
  {
    courseName: "Orthodontics",
    courseUrl:
      config.apiUrl + "/course-details/orthodontics",
  },
  {
    courseName: "Nursing",
    courseUrl: config.apiUrl + "/course-details/nursing",
  },
  {
    courseName: "Performance Arts",
    courseUrl:
      config.apiUrl + "/course-details/performance-arts",
  },
  {
    courseName: "Periodontics",
    courseUrl:
      config.apiUrl + "/course-details/periodontics",
  },
  {
    courseName: "Physical Medicine and Rehabilitation",
    courseUrl:
      config.apiUrl + "/course-details/physical-medicine-and-rehabilitation",
  },
  {
    courseName: "Pharmaceutical Quality Assurance",
    courseUrl:
      config.apiUrl + "/course-details/pharmaceutical-quality-assurance",
  },
  {
    courseName: "Portuguese",
    courseUrl: config.apiUrl + "/course-details/portuguese",
  },
  {
    courseName: "Pharmacognosy",
    courseUrl:
      config.apiUrl + "/course-details/pharmacognosy",
  },
  {
    courseName: "Polymer Science",
    courseUrl:
      config.apiUrl + "/course-details/polymer-science",
  },
  {
    courseName: "Photography",
    courseUrl:
      config.apiUrl + "/course-details/photography",
  },
  {
    courseName: "Production and Operation Management",
    courseUrl:
      config.apiUrl + "/course-details/production-and-operation-management",
  },
  {
    courseName: "Podiatry",
    courseUrl: config.apiUrl + "/course-details/podiatry",
  },
  {
    courseName: "Physical Education",
    courseUrl:
      config.apiUrl + "/course-details/physical-education",
  },
  {
    courseName: "Physician Assistant",
    courseUrl:
      config.apiUrl + "/course-details/physician-assistant",
  },
  {
    courseName: "Pharmacogenetics and drug development",
    courseUrl:
      config.apiUrl + "/course-details/pharmacogenetics-and-drug-development",
  },
  {
    courseName: "Preventative Medicine",
    courseUrl:
      config.apiUrl + "/course-details/preventative-medicine",
  },
  {
    courseName: "Project Management",
    courseUrl:
      config.apiUrl + "/course-details/project-management",
  },
  {
    courseName: "Pharmacology and Toxicology",
    courseUrl:
      config.apiUrl + "/course-details/pharmacology-and-toxicology",
  },
  {
    courseName: "Plastic Surgery",
    courseUrl:
      config.apiUrl + "/course-details/plastic-surgery",
  },
  {
    courseName: "Regenerative Medicine",
    courseUrl:
      config.apiUrl + "/course-details/regenerative-medicine",
  },
  {
    courseName: "Psychiatric and Mental Health Nursing",
    courseUrl:
      config.apiUrl + "/course-details/psychiatric-and-mental-health-nursing",
  },
  {
    courseName: "Public Health Dentistry",
    courseUrl:
      config.apiUrl + "/course-details/public-health-dentistry",
  },
  {
    courseName: "Punjabi",
    courseUrl: config.apiUrl + "/course-details/punjabi",
  },
  {
    courseName: "Psychiatry",
    courseUrl: config.apiUrl + "/course-details/psychiatry",
  },
  {
    courseName: "Public Administation",
    courseUrl:
      config.apiUrl + "/course-details/public-administation",
  },
  {
    courseName: "Seismology",
    courseUrl: config.apiUrl + "/course-details/seismology",
  },
  {
    courseName: "Sanskrit",
    courseUrl: config.apiUrl + "/course-details/sanskrit",
  },
  {
    courseName: "Renewable Energy",
    courseUrl:
      config.apiUrl + "/course-details/renewable-energy",
  },
  {
    courseName: "Transplantation Medicine and Surgery",
    courseUrl:
      config.apiUrl + "/course-details/transplantation-medicine-and-surgery",
  },
  {
    courseName: "Space Medicine",
    courseUrl:
      config.apiUrl + "/course-details/space-medicine",
  },
  {
    courseName: "Russian",
    courseUrl: config.apiUrl + "/course-details/russian",
  },
  {
    courseName: "Transfusion Medicine",
    courseUrl:
      config.apiUrl + "/course-details/transfusion-medicine",
  },
  {
    courseName: "Social Sciences",
    courseUrl:
      config.apiUrl + "/course-details/social-sciences",
  },
  {
    courseName: "Spanish",
    courseUrl: config.apiUrl + "/course-details/spanish",
  },
  {
    courseName: "Strategic Design and Brand Leadership",
    courseUrl:
      config.apiUrl + "/course-details/strategic-design-and-brand-leadership",
  },
  {
    courseName: "Sound Recording and Sound Design",
    courseUrl:
      config.apiUrl + "/course-details/sound-recording-and-sound-design",
  },
  {
    courseName: "TB and Chest Diseases",
    courseUrl:
      config.apiUrl + "/course-details/tb-and-chest-diseases",
  },
  {
    courseName: "Sports Medicine",
    courseUrl:
      config.apiUrl + "/course-details/sports-medicine",
  },
  {
    courseName: "Visual Media",
    courseUrl:
      config.apiUrl + "/course-details/visual-media",
  },
  {
    courseName: "Urdu",
    courseUrl: config.apiUrl + "/course-details/urdu",
  },
  {
    courseName: "Vascular Surgery",
    courseUrl:
      config.apiUrl + "/course-details/vascular-surgery",
  },
  {
    courseName: "Urban Management",
    courseUrl:
      config.apiUrl + "/course-details/urban-management",
  },
  {
    courseName: "Sociology",
    courseUrl: config.apiUrl + "/course-details/sociology",
  },
  {
    courseName: "Visual Communication",
    courseUrl:
      config.apiUrl + "/course-details/visual-communication",
  },
  {
    courseName: "Sports Management",
    courseUrl:
      config.apiUrl + "/course-details/sports-management",
  },
  {
    courseName: "Theology",
    courseUrl: config.apiUrl + "/course-details/theology",
  },
  {
    courseName: "Social Work",
    courseUrl:
      config.apiUrl + "/course-details/social-work",
  },
  {
    courseName: "Travel and Tourism",
    courseUrl:
      config.apiUrl + "/course-details/travel-and-tourism",
  },
  {
    courseName: "Tropical Medicine",
    courseUrl:
      config.apiUrl + "/course-details/tropical-medicine",
  },
  {
    courseName: "Yoga",
    courseUrl: config.apiUrl + "/course-details/yoga",
  },
  {
    courseName: "Business Ethics and Corporate Social Responsibility",
    courseUrl:
      config.apiUrl + "/course-details/business-ethics-and-corporate-social-responsibility",
  },
  {
    courseName: "Taxation Law",
    courseUrl:
      config.apiUrl + "/course-details/taxation-law",
  },
  {
    courseName: "Agriculture and Food Business",
    courseUrl:
      config.apiUrl + "/course-details/agriculture-and-food-business",
  },
  {
    courseName: "Wilderness Medicine",
    courseUrl:
      config.apiUrl + "/course-details/wilderness-medicine",
  },
  {
    courseName: "Ultrasonography",
    courseUrl:
      config.apiUrl + "/course-details/ultrasonography",
  },
  {
    courseName: "Biochemical Engineering",
    courseUrl:
      config.apiUrl + "/course-details/biochemical-engineering",
  },
  {
    courseName: "Demography",
    courseUrl: config.apiUrl + "/course-details/demography",
  },
  {
    courseName: "Actuarial Science",
    courseUrl:
      config.apiUrl + "/course-details/actuarial-science",
  },
  {
    courseName: "Biotechnology Engineering",
    courseUrl:
      config.apiUrl + "/course-details/biotechnology-engineering",
  },
  {
    courseName: "Urban Planning and Design",
    courseUrl:
      config.apiUrl + "/course-details/urban-planning-and-design",
  },
  {
    courseName: "Pharmaceutical Regulatory Affairs",
    courseUrl:
      config.apiUrl + "/course-details/pharmaceutical-regulatory-affairs",
  },
  {
    courseName: "Oil and Paint Technology",
    courseUrl:
      config.apiUrl + "/course-details/oil-and-paint-technology",
  },
  {
    courseName: "Manufacturing Engineering",
    courseUrl:
      config.apiUrl + "/course-details/manufacturing-engineering",
  },
  {
    courseName: "Pulp and Paper Technology",
    courseUrl:
      config.apiUrl + "/course-details/pulp-and-paper-technology",
  },
  {
    courseName: "Printing Technology",
    courseUrl:
      config.apiUrl + "/course-details/printing-technology",
  },
  {
    courseName: "Philosophy",
    courseUrl: config.apiUrl + "/course-details/philosophy",
  },
  {
    courseName: "Anthropology",
    courseUrl:
      config.apiUrl + "/course-details/anthropology",
  },
  {
    courseName: "Sciences",
    courseUrl: config.apiUrl + "/course-details/sciences",
  },
  {
    courseName: "Botany",
    courseUrl: config.apiUrl + "/course-details/botany",
  },
  {
    courseName: "Physics",
    courseUrl: config.apiUrl + "/course-details/physics",
  },
  {
    courseName: "Advertising",
    courseUrl:
      config.apiUrl + "/course-details/advertising",
  },
  {
    courseName: "History",
    courseUrl: config.apiUrl + "/course-details/history",
  },
  {
    courseName: "Auxiliary Nurse and Midwifery",
    courseUrl:
      config.apiUrl + "/course-details/auxiliary-nurse-and-midwifery",
  },
  {
    courseName: "Auditing",
    courseUrl: config.apiUrl + "/course-details/auditing",
  },
  {
    courseName: "Bengali",
    courseUrl: config.apiUrl + "/course-details/bengali",
  },
  {
    courseName: "Cardio Thoracic Nursing",
    courseUrl:
      config.apiUrl + "/course-details/cardio-thoracic-nursing",
  },
  {
    courseName: "Geography",
    courseUrl: config.apiUrl + "/course-details/geography",
  },
  {
    courseName: "Biomedical Sciences",
    courseUrl:
      config.apiUrl + "/course-details/biomedical-sciences",
  },
  {
    courseName: "Big Data Analytics",
    courseUrl:
      config.apiUrl + "/course-details/big-data-analytics",
  },
  {
    courseName: "Criminology and Forensic law",
    courseUrl:
      config.apiUrl + "/course-details/criminology-and-forensic-law",
  },
  {
    courseName: "Clinical Pharmacy",
    courseUrl:
      config.apiUrl + "/course-details/clinical-pharmacy",
  },
  {
    courseName: "Bio-physics",
    courseUrl:
      config.apiUrl + "/course-details/bio-physics",
  },
  {
    courseName: "Biology",
    courseUrl: config.apiUrl + "/course-details/biology",
  },
  {
    courseName: "Constitutional and Administrative Law",
    courseUrl:
      config.apiUrl + "/course-details/constitutional-and-administrative-law",
  },
  {
    courseName: "Bio-Chemistry",
    courseUrl:
      config.apiUrl + "/course-details/bio-chemistry",
  },
  {
    courseName: "Bio-informatics",
    courseUrl:
      config.apiUrl + "/course-details/bio-informatics",
  },
  {
    courseName: "Dental Anatomy",
    courseUrl:
      config.apiUrl + "/course-details/dental-anatomy",
  },
  {
    courseName: "Forensic Dentistry",
    courseUrl:
      config.apiUrl + "/course-details/forensic-dentistry",
  },
  {
    courseName: "Fashion and Textiles",
    courseUrl:
      config.apiUrl + "/course-details/fashion-and-textiles",
  },
  {
    courseName: "Forensic Medicine",
    courseUrl:
      config.apiUrl + "/course-details/forensic-medicine",
  },
  {
    courseName: "Film Technology",
    courseUrl:
      config.apiUrl + "/course-details/film-technology",
  },
  {
    courseName: "French",
    courseUrl: config.apiUrl + "/course-details/french",
  },
  {
    courseName: "Foundry and Forge Technology",
    courseUrl:
      config.apiUrl + "/course-details/foundry-and-forge-technology",
  },
  {
    courseName: "Gastroenterology and Hepatology",
    courseUrl:
      config.apiUrl + "/course-details/gastroenterology-and-hepatology",
  },
  {
    courseName: "Fashion Retail Management",
    courseUrl:
      config.apiUrl + "/course-details/fashion-retail-management",
  },
  {
    courseName: "Foreign Language",
    courseUrl:
      config.apiUrl + "/course-details/foreign-language",
  },
  {
    courseName: "Fisheries Science",
    courseUrl:
      config.apiUrl + "/course-details/fisheries-science",
  },
  {
    courseName: "Oral Hygiene",
    courseUrl:
      config.apiUrl + "/course-details/oral-hygiene",
  },
  {
    courseName: "Oral Medicine",
    courseUrl:
      config.apiUrl + "/course-details/oral-medicine",
  },
  {
    courseName: "Persian",
    courseUrl: config.apiUrl + "/course-details/persian",
  },
  {
    courseName: "Pharmaceutical Chemistry",
    courseUrl:
      config.apiUrl + "/course-details/pharmaceutical-chemistry",
  },
  {
    courseName: "Prosthodontics and Crown and Bridge",
    courseUrl:
      config.apiUrl + "/course-details/prosthodontics-and-crown-and-bridge",
  },
  {
    courseName: "Sleep Medicine",
    courseUrl:
      config.apiUrl + "/course-details/sleep-medicine",
  },
  {
    courseName: "Pulmonology and Respiratory Medicine",
    courseUrl:
      config.apiUrl + "/course-details/pulmonology-and-respiratory-medicine",
  },
  {
    courseName: "Port and Shipping Management",
    courseUrl:
      config.apiUrl + "/course-details/port-and-shipping-management",
  },
  {
    courseName: "Statistics",
    courseUrl: config.apiUrl + "/course-details/statistics",
  },
  {
    courseName: "Sustainable Development",
    courseUrl:
      config.apiUrl + "/course-details/sustainable-development",
  },
  {
    courseName: "Textile Design",
    courseUrl:
      config.apiUrl + "/course-details/textile-design",
  },
  {
    courseName: "Blockchain",
    courseUrl: config.apiUrl + "/course-details/blockchain",
  },
  {
    courseName: "Accessory Designing",
    courseUrl:
      config.apiUrl + "/course-details/accessory-designing",
  },
  {
    courseName: "Forensic Science",
    courseUrl:
      config.apiUrl + "/course-details/forensic-science",
  },
  {
    courseName: "Arts",
    courseUrl: config.apiUrl + "/course-details/arts",
  },
  {
    courseName: "Materials Science and Technology",
    courseUrl:
      config.apiUrl + "/course-details/materials-science-and-technology",
  },
  {
    courseName: "Polymer Technology",
    courseUrl:
      config.apiUrl + "/course-details/polymer-technology",
  },
  {
    courseName: "Principles of Management",
    courseUrl:
      config.apiUrl + "/course-details/principles-of-management",
  },
  {
    courseName: "Business Statistics",
    courseUrl:
      config.apiUrl + "/course-details/business-statistics",
  },
  {
    courseName: "Human Capital Management",
    courseUrl:
      config.apiUrl + "/course-details/human-capital-management",
  },
  {
    courseName: "Automobile Engineering",
    courseUrl:
      config.apiUrl + "/course-details/Automobile-Engineering",
  },
  {
    courseName: "Electronics and Communication Engineering",
    courseUrl:
      config.apiUrl + "/course-details/Electronics-and-Communication-Engineering",
  },
  {
    courseName: "Anatomy",
    courseUrl: config.apiUrl + "/course-details/Anatomy",
  },
  {
    courseName: "Banking",
    courseUrl: config.apiUrl + "/course-details/Banking",
  },
  {
    courseName: "Homeopathy",
    courseUrl: config.apiUrl + "/course-details/Homeopathy",
  },
  {
    courseName: "Veterinary Science",
    courseUrl:
      config.apiUrl + "/course-details/Veterinary-Science",
  },
  {
    courseName: "Conservative Dentistry and Endodontics",
    courseUrl:
      config.apiUrl + "/course-details/Conservative-Dentistry-and-Endodontics",
  },
  {
    courseName: "Company Law",
    courseUrl:
      config.apiUrl + "/course-details/Company-Law",
  },
  {
    courseName: "Control System Engineering",
    courseUrl:
      config.apiUrl + "/course-details/Control-System-Engineering",
  },
  {
    courseName: "Theatre",
    courseUrl: config.apiUrl + "/course-details/Theatre",
  },
  {
    courseName: "Sculpture",
    courseUrl: config.apiUrl + "/course-details/Sculpture",
  },
  {
    courseName: "Alternative Medicine",
    courseUrl:
      config.apiUrl + "/course-details/Alternative-Medicine",
  },
  {
    courseName: "Immigration Law",
    courseUrl:
      config.apiUrl + "/course-details/Immigration-Law",
  },
  {
    courseName: "Apparel Design",
    courseUrl:
      config.apiUrl + "/course-details/Apparel-Design",
  },
  {
    courseName: "Insurance Law",
    courseUrl:
      config.apiUrl + "/course-details/Insurance-Law",
  },
  {
    courseName: "Embedded Systems",
    courseUrl:
      config.apiUrl + "/course-details/Embedded-Systems",
  },
  {
    courseName: "Applied Mechanics",
    courseUrl:
      config.apiUrl + "/course-details/Applied-Mechanics",
  },
  {
    courseName: "Cyber Defense",
    courseUrl:
      config.apiUrl + "/course-details/Cyber-Defense",
  },
  {
    courseName: "Data Management",
    courseUrl:
      config.apiUrl + "/course-details/Data-Management",
  },
  {
    courseName: "Upstream",
    courseUrl: config.apiUrl + "/course-details/Upstream",
  },
  {
    courseName: "Automotive Electronics",
    courseUrl:
      config.apiUrl + "/course-details/Automotive-Electronics",
  },
  {
    courseName: "Genome Engineering",
    courseUrl:
      config.apiUrl + "/course-details/Genome-Engineering",
  },
  {
    courseName: "Fluid Mechanics",
    courseUrl:
      config.apiUrl + "/course-details/Fluid-Mechanics",
  },
  {
    courseName: "Solid Mechanics",
    courseUrl:
      config.apiUrl + "/course-details/Solid-Mechanics",
  },
  {
    courseName: "Infrastructure Development",
    courseUrl:
      config.apiUrl + "/course-details/Infrastructure-Development",
  },
  {
    courseName: "Smart Infrastructure",
    courseUrl:
      config.apiUrl + "/course-details/Smart-Infrastructure",
  },
  {
    courseName: "Construction Technology",
    courseUrl:
      config.apiUrl + "/course-details/Construction-Technology",
  },
  {
    courseName: "Decorative Arts",
    courseUrl:
      config.apiUrl + "/course-details/Decorative-Arts",
  },
  {
    courseName: "Energy Law",
    courseUrl: config.apiUrl + "/course-details/Energy-Law",
  },
  {
    courseName: "Information Design",
    courseUrl:
      config.apiUrl + "/course-details/Information-Design",
  },
  {
    courseName: "Information Security",
    courseUrl:
      config.apiUrl + "/course-details/Information-Security",
  },
  {
    courseName: "Cloud Technology",
    courseUrl:
      config.apiUrl + "/course-details/Cloud-Technology",
  },
  {
    courseName: "Game Technology",
    courseUrl:
      config.apiUrl + "/course-details/Game-Technology",
  },
  {
    courseName: "Computational Sustainability",
    courseUrl:
      config.apiUrl + "/course-details/Computational-Sustainability",
  },
  {
    courseName: "Intellectual Property Rights",
    courseUrl:
      config.apiUrl + "/course-details/Intellectual-Property-Rights",
  },
  {
    courseName: "Enterprise Mobility",
    courseUrl:
      config.apiUrl + "/course-details/Enterprise-Mobility",
  },
  {
    courseName: "Intelligent Systems",
    courseUrl:
      config.apiUrl + "/course-details/Intelligent-Systems",
  },
  {
    courseName: "Augmented and Virtual Reality",
    courseUrl:
      config.apiUrl + "/course-details/Augmented-and-Virtual-Reality",
  },
  {
    courseName: "Android Application Development",
    courseUrl:
      config.apiUrl + "/course-details/Android-Application-Development",
  },
  {
    courseName: "Heritage Management",
    courseUrl:
      config.apiUrl + "/course-details/Heritage-Management",
  },
  {
    courseName: "Fashion Business Management",
    courseUrl:
      config.apiUrl + "/course-details/Fashion-Business-Management",
  },
  {
    courseName: "Fashion Communication",
    courseUrl:
      config.apiUrl + "/course-details/Fashion-Communication",
  },
  {
    courseName: "Interior Architecture and Design",
    courseUrl:
      config.apiUrl + "/course-details/Interior-Architecture-and-Design",
  },
  {
    courseName: "Android Application Development",
    courseUrl:
      config.apiUrl + "/course-details/Android-Application-Development",
  },
  {
    courseName: "Heritage Management",
    courseUrl:
      config.apiUrl + "/course-details/Heritage-Management",
  },
  {
    courseName: "Fashion Business Management",
    courseUrl:
      config.apiUrl + "/course-details/Fashion-Business-Management",
  },
  {
    courseName: "Fashion Communication",
    courseUrl:
      config.apiUrl + "/course-details/Fashion-Communication",
  },
  {
    courseName: "Interior Architecture and Design",
    courseUrl:
      config.apiUrl + "/course-details/Interior-Architecture-and-Design",
  },
];
