import { useEffect, useState } from "react";
import TabNavigation from "../../exam-details-new/components/TabNavigation";
import Banner from "./components/Banner";
import MainDetails from "./components/MainDetails";
import KnowledgeHub from "./components/KnowledgeHub";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { fetchDetailData } from "../educationLoanThunk";
import Banks from "./components/Banks";
import LoanCourses from "./components/LoanCourses";

const tabsWithIds = [
  { name: "About Loan", sectionId: "about" },
  { name: "Loan Eligibility", sectionId: "eligibility" },
  { name: "Benefits Documents", sectionId: "benefits-documents" },
  { name: "How to Apply", sectionId: "how-to-apply" },
  { name: "Banks & NBFC Education Loans", sectionId: "banks" },
  { name: "Contact", sectionId: "contact" },
  { name: "FAQ", sectionId: "faq" },
];

const tabs = tabsWithIds.map((item) => item.name);

const EducationLoanDetails = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [activeTab, setActiveTab] = useState(0);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    dispatch(fetchDetailData());
  }, []);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }

    const element = document.getElementById(tabsWithIds[activeTab].sectionId);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offset = 50;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      });
    }
  }, [activeTab]);

  return (
    <div className="font-roboto text-black">
      <Banner />
      <TabNavigation
        tabs={tabs}
        activeTabIndex={activeTab}
        changeActiveTabIndex={(index: number) => setActiveTab(index)}
      />
      <section className="container flex max-lg:flex-col gap-8 py-12">
        <div className="lg:w-2/3">
          <MainDetails />
          <div id="banks">
            <Banks />
          </div>
          <div id="contact" className="p-8 text-base">
            <h1 className="text-4xl font-medium text-blue">Contact Us</h1>
            <p className="mt-2">In case of any queries, please reach out to:</p>
            <p className="mt-2">
              <strong>799716666</strong> (Monday to Friday - 10:00AM to 06:00 PM
              (IST))
            </p>
            <p className="mt-2">
              <strong>loan@collegementor.com</strong>
            </p>
          </div>
          <div id="faq">
            <KnowledgeHub />
          </div>
          <div className="p-8 text-base">
            <p className="mt-2">
              <strong>Disclaimer</strong> Report inaccuracy in this page
              Admin@collegementor.com All Content, Logo and Company names are
              registered trademarks of their respective holders. Use of them
              does not imply any affiliation with or endorsement by them.
            </p>
            <p className="mt-2">
              <strong>Non-affiliation</strong> The College Mentor is not
              affiliated with any government entity in India or elsewhere. For
              government scholarships, we rely on publicly available information
              from central and state government websites in India.
            </p>
          </div>
        </div>
        <div className="lg:w-1/3">
          <LoanCourses />
        </div>
      </section>
    </div>
  );
};

export default EducationLoanDetails;
