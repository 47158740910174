import React, { FC, useEffect, useState } from "react";

import BlogsBanner from "../components/BlogsAdBanner";
import BlogInfoCard from "../components/BlogInfoCard";
import BlogHoverContainer from "../components/BlogHoverContainer";

import TitleCard from "../components/TitleCard";
import ExamsAndAdmissions from "./sections/ExamsAndAdmissions";
import StudyAbroad from "./sections/StudyAbroad";
import CollegeAndScholarship from "./sections/CollegeAndScholarship";
import PremiumBlogs from "./sections/PremiumBlogs";
import CareersAndCourses from "./sections/CareersAndCourses";
import WebAndVisualStories from "./sections/WebAndVisualStories";
import PopularMentors from "./sections/PopularMentors";
import BlogsHeader from "../components/BlogsHeader";
import { AllSectionDetails, HeaderAPIResponse } from "../data/UtilsData";
import ArticlesAndLatestNews from "./sections/ArticlesAndLatestNews";
import axios from "axios";
import BlogsSubscriptionFooter from "../components/BlogsSubscriptionFooter";
import BannerImage1 from "../../../images/blog-article/blogsAdBanner.png";
import Loader from "../../../components/Loader/Loader";
import BlogsAdBanner from "../components/BlogsAdBanner";
import Banner from "./sections/Banner";
import config from "../../../util/config";
import TrendingTopics from "../components/TrendingTopics";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import AmritaVertical from "../../../images/blog-article/Amrita-Vertical.png";
import AmritaHorizontal from "../../../images/blog-article/Amrita-Horizontal.png";

const BlogsAndArticlesMain = () => {
  // const [allSectionData, setAllSectionData] = useState<any>({});
  const [sectionWiseData, setSectionWiseData] = useState<any>([]);
  const [headerResponse, setHeaderResponse] = useState<any>([]);
  const bannerImage2 = "/images/blogsBanner.png";
  // const [loading, setLoading] = useState<boolean>(true);

  const { loading, landingData: allSectionData } = useSelector(
    (state: RootState) => state.blogSlice
  );

  // const fetchAllSectionsResponse = async () => {
  //   await axios
  //     .get(config.apiUrl + "/api/blogs/blogsandnews")
  //     .then((response) => {
  //       setAllSectionData(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });

  //   // setAllSectionData(AllSectionDetails);
  //   setLoading(false);
  // };
  // useEffect(() => {
  //   fetchAllSectionsResponse();
  // }, []);

  if (loading) {
    return <Loader />;
  } else if (!allSectionData) return;

  // if (!headerResponse || !allSectionData) return null;

  return (
    <>
      {/* <TrendingTopics trendingTopics={headerResponse?.trendingTopics} /> */}
      {/* <BlogsHeader headerResponse={headerResponse?.categories} /> */}
      <Banner data={allSectionData?.sections} />

      <div className="justify-center hidden w-full py-6 bg-gray-300 md:flex lg:flex">
        <BlogsAdBanner
          width=""
          height=""
          image={AmritaHorizontal}
          link="https://www.amrita.edu/"
        />
      </div>
      <div className="md:py-4">
        <div className="grid grid-flow-row gap-0 grid-rows">
          <ArticlesAndLatestNews
            data={allSectionData?.sections}
            title="Articles & Blogs"
            secondaryTitle="Latest News"
          />

          <ExamsAndAdmissions
            examsAndAdmissionsResponse={allSectionData?.sections}
          />
          <div className="hidden py-4 md:flex lg:flex ">
            <div className="flex justify-center w-full py-4 bg-gray-300">
              <BlogsAdBanner
                width=""
                height=""
                image={bannerImage2}
                link="https://sageuniversity.in/"
              />
            </div>
          </div>

          <CollegeAndScholarship
            collegeAndScholarshipResponse={allSectionData?.sections}
          />
          <PremiumBlogs premiumBlogs={allSectionData?.sections} />
          <CareersAndCourses
            careersAndCoursesResponse={allSectionData?.sections}
          />
          <StudyAbroad studyAbroadResponse={allSectionData?.sections} />
          <WebAndVisualStories
            webAndVisualStoriesResponse={allSectionData?.sections}
          />
          <PopularMentors
            popularMentorResponse={
              allSectionData?.sections[0]["Popular Authors"]
            }
          />
          {/* <BlogsSubscriptionFooter /> */}
        </div>
      </div>
    </>
  );
};

export default BlogsAndArticlesMain;
