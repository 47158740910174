// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-slide {
  height: auto;
}

.ExploreMore-occupation .nextButton svg path,
.ExploreMore-occupation .prevButton svg path {
  color: black;
}

.ExploreMore-occupation .progressbar span {
  color: white;
}

.ExploreMore-occupation .progressbarBg {
  background: #ffffff;
}

.ExploreMore-occupation .progressbarBg .bg-blue {
  background: #00cc99;
}`, "",{"version":3,"sources":["webpack://./src/components/Slider/CustomSwiper.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;;EAEE,YAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AADA;EACE,mBAAA;AAIF;;AAFA;EACE,mBAAA;AAKF","sourcesContent":[".swiper-slide {\n  height: auto;\n}\n.ExploreMore-occupation .nextButton svg path,\n.ExploreMore-occupation .prevButton svg path {\n  color: black;\n}\n.ExploreMore-occupation .progressbar span {\n  color: white;\n}\n.ExploreMore-occupation .progressbarBg {\n  background: #ffffff;\n}\n.ExploreMore-occupation .progressbarBg .bg-blue {\n  background: #00cc99;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
