import React, { useState } from "react";

const ExploreYourDreamCollege: React.FC = () => {
  type FaqTabs = "collegeAndCampusTour" | "collegeApplicationToAdmission";

  const [selectedTab, setSelectedTab] = useState<FaqTabs>(
    "collegeApplicationToAdmission"
  );
  const [activeQuestions, setActiveQuestions] = useState<string[]>([]);

  const faqs: Record<FaqTabs, { question: string; answer: string }[]> = {
    collegeAndCampusTour: [
      {
        question: "What is the meaning of a college tour?",
        answer:
          "<p>A college tour is an organized visit to a college or university that allows prospective students to explore the campus, learn about academic programs, and get a feel for student life. During a college tour, visitors often meet with admissions counselors, current students, and faculty members to ask questions and gather information about the institution.</p>",
      },
      {
        question:
          "What is an early bird application, admission & scholarship offer?",
        answer:
          "<p>An early bird application refers to the opportunity for students to apply to colleges or universities before the regular admission deadlines. This often comes with benefits such as priority consideration for scholarships, reduced application fees, and the possibility of early admission decisions, providing students with peace of mind.</p>",
      },
      {
        question: "What is the meaning of early bird student admission?",
        answer:
          "<p>Early bird student admission refers to the process where students apply and are accepted to a college or university ahead of the standard application timeline. This can enhance their chances of securing a spot and allows them to plan their next steps earlier.</p>",
      },
      {
        question:
          "What are the benefits of early bird application to admission registration?",
        answer:
          "<p>Benefits of early bird applications include a higher likelihood of acceptance, access to more scholarships, and priority registration for classes. Additionally, early applicants often experience less competition, allowing them to stand out in the admissions process.</p>",
      },
      {
        question: "What is admission and application?",
        answer:
          "<p>Admission refers to the process by which a college or university grants a student the opportunity to enroll in its programs. The application is the formal process through which a student submits their information, including academic records, personal statements, and other required materials, to be considered for admission.</p>",
      },
      {
        question: "Is the early bird admission payment refundable?",
        answer:
          "<p>Whether the early bird admission payment is refundable depends on the institution's policy. Many colleges have specific guidelines regarding application fees, so it’s important for applicants to review the terms and conditions or contact the admissions office for clarification.</p>",
      },
      {
        question: "What are exceptional circumstances in admissions?",
        answer:
          "<p>Exceptional circumstances refer to unforeseen situations that may impact a student's ability to meet application deadlines or admission requirements. This can include personal hardships, health issues, or other significant life events. Many colleges offer options for students to explain their circumstances and potentially receive accommodations in the admissions process.</p>",
      },
      {
        question:
          "For institutions that want to offer application to direct admissions, what should they consider?",
        answer:
          "<p>Institutions aiming to provide direct admissions should consider factors like their selection criteria, the transparency of the application process, and the resources available to assist applicants. It's essential to communicate clearly about the benefits of direct admission and ensure that the process is streamlined for potential students.</p>",
      },
    ],
    collegeApplicationToAdmission: [
      {
        question: "Which university is right for you?",
        answer:
          "<p>The right university for you depends on your academic interests, career goals, campus culture preferences, and financial considerations. It's essential to research various institutions, visit campuses if possible, and consider factors such as program reputation, location, and available support services.</p>",
      },
      {
        question:
          "Can I speak to a college admissions coach before enrolling in College Mentor Admissions services?",
        answer:
          "<p>Yes, most college admissions coaching services allow prospective clients to have an initial consultation. This meeting is an opportunity to discuss your goals, ask questions about the services offered, and determine if the coach is a good fit for your needs.</p>",
      },
      {
        question: "What is discussed in the initial consultation?",
        answer:
          "<p>In an initial consultation, topics often include your academic background, career aspirations, application strategies, and any specific concerns you have about the admissions process. This session aims to assess your needs and how the coaching service can best support you.</p>",
      },
      {
        question: "What do one-on-one advising sessions entail?",
        answer:
          "<p>One-on-one advising sessions typically involve personalized guidance on various aspects of the college application process. This can include crafting personal statements, preparing for interviews, and discussing application strategies tailored to your goals.</p>",
      },
      {
        question: "What is college counseling?",
        answer:
          "<p>College counseling is a service provided to help students navigate the college admissions process. Counselors assist with identifying suitable colleges, understanding application requirements, and developing strategies to enhance the chances of acceptance, including academic and extracurricular guidance.</p>",
      },
    ],
  };

  const toggleFAQ = (question: string) => {
    setActiveQuestions((prev) =>
      prev.includes(question)
        ? prev.filter((q) => q !== question)
        : [...prev, question]
    );
  };

  const handleTabClick = (tab: FaqTabs) => {
    setSelectedTab(tab);
    setActiveQuestions([]);
  };

  return (
    <div className="flex flex-col items-start px-20 py-14 w-full bg-[#FFF6F1] max-md:px-5 max-md:max-w-full mb-6">
      <div className="md:w-[1010px] ">
        <h3 className="text-5xl font-bold text-[#173CBA]">
          <span>Explore Your Dream College </span>
          <br />
          <span className="text-[#0C9]">Guided Campus Tours</span>
          <span> for Students & Parents (FAQ’s)</span>
        </h3>
      </div>

      <div className="flex flex-wrap gap-6 mt-6 max-w-full text-xl font-semibold leading-10 text-[#173CBA] w-[600px]">
        {Object.keys(faqs).map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab as FaqTabs)}
            className={`flex-auto gap-7 self-stretch px-2.5 rounded-3xl border border-[#0C9] border-solid transition-colors duration-300 ease-in-out 
              ${
                selectedTab === tab
                  ? "bg-[#0C9] text-white"
                  : "bg-white text-[#173CBA]"
              }
              hover:bg-[#0C9] hover:text-white`}
          >
            {tab === "collegeAndCampusTour"
              ? "College & Campus Tour"
              : "College Application to Admission"}
          </button>
        ))}
      </div>

      <div className="mt-8 w-full max-md:max-w-full">
        <div
          className="flex justify-between max-md:flex-col"
          style={{ columnGap: "230px" }}
        >
          <div className="flex flex-col gap-6 w-full">
            {faqs[selectedTab]
              .filter((_, index) => index % 2 === 0)
              .map((item) => (
                <FAQItem
                  key={item.question}
                  question={item.question}
                  answer={item.answer}
                  isActive={activeQuestions.includes(item.question)}
                  onClick={() => toggleFAQ(item.question)}
                />
              ))}
          </div>

          <div className="flex flex-col gap-6 w-full">
            {faqs[selectedTab]
              .filter((_, index) => index % 2 !== 0)
              .map((item) => (
                <FAQItem
                  key={item.question}
                  question={item.question}
                  answer={item.answer}
                  isActive={activeQuestions.includes(item.question)}
                  onClick={() => toggleFAQ(item.question)}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

interface FAQItemProps {
  question: string;
  answer: string;
  isActive: boolean;
  onClick: () => void;
}

const FAQItem: React.FC<FAQItemProps> = ({
  question,
  answer,
  isActive,
  onClick,
}) => {
  return (
    <div
      className="flex flex-col gap-3 p-3 mt-6 w-full bg-white shadow-[0px_0px_15px_rgba(0,0,0,0.15)] cursor-pointer"
      onClick={onClick}
    >
      <div className="flex justify-between items-center gap-4">
        <h4 className="text-base font-normal text-[#7C7C7C]">{question}</h4>
        <div className="flex gap-2.5 items-center self-stretch py-2 pr-1.5 pl-2 my-auto bg-blue-800 rounded-2xl h-[30px] min-h-[30px] w-[30px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
          >
            <path
              d="M9.33252 1.66669L9.33252 18.3334"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M0.999878 9.99969H17.6665"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
      {isActive && (
        <div
          className="text-md text-[#7C7C7C]"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      )}
    </div>
  );
};

export default ExploreYourDreamCollege;
