import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import PlaceholderChip from "./common/PlaceholderChip/PlaceholderChip";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import { useForm } from "react-hook-form";
import {
  nextStep,
  updateForm,
  prevStep,
} from "../../../../store/slices/registrationFormSlice";
import PlaceholderChipGroup from "./common/PlaceholderChip/PlaceholderChipGroup";
import OptionGroup from "./common/OptionsCard/OptionGroup";

interface CurrentLevelStudyProps {
  currentLevelOfStudy: string;
}

export const CURRENT_LEVEL_OF_STUDY: string[] = [
  "8-11",
  "12",
  "UG",
  "PG",
  "Others",
];
const cards: string[] = ["Card 1", "Card 2", "Card 3"];
const CurrentLevelStudy = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, email, mobileNumber, currentLevelOfStudy } =
    useSelector((state: RootState) => state.registrationFormSlice.data);

  const { pafData } = useSelector((state: RootState) => state.auth);

  const [selectedCard, setSelectedCard] = useState<any>("");
  const [selectedSingle, setSelectedSingle] = useState<any>(null);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    dispatch(updateForm({ currentLevelOfStudy: selectedSingle }));
    dispatch(nextStep());
  };

  const handleClick = (message: string): void => {
    console.log("Message from Child: ", message);

    onSubmit(message);
  };

  const handleCardClick = (card: string) => {
    console.log("CurrentLevelStudy - Message from Child: ", card);
    setSelectedCard(card);
  };

  const handleSingleSelectionChange = (selected: any) => {
    setSelectedSingle(selected);
  };

  const handleNext = () => {
    dispatch(updateForm({ currentLevelOfStudy: selectedSingle }));

    dispatch(nextStep());
  };
  return (
    <div className="flex flex-col w-auto gap-2">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={false}
        showBack={true}
        stepName="What's your current level of Study ?"
        stepDescription=""
      >
        <div className="w-full p-4 overflow-auto md:h-21rem">
          {/* Old Implementation */}

          {/* <OptionGroup
            mode={"single"}
            options={CURRENT_LEVEL_OF_STUDY}
            handleCardSelect={handleCardClick}
            cardSize={"36"}
          /> */}

          {/* <OptionGroup
            options={pafData?.currentLevelOfStudy}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.title
            }
            handleCardSelect={handleCardClick}
            cardSize={"36"}
          /> */}

          <OptionGroup
            options={pafData?.currentLevelOfStudy}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.title
            }
            onSelectionChange={handleSingleSelectionChange}
            cardSize="52"
          />
          {/* <h3 className="mt-4">Selected Single Option (Full Object):</h3>
          <pre>{JSON.stringify(selectedSingle, null, 2)}</pre> */}
          <div className="flex justify-center mt-10">
            <button
              type="button"
              onClick={handleNext}
              className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
              style={{ padding: "8px 18px" }}
            >
              Continue
            </button>
          </div>
        </div>
      </StepNavigationWrapper>
    </div>
  );
};

export default CurrentLevelStudy;
