import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import TopRankedUniversitiesQSImg from "../../images/study-abroad/TopRankedUniversitiesQS-img.png";
import type { SVGProps } from "react";
import { LeadGenerationPopup } from "../../screens/colleges-new/landing/components/WebinarAndArticles";

export function IconParkOutlineRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="m19 12l12 12l-12 12"
      ></path>
    </svg>
  );
}

interface TopRankedUniversitiesQSProps {
  rankedByQs: {
    courseName: string;
    rank: string;
    collegeName: string;
    fee: string;
    courseDuration: string;
    scholarshipType: string;
    scholarshipPercentage: string;
    imageUrl?: string;
  }[];
}

const TopRankedUniversitiesQS: React.FC<TopRankedUniversitiesQSProps> = ({
  rankedByQs,
}) => {
  const [visibleRows, setVisibleRows] = useState(2);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const itemsPerRow = 4;
  const itemsToShow = visibleRows * itemsPerRow;

  const handleViewMore = () => {
    const maxVisibleItems = visibleRows * itemsPerRow;
    if (maxVisibleItems >= rankedByQs.length) {
      setVisibleRows(2);
    } else {
      setVisibleRows(visibleRows + 2);
    }
  };
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const renderCard = (course: any, index: number) => (
    <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="h-48 overflow-hidden bg-gray-200">
        <img
          src={
            course.imageUrl && course.imageUrl !== "#"
              ? course.imageUrl
              : TopRankedUniversitiesQSImg
          }
          alt={course.courseName}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-4 pb-5 flex flex-col gap-6">
        <div className="flex flex-col gap-3.5">
          <h3 className="text-base font-semibold text-black line-clamp-2">
            {course.courseName}
          </h3>
          <div className="flex flex-wrap text-sm text-black gap-1">
            <p className="flex gap-1">
              <span className="bg-green text-white px-1">QS</span>#{course.rank}
            </p>
            <span>.{course.collegeName}</span>
          </div>
        </div>
        <div className="flex flex-col gap-3.5">
          <div className="flex text-sm text-black gap-4">
            <div className="border border-[#F1F1F1] p-2.5 text-xs">
              <p className="font-semibold text-black">{course.fee}</p>
              <p className="text-xs text-[#747474]">Est. Annual Tuition</p>
            </div>
            <div className="border border-[#F1F1F1] p-2.5">
              <p className="font-semibold text-black">
                {course.courseDuration}
              </p>
              <p className="text-xs text-[#747474]">Course Duration</p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-blue-600 text-sm font-medium">
              {course.scholarshipType}
            </p>
            <p className="text-sm">{course.scholarshipPercentage}</p>
          </div>
        </div>
        <button
          onClick={handlePopup}
          className="w-full white-color button-styles map-mentor arrow-button   flex flex-row items-center justify-center gap-2"
        >
          Secure Scholarship
        </button>
      </div>
      <LeadGenerationPopup
        isOpen={isPopupVisible}
        onClose={() => setIsPopupVisible(!isPopupVisible)}
      />
    </div>
  );

  const handleSlideChange = (swiper: any) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  return (
    <section className="bg-[#F1F1F1] py-10">
      <div className="container mx-auto px-4">
        <h3 className="text-4xl lg:text-5xl font-normal md:font-light text-blue-900 mb-7 max-md:text-center">
          Top Ranked Universities by QS
        </h3>

        <div className="block lg:hidden gap-6">
          <Swiper
            spaceBetween={16}
            slidesPerView={1}
            navigation={false}
            modules={[Navigation]}
            loop={false}
            onSwiper={setSwiperInstance}
            onSlideChange={handleSlideChange}
            className="mobile-carousel"
          >
            {rankedByQs.map((course, index) => (
              <SwiperSlide key={index}>{renderCard(course, index)}</SwiperSlide>
            ))}
          </Swiper>
          <div className="flex justify-center gap-4 mt-6">
            <button
              onClick={() => swiperInstance?.slidePrev()}
              disabled={isBeginning}
              className={`w-9 h-9 flex justify-center items-center rotate-180 rounded-full bg-blue text-white transition-opacity ${
                isBeginning ? "opacity-50 cursor-not-allowed" : "opacity-100"
              }`}
            >
              <IconParkOutlineRight />
            </button>
            <button
              onClick={() => swiperInstance?.slideNext()}
              disabled={isEnd}
              className={`w-9 h-9 flex justify-center items-center rounded-full bg-blue text-white transition-opacity ${
                isEnd ? "opacity-50 cursor-not-allowed" : "opacity-100"
              }`}
            >
              <IconParkOutlineRight />
            </button>
          </div>
        </div>

        <div className="hidden lg:grid lg:grid-cols-4 gap-6">
          {rankedByQs
            .slice(0, itemsToShow)
            .map((course, index) => renderCard(course, index))}
        </div>

        {rankedByQs.length > itemsToShow && (
          <div className="hidden lg:flex justify-center mt-6">
            <button
              onClick={handleViewMore}
              className="hover:bg-blue text-white py-3.5 px-3 sm:px-9 rounded-full flex items-center gap-1.5 bg-green hover:rounded-none transition-all duration-300 ease-in-out text-base font-semibold"
            >
              {itemsToShow >= rankedByQs.length ? "Show Less" : "View More"}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default TopRankedUniversitiesQS;
