import axios from "axios";
import config from '../util/config';
const BaseURL = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export default BaseURL;
