import React from 'react';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="my-7 container mx-auto p-6  text-black rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">
        The terms of service and conditions mentioned here are reflected according to the services provided by our company, Dreams and Degrees. These Terms and Conditions serve as a contract between the user and the parent company of this website, Dreams and Degrees.
      </p>
      <p className="mb-4">
        The service provider of www.collegementor.com webpage/mobile application or any form of web or mobile application is Dreams and Degrees Private Limited based in Hyderabad, Telangana, India. As we are based in India, we operate according to Indian laws and local State government laws.
      </p>
      <p className="mb-4">
        Mere visiting of this website makes you enter into a contract with the company, and no separate signatures in any form are required to enforce these terms and conditions. In the following document, if there are terms such as COMPANY, We, US, or OUR, it means Dreams and Degrees Private Limited. Broadly speaking, before you avail any of our services, i.e., accessing the information displayed on the website like details regarding various types of exams, scholarships, college details, articles, blogs, or news, it is considered that you are abiding by these terms and conditions. These terms and conditions can be updated or amended by us from time to time as per our policies. We reserve the right to update as and when required, and the updated terms and conditions will govern you when you visit any of our service-providing platforms.
      </p>
      <p className="mb-4">
        You need not register at present to access our service or platforms. In this process, if any digital data is generated like cookies, IP address, or in any form, we are not storing any data. A very detailed policy of how we use your data is stated in the Privacy Policy.
      </p>
      <p className="mb-4">
        To access our service, the user has to be 18 or above. In case a minor is using our service, explicit parental or guardian consent is required. Users are warned not to reveal their personal information to any other users if they are minors. The company doesn’t take any legal responsibility if the user shares their personal information with any person other than when the company explicitly asks for it to provide service.
      </p>
      <p className="mb-4">
        We are constantly working on improving our services by adding new functionalities. As part of this, we may add, remove, or update the existing functionalities, and the user cannot claim any damages or legal issues due to these changes. If there is any significant change that impacts the user's data, the service user will be asked for explicit consent.
      </p>
      <p className="mb-4">
        If we learn that the information provided by you is falsified and misleading, we reserve the right to take appropriate legal action. The company has absolute authority over the kind of content displayed, including promotional advertisements and potential partners.
      </p>
      <p className="mb-4">
        When you access information, either in the form of written or pictorial representation or images by us or any vendors or contracts related to us on the website or application, it is copyrighted under Indian law. We reserve the right to take legal action if there is any unauthorized use of this information.
      </p>
      <p className="mb-4">
        If we find any misuse, abuse, harm, or interference with the information provided by us or the company name, strict legal action will be taken against the person.
      </p>
      <h3 className="font-bold text-lg mb-2">Prohibited Acts:</h3>
      <ol className="list-decimal list-inside mb-4">
        <li>Installing bugs, spamming, hacking, or bypassing our systems or protective measures.</li>
        <li>Copy-pasting the existing content or scraping the content. If any violation is found, you will be blocked from accessing our applications, and appropriate legal action will be initiated against you.</li>
        <li>Gaining or attempting to gain unauthorized access to any part or complete webpage, website, or mobile application or any URL related to us.</li>
        <li>Usage of any software or automated programs to crawl, scrape, or extract the information in any manner.</li>
        <li>Deciphering or trying to reverse engineer the source code.</li>
        <li>Creating unreasonable traffic to disrupt the general usage for the larger public.</li>
        <li>Campaigning and spreading misinformation against the company or the services we provide or the information displayed on the website.</li>
        <li>Repurposing these images or any information without any prior consent of the company.</li>
      </ol>
      <p className="mb-4">
        Before signing up to utilize any of the services, users must consent to the following:
      </p>
      <ol className="list-decimal list-inside mb-4">
        <li>They will not use any of the information to gain financial benefits.</li>
        <li>Misuse the communication page by sending junk mail or spam mail.</li>
        <li>Impersonating another person’s identity to get information.</li>
        <li>To threaten, harass, or intimidate any user.</li>
        <li>Our webpage or application might have some paid advertisements, and from time to time, according to company policy, certain details like your IP address and courses you are looking for might be shared with partner institutions and companies. As of now, i.e., 12-10-2024, no such data is collected by us.</li>
      </ol>
      <p className="mb-4">
        In case of any issues, you can report to us on our social media platforms or email us. Any litigation that arises with the company shall be adjudicated in Hyderabad civil courts.
      </p>
    </div>
  );
};

export default TermsAndConditions;
