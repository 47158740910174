import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchLandingData } from "./scholarshipThunk";

interface FeaturedScholarship {
  id: string;
  logo: string;
}

interface Mentor {
  imageUrl: string;
  name: string;
  role: string;
  experiance: string;

}

interface InspiringStory {
  image: string;
  name: string;
  designation: string;
  role: string;
  comments: string;
}

interface FAQItem {
  question: string;
  answer: string;
}

interface ScholarshipSlice {
  featured: FeaturedScholarship[];
  mentors: Mentor[];
  scholarshipSuccessStory: InspiringStory[];
  scholarshipFaq: FAQItem[];
  educationLoanFaqs: FAQItem[];
  landingDataLoading: boolean;
  landingDataError: string | null;
}

const initialState: ScholarshipSlice = {
  featured: [],
  mentors: [],
  scholarshipSuccessStory: [],
  scholarshipFaq: [],
  educationLoanFaqs: [],
  landingDataLoading: false,
  landingDataError: null,
};

const scholarshipSlice = createSlice({
  name: "scholarshipLanding",
  initialState,
  reducers: {
    setScholarshipData: (state, action: PayloadAction<ScholarshipSlice>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLandingData.pending, (state) => {
        state.landingDataLoading = true;
        state.landingDataError = null;
      })
      .addCase(fetchLandingData.fulfilled, (state, action) => {
        state.landingDataLoading = false;
        Object.assign(state, action.payload);
      })
      .addCase(fetchLandingData.rejected, (state, action) => {
        state.landingDataLoading = false;
        state.landingDataError =
          action.error.message || "Failed to fetch landing data";
      });
  },
});

export const { setScholarshipData } = scholarshipSlice.actions;
export default scholarshipSlice.reducer;
