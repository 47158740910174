import Filter from "./Filter";
import { useEffect, useState } from 'react';
import axios from 'axios';
import CollegeCard from "./CollegeCard";
import config from "../../../util/config";
const Explore = () => {
  const [responseData, setResponseData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [location, setLocation] = useState<string | null>(null);


  useEffect(() => {
    const fetchUserExploreData = async () => {
      const requestBody = {
        stream: ["1"], 
        states: ["string"], 
        city: ["string"], 
        fees: ["string"], 
        pageNo: 1,
        pageSize: 10 
      };

      try {
        const response = await axios.post(config.apiUrl + '/api/auth/user/userExplore', requestBody, {
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        setResponseData(response.data.data);
        console.log(responseData);
      } catch (error) {
        console.error("Error fetching user explore data:", error);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserExploreData();
  }, []); 
  const getLocation = (college: any) => {
    return college.collegeCity && college.collegeState
      ? `${college.collegeCity}, ${college.collegeState}`
      : 'Location not available'; 
  };
  return (
    <section>
      <Filter />
      <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 m-2'>
      {responseData?.popularColleges?.map((college:any, index:number) => (
        <CollegeCard 
          key={index} 
          collegeName={college.collegeName} 
          image={college.iconUrl} 
          category={college.category} 
          categoryId={college.collegeId}
          location={getLocation(college)} 
          type={"explore"}
          />
      ))}
    </div>
    </section>
  );
};

export default Explore;
