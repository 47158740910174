import { useEffect, useRef, useState } from "react";
import DesktopFilterMenu, {
  IFilterDataItem,
} from "../../components/filter/components/DesktopFilterMenu";
import config from "../../util/config";
import useSelectedFilter, {
  ICategory,
  IGetFilterDataFromPath,
  IGetPathFromFilterData,
  
} from "./Cards/UseSelectedFilter";
import { FilterCategory } from "../../components/filter/FilterEnum";
import ScholarshipCard from "../course-intermediate/components/ScholarshipCard";
import Banner from "../scholarship/intermediate/components/Banner";
import useDebouncedSearch from "../../components/filter/customHooks/useDebouncedSearch";
import useQueryParamPagination from "../../components/filter/customHooks/useQueryParamPagination";
import MobileFilterMenu from "../../components/filter/components/MobileFilterMenu";
import Pagination from "../../components/filter/components/Pagination";
import { faFilter, faX, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterChips from "../../components/filter/components/FilterChips";
import { useAxios } from "../../components/useAxios";
import PredictorResultCard from "./Cards/PredictorResultCard";

import ResultsGuidance from "./Cards/ResultsGuidance";
import Summary from "./Cards/Summary";
import ReportDownload from "./Cards/ReportDownload";
import Advertisment from "./Cards/Advertisment";
const PAGE_SIZE = 2;
interface ICollegeCourse {
    lastYearCutoffAiRank: number;
    courseName: string;
  }
  
  interface ICollege {
    collegeName: string;
    ownerShip: string;
    courseVo: ICollegeCourse[];
  }
  
  interface IFilterOption {
    name: string;
    count: number;
    key: string;
  }
  
  interface IFilter {
    options: IFilterOption[];
    categoryKey: string;
    categoryName: string;
  }
  
  interface IResponseData {
    toughChanceColleges: number;
    filter: IFilter[];
    goodChanceColleges: number;
    colleges: ICollege[];
    getinChanceColleges: number;
    totalCount: number;
  }
  
  interface IApiResponse {
    message: string;
    data: IResponseData;
    timeStamp: string;
  }
  
const EDUCATION_FILTER_CATEGORY = "branch";
const CITY_FILTER_CATEGORY = "city";
const FEE_FILTER_CATEGORY = "fee";
const STATE_FILTER_CATEGORY = "state";
const GENDER_FILTER_CATEGORY = "gender";
const CASTE_FILTER_CATEGORY = "caste";

const FILTER_CATEGORIES: ICategory[] = [
  {
    key: EDUCATION_FILTER_CATEGORY,
    type: FilterCategory.MultiSelect,
    showinPath: false,
  },
  {
    key: CITY_FILTER_CATEGORY,
    type: FilterCategory.SingleSelect,
    showinPath: false,  },
    {
      key: FEE_FILTER_CATEGORY,
      type: FilterCategory.SingleSelect,
      showinPath: false,

    },
    {
      key: STATE_FILTER_CATEGORY,
      type: FilterCategory.SingleSelect,
      showinPath: false,

    },
    {
      key: GENDER_FILTER_CATEGORY,
      type: FilterCategory.SingleSelect,
      showinPath: false,

    },
    {
      key: CASTE_FILTER_CATEGORY,
      type: FilterCategory.SingleSelect,
      showinPath: false,

    }

];
const rank=16000;
const counselling = "Joint Seat Allocation Authority (JoSAA Counselling";
const examName = "NEET"
const educationFilterData: IFilterDataItem = {
  categoryName: "Branch",
  categoryKey: EDUCATION_FILTER_CATEGORY,
  type: FilterCategory.MultiSelect,
  options: [
    { name: "B.Tech. in Computer Science and Engineerig", key: "B.Tech. in Computer Science and Engineering" },
    { name: "B.Tech. in Mechanical Engineerig", key: "Mechanical" },
    { name: "B.Tech. in Civil Engineerig", key: "civil" },
    { name: "B.Tech. in ECE Engineerig", key: "ECE " },
    { name: "B.Tech. in EEE Engineerig", key: "EEE" },
   
  ],
};
const cityFilterData: IFilterDataItem = {
  categoryName: "City",
  categoryKey: CITY_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Hyderabad", key: "Hyderabad" },
    { name: "Chennai", key: "Chennai" },
  
   
  ],
};
const feeFilterData: IFilterDataItem = {
  categoryName: "Fees",
  categoryKey: FEE_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "<1 Lakhs", key:"<1 Lakhs" },
    { name: "1 Lakhs <= 3 Lakhs", key: "1 Lakhs <= 3 Lakhs" },
  
   
  ],
};
const stateFilterData: IFilterDataItem = {
  categoryName: "State",
  categoryKey: STATE_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Tamilnadu", key:"Tamilnadu" },
    { name: "Telangana", key: "Telangana" },
  
   
  ],
};
const genderFilterData: IFilterDataItem = {
  categoryName: "Gender",
  categoryKey: GENDER_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Male", key:"Male" },
    { name: "Female", key: "Female" },
  
   
  ],
};
const casteFilterData: IFilterDataItem = {
  categoryName: "Caste",
  categoryKey: CASTE_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "OC", key:"OC" },
    { name: "MBC", key: "MBC" },
  
   
  ],
};
const getPathName: IGetPathFromFilterData = (filterData) => {
  return "";
};

const getPathData: IGetFilterDataFromPath = (path) => {
  return {};
};

const CollegePredictorResult = () => {
  const listingContainerRef = useRef<HTMLDivElement>(null);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const isMediumDevice = window.innerWidth >= 768;
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  const {
    selectedFilter,
    updateSelectedFilter,
    clearFilter,
    filterDataForApiBody,
  } = useSelectedFilter(
    FILTER_CATEGORIES,
    "college-predictor-result",
    getPathName,
    getPathData
  );

 
    const {
        data: courseData,
        loading: courseDataLoading,
        error: courseDataError,
        fetchData: fetchcourseData,
      } = useAxios<IApiResponse>({
        url: config.apiUrl + "/api/college/college/predicteColleges",
        method: "POST",
      });

      const cardData = {
        colleges: courseData?.data.colleges || [], 
      };    

      const summaryData = {
        toughChanceColleges: courseData?.data.toughChanceColleges || 0,
        goodChanceColleges: courseData?.data.goodChanceColleges || 0,
        getinChanceColleges: courseData?.data.getinChanceColleges || 0,
    }; 
    
    const {
    totalPages,
    currentPage,
    updateTotalCount,
    handlePrevPage,
    handleNextPage,
    handleResetPagination,
  } = useQueryParamPagination(PAGE_SIZE);
    const isEmptyObject = (obj: Record<string, any>) => Object.keys(obj).length === 0;


    useEffect(() => {
      updateTotalCount(courseData?.data.totalCount || 0);
    }, [courseData]);

  useEffect(() => {
    
      if (filterDataForApiBody && Object.keys(filterDataForApiBody).length > 0) 
        fetchcourseData(
        {},
        {
            ...filterDataForApiBody,
            rank: rank,
            counselling: counselling,
            examName: examName,
            pageSize: PAGE_SIZE,
            pageNo: currentPage-1,
        }
      );
  }, [filterDataForApiBody,currentPage]);
  useEffect(() => {
    if (!isFirstLoad && listingContainerRef.current) {
      listingContainerRef.current.scrollIntoView();
    }
  }, [currentPage]);

  const filterData: IFilterDataItem[] = [];

  filterData.push(educationFilterData);
  filterData.push(cityFilterData);
  filterData.push(feeFilterData);
  filterData.push(stateFilterData);
  filterData.push(genderFilterData);
  filterData.push(casteFilterData);

  return (
    <div className="bg-pink-light text-black font-inter">
      <div ref={listingContainerRef} className="container">
      <Summary  toughChanceColleges={summaryData.toughChanceColleges }
  goodChanceColleges={summaryData.goodChanceColleges}
  getinChanceColleges={summaryData.getinChanceColleges}/>

        <div className="flex gap-6 py-12">
          {isMediumDevice && (
            <div className="w-[30%]">
              <DesktopFilterMenu
                filterData={filterData}
                selectedFilter={selectedFilter}
                updateSelectedFilter={updateSelectedFilter}
                clearFilter={clearFilter}
              />
            </div>
          )}
          {!isMediumDevice && showMobileFilter && (
            <MobileFilterMenu
              filterData={filterData}
              selectedFilter={selectedFilter}
              updateSelectedFilter={updateSelectedFilter}
              clearFilter={clearFilter}
              closeFilter={() => setShowMobileFilter(false)}
            />
          )}

          <div className="w-full md:w-[70%] flex flex-col gap-6">

            {/* <FilterChips
              filterData={filterData}
              selectedFilter={selectedFilter}
              updateSelectedFilter={updateSelectedFilter}
            /> */}
            <div className="flex items-center justify-between gap-4">
              {!isMediumDevice && (
                <button
                  className="bg-white rounded-md p-3"
                  onClick={() => setShowMobileFilter(true)}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    color="white"
                    stroke="#7D7B89"
                    size="lg"
                    strokeWidth={40}
                  />
                </button>
              )}
            
            </div>
            <>
      <div className="container flex flex-col max-w-6xl min-h-screen ">

          <div className="flex flex-col gap-3 sm:col-span-1 md:col-span-6">
            <ReportDownload />
            <span>Showing 93 Colleges in Joint Seat Allocation Authority</span>
            <PredictorResultCard data={cardData}/>
            <ResultsGuidance />
            <Advertisment />
          </div>

          <div className=" sm:col-span-1 md:col-span-2"></div>
        </div>
    </>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegePredictorResult;
