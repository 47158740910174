import React from "react";
import ApplicatonsToAdmission from "./components/ApplicationsToAdmission";
import BookCounselling from "./components/BookCounselling";
import ProcessSteps from "./components/ProcessSteps";

const ApplicationsAdmissionsTwo = () => {
  return (
    <div>
      <ProcessSteps />
      <ApplicatonsToAdmission />
      <BookCounselling />
    </div>
  );
};

export default ApplicationsAdmissionsTwo;
