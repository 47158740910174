import React, { useState } from "react";
import knowledgeHubFaq from "../../images/study-abroad/study-abroad-img.png";

interface AccordionItem {
  question: string;
  answer: string;
}

interface KnowledgeHubProps {
  faqData: Record<
    "destination" | "service" | "examsAndScholarships" | "studentLife",
    AccordionItem[]
  >;
}

const tabs = [
  { key: "destination", label: "Destinations" },
  { key: "service", label: "Services" },
  { key: "examsAndScholarships", label: "Exams & Scholarships" },
  { key: "studentLife", label: "Student Life" },
];

const KnowledgeHub: React.FC<KnowledgeHubProps> = ({ faqData }) => {
  const [activeTab, setActiveTab] =
    useState<keyof KnowledgeHubProps["faqData"]>("destination");
  const [activeAccordion, setActiveAccordion] = useState<number>(0);

  const safeFaqData = faqData || {
    destinations: [],
    service: [],
    examsAndScholarships: [],
    studentLife: [],
  };

  const handleAccordionClick = (index: number) => {
    setActiveAccordion(index === activeAccordion ? -1 : index);
  };

  const handleTabChange = (tabKey: keyof KnowledgeHubProps["faqData"]) => {
    setActiveTab(tabKey);
    setActiveAccordion(0);
  };

  const getAccordionData = () => {
    return safeFaqData[activeTab] || [];
  };

  return (
    <section className="py-10 bg-[#EEF2FF]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-10 items-center">
          <div className="lg:w-3/5 flex flex-col">
            <div className="flex flex-col gap-6">
              <h3 className="text-4xl lg:text-5xl font-bold text-blue">
                Knowledge Hub (FAQ's)
              </h3>

              <div className="flex flex-wrap md:flex-row gap-4">
                {tabs.map((tab) => (
                  <button
                    key={tab.key}
                    className={`px-4 py-2 rounded-full text-sm font-medium ${
                      activeTab === tab.key
                        ? "bg-blue-900 text-white"
                        : "bg-transparent border border-blue-900 text-blue-900"
                    }`}
                    onClick={() =>
                      handleTabChange(
                        tab.key as keyof KnowledgeHubProps["faqData"]
                      )
                    }
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-6 mt-12">
              {getAccordionData().map((item: AccordionItem, index: number) => (
                <div key={index} className="rounded-lg overflow-hidden">
                  <button
                    className={`w-full flex gap-3.5 items-center text-sm font-semibold text-black bg-transparent`}
                    onClick={() => handleAccordionClick(index)}
                  >
                    <span className="flex">
                      {activeAccordion === index ? (
                        <span className="text-xl">
                          <svg
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="23"
                              height="23"
                              rx="3"
                              fill="#173CBA"
                            />
                            <path
                              d="M5 12H17"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </span>
                      ) : (
                        <span className="text-xl">
                          <svg
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="23"
                              height="23"
                              rx="3"
                              fill="#173CBA"
                            />
                            <path
                              d="M5 12H17"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M11 18L11 6"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </span>
                      )}
                    </span>
                    <span className="text-lg font-medium text-left text-black">
                      {item.question}
                    </span>
                  </button>
                  {activeAccordion === index && (
                    <div className="p-4 text-black text-sm">
                      <p>{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="lg:w-2/5 flex justify-center">
            <div className="w-full h-full">
              <img
                src={knowledgeHubFaq}
                alt="Knowledge Hub Image"
                className="w-full h-auto rounded-lg shadow-md object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KnowledgeHub;
