const ReportDownload = () => {
  return (
    <div className="flex justify-center w-full">
      <div className="w-full flex flex-row">
        <div className="flex  py-2 item-center  w-[80%] bg-[#173CBA] text-white text-[16px] pl-4 pt-1">
          Get Personalised Report in PDF
        </div>
        <a
          href="/pdf/sample.pdf"
          download="Report.pdf"
          className="flex item-center  py-2 justify-center w-[20%] bg-[#0C9] pt-1 text-white text-[16px] px-4 inline-block text-center cursor-pointer"
        >
          Send
        </a>
      </div>
    </div>
  );
};

export default ReportDownload;
