import React from "react";
import TitleCard from "../../components/TitleCard";
import BlogInfoCard from "../../components/BlogInfoCard";

import ExamBlogsImage1 from "../../../../images/blog-article/exam-section-image1.jpg";
import ExamBlogsImage2 from "../../../../images/blog-article/exam-section-image2.jpeg";
import ExamBlogsImage3 from "../../../../images/blog-article/exam-section-image3.jpg";
import ExamBlogsImage4 from "../../../../images/blog-article/exam-section-image4.jpg";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import BlogCardwithTitle from "../../components/BlogCardwithTitle";
import { GetWindowDimensions } from "../../../../hooks/window-hooks/WindowHooks";
import { Link } from "react-router-dom";

interface CareersAndCoursesProps {
  careersAndCoursesResponse: any;
}
const CareersAndCourses: React.FC<CareersAndCoursesProps> = ({
  careersAndCoursesResponse = [],
}) => {
  const { width, height } = GetWindowDimensions();
  const getCurrentHoveredBlog = (param: any) => {};

  let isMobile = width < 700;

  const showOnlyFiveList = (results: any) => {
    if (isMobile) return results.slice(0, 5);
    else return results;
  };

  return (
    <>
      <div className="container grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
        <div className="grid grid-flow-row gap-4 grid-rows">
          <TitleCard
            title="Careers and Courses"
            bgColor="#173CBA"
            color="#ffffff"
          />

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1">
            <CustomSwiper
              className="!px-2"
              items={careersAndCoursesResponse[0]?.[
                "Career & Courses"
              ]?.subcategories["Articles & Blogs"]?.results?.map(
                (item: any) => (
                  <BlogCardwithTitle item={item} />
                )
              )}
              // items={careersAndCoursesResponse[0]?.[
              //   "Careers And Courses"
              // ]?.subcategories["Articles & Blogs"]?.results?.map(
              //   (item: any) => (
              //     <BlogCardwithTitle item={item} />
              //   )
              // )}
              navigation={"bottom"}
              autoplay={true}
              progressBar={true}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 3, spaceBetween: 20 },
                1024: { slidesPerView: 3, spaceBetween: 18 },
                1280: { slidesPerView: 4, spaceBetween: 30 },
              }}
            />
          </div>

          <div className="grid grid-cols-1 gap-4 p-4 mt-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {/* {[...Array(4)].map(() => (
              <>
                {[...Array(2)].map(() => (
                  <BlogInfoCard
                    dateValue={true}
                    rowReverse={true}
                    blogInfo={[]}
                    getCurrentHoveredItem={getCurrentHoveredBlog}
                  />
                ))}
              </>
            ))} */}

            <>
              {showOnlyFiveList(
                careersAndCoursesResponse[0]?.["Career & Courses"]
                  ?.subcategories["Latest News"]?.results
              )?.map((blogInfo: any) => (
                <BlogInfoCard
                  dateValue={true}
                  rowReverse={true}
                  blogInfo={blogInfo}
                  getCurrentHoveredItem={getCurrentHoveredBlog}
                  height=""
                  categoryOrderReverse={false}
                  borderBottom={false}
                  imageField={true}
                />
              ))}
            </>
          </div>

          {isMobile && (
            <div className="flex flex-row items-center justify-center">
              <Link
                to={`/blogs/career-courses`}
                state={{
                  from: "CareersAndCourses view all",
                  category: "career & courses",
                }}
                className="h-full white-color map-mentor button-styles arrow-button"
              >
                View All
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Old implementation without Swipers */}

      {/* <div className="container grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
        <div className="grid grid-flow-row gap-4 grid-rows">
          <TitleCard
            title="Careers and Courses"
            bgColor="#173CBA"
            color="#ffffff"
          />
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-4">
            <div className="bg-gray-100 image-container">
              <img src={ExamBlogsImage1} />
            </div>
            <div className="bg-gray-100 image-container">
              <img src={ExamBlogsImage2} />
            </div>
            <div className="bg-gray-100 image-container">
              <img src={ExamBlogsImage3} className="h-full" />
            </div>
            <div className="bg-gray-100 image-container">
              <img src={ExamBlogsImage4} height="100%" />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-4">
            <div className="">
              {[...Array(2)].map(() => (
                <BlogInfoCard dateValue={true} rowReverse={true} />
              ))}
            </div>
            <div className="">
              {[...Array(2)].map(() => (
                <BlogInfoCard dateValue={true} rowReverse={true} />
              ))}
            </div>
            <div className="">
              {[...Array(2)].map(() => (
                <BlogInfoCard dateValue={true} rowReverse={true} />
              ))}
            </div>
            <div className="">
              {[...Array(2)].map(() => (
                <BlogInfoCard dateValue={true} rowReverse={true} />
              ))}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CareersAndCourses;
