import React, { Fragment } from "react";
import PersonalInfoForm from "../../PersonalInfoForm";
import CurrentLevelStudy from "../../CurrentLevelStudy";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../../../../images/navbar/newLogo.svg";
import SignInForm from "../../../../login/SignInForm";
import { RootState } from "../../../../../../store/store";
import {
  nextStep,
  prevStep,
} from "../../../../../../store/slices/registrationFormSlice";
import MultiStepForm from "../../MultiStepForm";

import "../../../Registration.scss";
import { useNavigate } from "react-router-dom";
interface RegistrationLayoutProps {
  children: React.ReactNode;
}
const RegistrationLayout: React.FC<RegistrationLayoutProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const { data, step } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  const handleNext = () => {
    dispatch(nextStep());
  };

  const handleBack = () => {
    dispatch(prevStep());
  };

  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="flex flex-col min-h-screen bg-[#fff] auth-bg">
        <header className="p-4 ">
          <div className="container flex p-4 reg_layout_header">
            <div className="">
              <img src={Logo} alt="logo" />
            </div>
            {/* <div className="flex justify-end grow">Close Icon</div> */}
          </div>
        </header>

        <main className="flex items-start justify-center flex-grow mt-12">
          {/* <div className="w-full max-w-xs rounded-lg sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"> */}
          {children}
          {/* </div> */}
        </main>

        {/* <div className="flex justify-center">
          Already have an account?{" "}
          <button
            className="text-[#113cc0] font-semibold bg-transparent hover:underline focus:outline-none"
            onClick={(e) => navigate("/secure/login")}
          >
            Log In
          </button>
        </div> */}
        {/* <footer className="p-4 bg-transparent-800">
          <div className="flex xs:flex-row sm:flex-col md:justify-between md:flex-row">
            <div className="text-sm text-center">
              By using our service, you agree to the{" "}
              <a
                href="/terms-of-service"
                className="font-semibold text-blue-600 underline hover:no-underline"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy-policy"
                className="font-semibold text-blue-600 underline hover:no-underline"
              >
                Privacy Policy
              </a>
              .
            </div>

            <p className="text-sm">© 2024</p>
          </div>
        </footer> */}
      </div>

      {/* <div className="flex flex-col min-h-screen">
        <header className="p-4 text-white bg-blue-600">
          <h1 className="text-3xl font-bold text-center">Common Header</h1>
        </header>

        <main className="flex items-center justify-center flex-grow">
          <div className="w-full max-w-xs p-6 bg-gray-200 rounded-lg sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl">
            <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2">
              <div className="p-4 bg-white rounded-lg shadow-md">
                <h3 className="mb-2 text-lg font-bold">Card 1</h3>
                <p className="text-gray-600">Description of Card 1</p>
              </div>

              <div className="p-4 bg-white rounded-lg shadow-md">
                <h3 className="mb-2 text-lg font-bold">Card 2</h3>
                <p className="text-gray-600">Description of Card 2</p>
              </div>
            </div>

            <div className="p-4 text-center bg-gray-300 rounded-lg">
              <p className="text-gray-700">
                This is a static footer for the next container
              </p>
            </div>
          </div>
        </main>

        <footer className="p-4 text-center text-white bg-gray-800">
          <p>© 2024 Your Company. All rights reserved.</p>
        </footer>
      </div> */}
    </Fragment>
  );
};

export default RegistrationLayout;
