// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes blogFooterPulse {
  from {
    opacity: 1;
    height: 0;
    width: 0;
  }
  to {
    opacity: 0;
    height: 6rem;
    width: 6rem;
  }
}
.blog-footer .pulse::after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  background: white;
  border-radius: 100%;
  transform: translate(calc(-50% + 0.25rem), calc(-50% + 0.25rem));
}
.blog-footer .pulse.active::after {
  animation: blogFooterPulse 1s ease-out;
}`, "",{"version":3,"sources":["webpack://./src/screens/blogs-articles/components/BlogFooter.scss"],"names":[],"mappings":"AAAA;EACE;IACE,UAAA;IACA,SAAA;IACA,QAAA;EACF;EACA;IACE,UAAA;IACA,YAAA;IACA,WAAA;EACF;AACF;AAGE;EACE,WAAA;EACA,cAAA;EACA,SAAA;EACA,QAAA;EACA,iBAAA;EACA,mBAAA;EACA,gEAAA;AADJ;AAGE;EACE,sCAAA;AADJ","sourcesContent":["@keyframes blogFooterPulse {\n  from {\n    opacity: 1;\n    height: 0;\n    width: 0;\n  }\n  to {\n    opacity: 0;\n    height: 6rem;\n    width: 6rem;\n  }\n}\n\n.blog-footer {\n  .pulse::after {\n    content: \"\";\n    display: block;\n    height: 0;\n    width: 0;\n    background: white;\n    border-radius: 100%;\n    transform: translate(calc(-50% + 0.25rem), calc(-50% + 0.25rem));\n  }\n  .pulse.active::after {\n    animation: blogFooterPulse 1s ease-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
