import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useState } from "react";
import ApplyNowForm from "../../../exams/components/ApplyNowForm";
import Modal from "../../../../components/Modal";

const LoanCourses = () => {
  const courses = useSelector(
    (state: RootState) => state.educationLoan.courses
  );

  const [leadGenerationPopupIsOpen, setLeadGenerationPopupIsOpen] =
    useState(false);

  return (
    <div className="border border-grey-4 shadow-lg">
      <div className="text-2xl text-white bg-blue px-8 py-6">
        Education Loan for top Courses
      </div>
      <ul className="list-none divide-y divide-grey-4 p-4">
        {courses?.map((course) => (
          <li className="p-2 text-sm">
            <button
              onClick={() => setLeadGenerationPopupIsOpen(true)}
            >{`Education Loan for ${course.courseName}`}</button>
          </li>
        ))}
      </ul>
      <LeadGenerationPopup
        isOpen={leadGenerationPopupIsOpen}
        onClose={() => setLeadGenerationPopupIsOpen(false)}
      />
    </div>
  );
};

export default LoanCourses;

const LeadGenerationPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ApplyNowForm />
    </Modal>
  );
};
