import "./CareerDetailsStyles.scss";

const DataScienceRoadmap: React.FC<{ roadmap: any }> = ({ roadmap }) => {
  if (!roadmap || !roadmap.description) return null;

  return (
    <section>
      <div className="container mx-auto">
        <div className="flex max-md:w-full">
          <h3 className="text-xl lg:text-2xl max-md:text-center font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {roadmap.title}
          </h3>
        </div>

        <div className="roadmap-image-container flex flex-col items-center justify-center">
          <img
            src={roadmap.description}
            alt={roadmap.title}
            className="w-full h-auto object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default DataScienceRoadmap;
