// Global mapping for the order of subcategories under each category
const subcategoryOrderMap: Record<string, string[]> = {
  Exams: [
    "Board Exams",
    "College Entrance Exams",
    "Competitive Exams",
    "International Exams",
    "Scholarship Exams",
    "State Entrance Exams",
  ],
  Admissions: [
    // Define subcategories for Admissions
    "College Admissions",
    "International Admissions",
    "Online Admissions",
    "School Admissions",
  ],
  Scholarships: [
    // Define subcategories for Scholarships
    "School Scholarships",
    "College Scholarships",
    "Sports Scholarships",
    "Minority Scholarships",
    "Girl Scholarships",
    "International Scholarships",
  ],
  "Career & Courses": [
    // Define subcategories for Career & Courses
    "Trending Careers",
    "Trending Courses",
    "Online Courses",
    "Start Up & Innovations",
  ],
  "Study Abroad": [
    // Define subcategories for Study Abroad

    "Top Countries",
    "Student Visas",
    "International Scholarships",
    "International Exams",
  ],
  Colleges: [
    "Engineering",
    "Medical & Health Sciences",
    "Business & Management",
    "Architecture & Design",
    "Law Admissions",
    "Liberal Arts",
    "Others",
  ],
  // Add more categories as needed
};

export { subcategoryOrderMap };
