import "./CareerDetailsStyles.scss";

const CareerPaths: React.FC<{ careerPaths: any; topColleges: any }> = ({
  careerPaths,
  topColleges,
}) => {
  if (!careerPaths) return null;

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col lg:flex-row justify-between md:pr-8 gap-6">
            <div className="flex flex-col w-full lg:w-4/5">
              <div className="flex max-md:flex-col max-md:w-full">
                <h3 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                  {careerPaths.title}
                </h3>
              </div>
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 careerPathsDescription"
                dangerouslySetInnerHTML={{
                  __html: careerPaths.description as string,
                }}
              ></div>
            </div>
            <div className="hidden lg:flex flex-col items-center justify-center w-full lg:w-1/5 relative bg-[#D9D9D9]">
              <div className="p-1">
                <img
                  src={careerPaths.imageUrl}
                  alt={careerPaths.title}
                  className="w-full h-full object-cover rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>

          {/* Top Colleges Section */}
          <div className="top-colleges mt-10">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
              {topColleges?.map((college: any, index: number) => (
                <div
                  key={index}
                  className="flex flex-col items-center p-4 border rounded-lg shadow-sm"
                >
                  <img
                    src={college.imageUrl}
                    alt={college.collegeName}
                    className="w-16 h-16 object-cover mb-4"
                  />
                  <p className="text-center font-semibold">
                    {college.collegeName}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerPaths;
