import React from 'react';
import Image from '../../../components/Image';

interface MentorCardProps {
    key: number;
    imageUrl: string;
    name: string;
    role: string;
    experiance: string;
    worksOn: string;
    location: string;
}

const MentorCard: React.FC<MentorCardProps> = ({ key, imageUrl, name, role, experiance, worksOn, location }) => {
    return (
        <div className="relative bg-white border border-gray-300 rounded-lg overflow-hidden shadow-lg max-w-sm px-2 flex flex-col"> 
            <Image aspectRatio={100} src={imageUrl} alt={""} className="w-full mx-auto rounded-lg object-contain" />
            <div className="py-2 px-2 flex-grow">
                {worksOn &&
                    <p className='text-[14px] text-green-800 py-2  '>
                        <span className='bg-green-200 px-2 py-1 rounded-2xl font-bold'> {worksOn} </span>
                    </p>}
                <h1 className="text-[20px] font-bold mb-2 whitespace-nowrap pt-2">{name}</h1>
                <div className='flex flex-row pt-2 border-b border-gray-300'>
                    <p className=" text-sm mb-4 pl-1"> <span className='font-bold'>{role}</span></p>
                </div>
                <div className="mb-4 flex flex-col pt-2">

                    {experiance && <span className="block text-[14px] pr-4"><span className='font-bold'>Experience:</span> {experiance} </span>}


                    {location && <span className="text-gray-800 text-sm text-[14px] pr-12 "><span className='font-bold'>Location: </span>{location}</span>}

                </div>
            </div>
            <div className="flex justify-center items-center pb-2"> 
                <button
                    className=" white-color button-styles map-mentor arrow-button flex flex-row items-center justify-center gap-2"
                > View Details
                </button>
            </div>
        </div>
    );
};

export default MentorCard;