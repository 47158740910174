import React, { useEffect, useState } from "react";
import CollegeBanner from "./components/Banner";
import NavigationTabs from "./components/NavigationTabs";
import Loader from "../../components/Loader/Loader";
import config from '../../util/config';

const CollegeDetails = (selectedCollege: any) => {
  const collegeId = selectedCollege?.selectedCollege?.collegeId
    ? selectedCollege?.selectedCollege?.collegeId
    : 1484;

  const [collegeDetails, setCollegeDetails] = useState(null);

  useEffect(() => {
    if (!collegeId) {
      return;
    }
    const fetchCollegeDetails = async () => {
      try {
        const response = await fetch(
          config.apiUrl + "/api/college/college-details/overview",
          {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              collegeId: collegeId,
              categoryOfRanking: "",
              yearOfRanking: "",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch college details");
        }

        const data = await response.json();
        setCollegeDetails(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCollegeDetails();
  }, [collegeId]);

  if (!collegeDetails) {
    return <Loader />;
  }

  return (
    <div>
      <CollegeBanner collegeDetails={collegeDetails} />
      <NavigationTabs collegeDetails={collegeDetails} />
    </div>
  );
};

export default CollegeDetails;
