


const CreditScore = () =>{
return(
<div className="bg-white py-12">
<div className="container flex flex-row items-center justify-between">
  <div className="text-[#383838] font-roboto text-base font-medium leading-[30px] flex flex-col">
    <div className="font-bold">
      Check Your Credit Score in 2 Minutes
    </div>
    <div>
      Get the Funds You Need to Achieve Your Academic Aspirations!
    </div>
  </div>

  <div className="flex-shrink-0">
    <img src="\images\scholarship\landing\CreditScore.png" alt="Credit Score" className="h-auto max-w-full" />
  </div>

  <div>
    <button className="bg-[#0C9] px-4 py-2 text-white rounded-2xl">Check Score</button>
  </div>
</div>

    </div>
)
}

export default CreditScore;