import React from "react";
import TitleCard from "../../components/TitleCard";
import MentorCard from "../../components/MentorCard";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import { GetWindowDimensions } from "../../../../hooks/window-hooks/WindowHooks";
interface PopularMentorsProps {
  popularMentorResponse: any;
}
const PopularMentors: React.FC<PopularMentorsProps> = ({
  popularMentorResponse,
}) => {
  const { width } = GetWindowDimensions();
  return (
    <div className="container grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
      <div className="grid grid-flow-row gap-4 grid-rows">
        <TitleCard title="Popular Authors" bgColor="#173CBA" color="#ffffff" />
      </div>

      <div className="">
        <CustomSwiper
          className="!px-2"
          items={Object.keys(popularMentorResponse?.subcategories)
            ?.filter(
              (authorName) =>
                popularMentorResponse?.subcategories[authorName]?.[
                  "Latest News"
                ]?.results?.length >= 3
            )
            ?.map((authorName) => {
              const data = popularMentorResponse?.subcategories[authorName];

              return (
                <div className="last:border-r-0 md:border-r-2 md:pr-4">
                  <MentorCard
                    authorName={authorName}
                    authorImageUrl={data?.authorImageUrl}
                    blogs={data?.["Latest News"]}
                  />
                </div>
              );
            })}
          navigation={width < 700 ? "bottom" : "hidden"}
          autoplay={true}
          progressBar={width < 700 ? true : false}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 4, spaceBetween: 20 },
            1024: { slidesPerView: 4, spaceBetween: 18 },
            1280: { slidesPerView: 4, spaceBetween: 15 },
          }}
        />
      </div>
    </div>
  );
};

export default PopularMentors;
