import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

import logo from "../../images/navbar/newLogo.svg";
import { Link } from "react-router-dom";

const EducationForAllIntermediate = () => {
  return (
    <div className="font-roboto text-grey flex flex-col gap-4 lg:gap-1">
      <section className="text-white flex max-md:flex-col bg-[#0A0A60]">
        <div className="py-20 lg:py-36 px-6 lg:px-12 lg:w-1/2 self-center">
          <Breadcrumbs
            links={[
              { name: "Home", path: "/" },
              { name: "Education for All Intermediate", path: "/" },
            ]}
          />
          <h1 className="mt-6 text-4xl lg:text-5xl font-bold">
            Are you a College Mentor student?
          </h1>
          <p className="mt-4 text-lg lg:text-xl">
            If yes, then you can track the progress of the child whose education
            you have helped sponsor.
          </p>
          <form>
            <div className="mt-6 flex gap-4 flex-wrap text-black">
              <input
                type="email"
                required
                placeholder="Enter the email you are enrolled with CM"
                className="p-2 rounded text-base w-72 text-base placeholder:text-sm placeholder:text-grey"
              />
              <input
                type="text"
                required
                placeholder="Your Enrolment number eg. cm1666"
                className="p-2 rounded text-base w-64 text-base placeholder:text-sm placeholder:text-grey"
              />
            </div>
            <button className="mt-6 bg-green px-4 py-2 rounded">
              Track <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </form>
        </div>
      </section>
      <section className="bg-[#EEF2FF] overflow-hidden">
        <div className="container py-12 grid grid-cols-2 gap-y-12 gap-x-4 lg:grid-cols-3">
          <div className="max-md:col-span-2">
            <img src={logo} />
          </div>
          <div>
            <p className="font-bold">Important Links</p>
            <ul className="mt-2 flex flex-col gap-2 list-none">
              <li>
                <Link to="/">About Levrate Edu</Link>
              </li>
              <li>
                <Link to="/">Track Child Progress</Link>
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold">Contact Us</p>
            <ul className="mt-2 flex flex-col gap-2 list-none">
              <li>cm.1666@cm.com</li>
            </ul>
          </div>
        </div>
        <div className="max-md:w-[200vw] max-md:-translate-x-[10%]">
          <img
            className="my-12 w-full  object-cover opacity-30"
            src="/images/education-for-all/monuments.png"
          />
        </div>
      </section>
    </div>
  );
};

export default EducationForAllIntermediate;
