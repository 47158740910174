import React, { useState } from "react";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import PlaceholderChipGroup from "./common/PlaceholderChip/PlaceholderChipGroup";
import {
  nextStep,
  updateForm,
} from "../../../../store/slices/registrationFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { RootState } from "../../../../store/store";
import OptionGroup from "./common/OptionsCard/OptionGroup";
import { signupPAFDetail } from "../../../../store/thunks/authThunks";

export const COURSE_SPECIALIZATIONS: string[] = [
  "Engineering",

  "Law",
  "Medicine and Allied Sciences",
  "Architecture",
  "Management and Business Administration",
];

const StreamLookingFor = () => {
  const dispatch = useDispatch<any>();
  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    currentLevelOfStudy,
    stream,
  } = useSelector((state: RootState) => state.registrationFormSlice.data);
  const { pafData } = useSelector((state: RootState) => state.auth);
  const { register, handleSubmit } = useForm();

  const [selectedCard, setSelectedCard] = useState<any>("");

  const [selectedMultiple, setSelectedMultiple] = useState<any[]>([]);

  const [selectedSingle, setSelectedSingle] = useState<any>(null);

  const onSubmit = (data: any) => {
    dispatch(updateForm({ stream: selectedSingle }));
    dispatch(nextStep());
  };

  const handleCardClick = (card: string) => {
    console.log("CourseLookingFor - Message from Child: ", card);
    setSelectedCard(card);
  };

  const handleNext = () => {
    console.log("get list of courses based on stream", selectedSingle);
    dispatch(updateForm({ stream: selectedSingle }));
    dispatch(
      signupPAFDetail({
        streamId: selectedSingle?.streamId,
        courseId: 0,
        state: 0,
        city: 0,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(nextStep());
      })
      .catch((err: any) => {
        console.error("OTP verification failed:", err);
      });
  };

  // multi select values
  const handleMultipleSelectionChange = (selected: any[] | any) => {
    if (Array.isArray(selected)) {
      setSelectedMultiple(selected);
    }
  };

  const handleSingleSelectionChange = (selected: any) => {
    setSelectedSingle(selected);
  };
  return (
    <div className="flex flex-col w-auto gap-2">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={false}
        showBack={true}
        stepName="Stream Looking For?"
        stepDescription=""
      >
        <div className="w-full p-4 overflow-auto md:h-21rem">
          {/* Old Implementation */}
          {/* <OptionGroup
            mode={"single"}
            options={COURSE_SPECIALIZATIONS}
            handleCardSelect={handleCardClick}
            cardSize={"auto"}
          /> */}
          {/* <OptionGroup
            options={pafData.lookingForCourse}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.courseName
            }
            handleCardSelect={handleCardClick}
            cardSize={"80"}
          /> */}

          <OptionGroup
            options={pafData.lookingForStream}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.streamName
            }
            onSelectionChange={handleSingleSelectionChange}
            cardSize="auto"
          />
          {/* <h3 className="mt-4">Selected Multiple Options (Full Objects):</h3>
          <pre>{JSON.stringify(selectedSingle, null, 2)}</pre> */}
        </div>
      </StepNavigationWrapper>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={handleNext}
          className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
          style={{ padding: "8px 18px" }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default StreamLookingFor;
