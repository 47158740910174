// Shortlist.tsx
import { useEffect, useState } from 'react';
import axios from 'axios';
import CollegeCard from './CollegeCard';
interface Shortlist {
  image: string;
  title: string;
  category: string;
collegeId:number;
  location:string;
}

const Shortlist = () => {
  const [shortListData, setShortListData] = useState<Shortlist[]>([]);
  const [userId, setUserId] = useState<string | null>(null);
  console.log("im in shortlist card ",userId);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserId(parsedUser.userId); 
    }
  }, []);

  useEffect(() => {
    if (userId) {
      axios({
        url: `https://mentormenteedev.com/api/wishlist/category/${userId}?category=college`,
        method: "GET",
      })
      .then((response) => {
        setShortListData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching mentor data:", error);
      });
    } else {
      console.log("userId is null, skipping API call");
    }
  }, [userId]);

  return (
    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 m-2'>
      {shortListData.map((college, index) => (
        <CollegeCard 
          key={college.collegeId} 
          collegeName={college.title} 
          image={college.image} 
          category={college.category} 
          categoryId={college.collegeId}
          location={college.location || "location not available"}
          type={"shortlist"}
        />
      ))}
    </div>
  );
};

export default Shortlist;
