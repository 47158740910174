import React from "react";
import StudyAbroadImage from "../../../images/blog-article/study-abroad-image.png";
import StoryIcon from "../../../components/blogs-and-articles/images/stories-icon.png";
import DefaultThumbNail from "../../../images/blog-article/default.png";
import moment from "moment";
interface IProps {
  title: string;
  description: string;
  image: string;
  topic: string;
  publishedDate: string;
  openVisualStory: () => void;
}
export const VisualStoriesCard: React.FC<IProps> = ({
  title,
  description,
  image,
  openVisualStory,
  topic,
  publishedDate
}) => {
  const formattedDate = (date: any) =>
    moment(date).format("MMM D, YYYY");
  return (
    <>
      <div
        className="rounded overflow-hidden shadow-lg p-4 cursor-pointer"
        onClick={openVisualStory}
      >
        <div className="relative">
          <img
            className="w-full h-80"
            src={image || DefaultThumbNail}
            style={{ objectFit: "cover" }}
          />
          <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
          <img src={StoryIcon} className="absolute top-4 right-4 w-8" />
          {/* <a href="!#">
            <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
              <span className="font-bold">27</span>
              <small>March</small>
            </div>
          </a> */}
        </div>
        <div className="py-4 flex-grow-1 ">
          <div className="font-semibold text-lg inline-block  hover:text-indigo-600 transition duration-500 ease-in-out ">
            <span className="line-clamp-2 min-h-[60px]">{title}</span>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          {formattedDate(publishedDate)}
          <p className="flex grow items-end justify-end">
            <div className="relative grid select-none items-center whitespace-nowrap rounded-full bg-[#00c798] py-1.5 px-3 font-sans text-xs font-bold uppercase text-white">
              <span className="">{topic}</span>
            </div>
          </p>
        </div>
      </div>

      {/* <div className="max-w-screen-md ">
        <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-4">
          <div className="rounded overflow-hidden shadow-lg">
            <a href="#"></a>
            <div className="relative">
              <a href="#">
                <img
                  className="w-full"
                  src="https://images.pexels.com/photos/196667/pexels-photo-196667.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500"
                  alt="Sunset in the mountains"
                />
                <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
              </a>
              <a href="#!">
                <div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  Photos
                </div>
              </a>

              <a href="!#">
                <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  <span className="font-bold">27</span>
                  <small>March</small>
                </div>
              </a>
            </div>
            <div className="px-6 py-4">
              <a
                href="#"
                className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out"
              >
                Best View in Newyork City
              </a>
              <p className="text-gray-500 text-sm">
                The city that never sleeps
              </p>
            </div>
            <div className="px-6 py-4 flex flex-row items-center"></div>
          </div>
          <div className="rounded overflow-hidden shadow-lg">
            <a href="#"></a>
            <div className="relative">
              <a href="#">
                <img
                  className="w-full"
                  src="https://images.pexels.com/photos/1653877/pexels-photo-1653877.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500"
                  alt="Sunset in the mountains"
                />
                <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
              </a>
              <a href="#!">
                <div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  Photos
                </div>
              </a>

              <a href="!#">
                <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  <span className="font-bold">20</span>
                  <small>March</small>
                </div>
              </a>
            </div>
            <div className="px-6 py-4">
              <a
                href="#"
                className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out"
              >
                Best Pizza in Town
              </a>
              <p className="text-gray-500 text-sm">
                The collection of best pizza images in Newyork city
              </p>
            </div>
            <div className="px-6 py-4 flex flex-row items-center"></div>
          </div>
          <div className="rounded overflow-hidden shadow-lg">
            <a href="#"></a>
            <div className="relative">
              <a href="#">
                <img
                  className="w-full"
                  src="https://images.pexels.com/photos/257816/pexels-photo-257816.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500"
                  alt="Sunset in the mountains"
                />
                <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
              </a>
              <a href="#!">
                <div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  Photos
                </div>
              </a>

              <a href="!#">
                <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  <span className="font-bold">15</span>
                  <small>April</small>
                </div>
              </a>
            </div>
            <div className="px-6 py-4">
              <a
                href="#"
                className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out"
              >
                Best Salad Images ever
              </a>
              <p className="text-gray-500 text-sm">
                The collection of best salads of town in pictures
              </p>
            </div>
            <div className="px-6 py-4 flex flex-row items-center"></div>
          </div>
          <div className="rounded overflow-hidden shadow-lg">
            <a href="#"></a>
            <div className="relative">
              <a href="#">
                <img
                  className="w-full"
                  src="https://images.pexels.com/photos/196667/pexels-photo-196667.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500"
                  alt="Sunset in the mountains"
                />
                <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
              </a>
              <a href="#!">
                <div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  Photos
                </div>
              </a>

              <a href="!#">
                <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  <span className="font-bold">27</span>
                  <small>March</small>
                </div>
              </a>
            </div>
            <div className="px-6 py-4">
              <a
                href="#"
                className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out"
              >
                Best View in Newyork City
              </a>
              <p className="text-gray-500 text-sm">
                The city that never sleeps
              </p>
            </div>
            <div className="px-6 py-4 flex flex-row items-center"></div>
          </div>
        </div>
      </div> */}

      {/* <div className="rounded overflow-hidden shadow-lg p-4">
        <a href="#"></a>
        <div className="relative">
          <a href="#">
            <img className="w-full" src={StudyAbroadImage} />
            <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
          </a>

          <a href="!#">
            <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
              <span className="font-bold">20</span>
              <small>March</small>
            </div>
          </a>
        </div>
        <div className="py-4">
          <a
            href="#"
            className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out"
          >
            Top 10 NIRF Engineering Colleges in India
          </a>
        </div>
        <div className="flex flex-row items-center gap-2">
          24 July 2024{" "}
          <p className="flex grow items-end justify-end">
            <div className="relative grid select-none items-center whitespace-nowrap rounded-full bg-[#00c798] py-1.5 px-3 font-sans text-xs font-bold uppercase text-white">
              <span className="">College</span>
            </div>
          </p>
        </div>
      </div> */}
    </>
  );
};
