import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules"; // Import Autoplay and Navigation
import defaultImg from "../../images/study-abroad/profile-img.png";
import BadgeIcon from "../../images/study-abroad/badges-icon.png";
import type { SVGProps } from "react";
import { LeadGenerationPopup } from "../../screens/colleges-new/landing/components/WebinarAndArticles";

// SVG for navigation button
export function IconParkOutlineRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="m19 12l12 12l-12 12"
      ></path>
    </svg>
  );
}

interface UniversitiesWorldwideMentorsProps {
  connectToMentor: {
    name: string;
    role: string;
    imageUrl?: string;
    worksOn?: string[];
    info: string;
    id: number;
    country?: string;
  }[];
}

const UniversitiesWorldwideMentors: React.FC<
  UniversitiesWorldwideMentorsProps
> = ({ connectToMentor }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [swiper, setSwiper] = useState<any>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("All");
  const [filteredMentors, setFilteredMentors] = useState(connectToMentor);

  const tabs = [
    "All",
    "USA",
    "UK",
    "Canada",
    "Australia",
    "France",
    "Germany",
    "Ireland",
    "Italy",
    "New Zealand",
    "Dubai",
    "Singapore",
    "Sweden",
  ];

  // Tab switching logic
  const handleTabChange = (country: string) => {
    setActiveTab(country);
    if (country === "All") {
      setFilteredMentors(connectToMentor);
    } else {
      setFilteredMentors(
        connectToMentor.filter((mentor) => mentor.country === country)
      );
    }
  };

  const onSwiperInit = (swiperInstance: any) => {
    setSwiper(swiperInstance);
    setIsBeginning(swiperInstance.isBeginning);
    setIsEnd(swiperInstance.isEnd);
  };

  // Ensure that the swiper is updated when it's initialized
  useEffect(() => {
    if (swiper && swiper.navigation) {
      swiper.navigation.update();
    }
  }, [swiper]);

  const handleSlideChange = () => {
    if (swiper) {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <section className="universities-mentors-container bg-[#D9F6EF]">
      <div className="flex justify-center flex-wrap bg-[#061958] py-4">
        {tabs.map((tab, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <span className="text-white opacity-75">|</span>}
            <button
              className={`px-3 ${
                activeTab === tab
                  ? "text-white font-bold border-b-2 border-white"
                  : "text-white opacity-75"
              } focus:outline-none`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          </React.Fragment>
        ))}
      </div>
      <div className="py-10 lg:py-7">
        <div className="container mx-auto">
          <div className="flex flex-col gap-6">
            <div className="w-full lg:w-1/2">
              <h3 className="text-2xl lg:text-5xl font-light text-center md:text-left text-blue mx-12">
                We will get you Admitted into Top Class Universities Worldwide
              </h3>
            </div>

            <div className="relative">
              <Swiper
                onSwiper={onSwiperInit}
                onSlideChange={handleSlideChange}
                slidesPerView={1}
                spaceBetween={34}
                breakpoints={{
                  640: { slidesPerView: 1 },
                  768: { slidesPerView: 2 },
                  1024: { slidesPerView: 3 },
                  1280: { slidesPerView: 4 },
                }}
                loop={true}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                modules={[Autoplay, Navigation]}
                navigation={{
                  nextEl: ".mentor-button-next",
                  prevEl: ".mentor-button-prev",
                }}
                className="universities-mentors-swiper px-11"
              >
                {filteredMentors.length > 0 ? (
                  filteredMentors.map((mentor, index) => (
                    <SwiperSlide key={index} className="text-center">
                      <div
                        className="relative group bg-[#FFF9E5] rounded-lg overflow-hidden"
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                      >
                        <div
                          className={`flex flex-col overflow-hidden transition-opacity duration-300 ${
                            hoveredIndex === index
                              ? " grayscale"
                              : "opacity-100"
                          }`}
                        >
                          <div className="w-full h-72 overflow-hidden">
                            <img
                              src={mentor.imageUrl || defaultImg}
                              alt={mentor.name}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div
                            className={`p-2 bg-blue text-center flex flex-col gap-1 relative `}
                          >
                            <img
                              src={BadgeIcon}
                              alt="Badge Icon"
                              className="w-[26px] h-[36px] mx-auto absolute -top-4 right-4"
                            />
                            <h4 className="font-bold text-white text-base">
                              {mentor.name}
                            </h4>
                            <p className="text-white text-sm">{mentor.role}</p>
                          </div>
                        </div>

                        <div
                          className={`absolute inset-0 text-white p-4 flex flex-col justify-center items-center transition-opacity duration-300 ease-in-out rounded-lg ${
                            hoveredIndex === index
                              ? "bg-black bg-opacity-65 visible opacity-100"
                              : "bg-transparent opacity-0 invisible"
                          }`}
                        >
                          <p className="text-base mb-4">{mentor.info}</p>
                          <div className="flex flex-wrap justify-center gap-2 mb-4">
                            {mentor.worksOn &&
                              mentor.worksOn.map((work, idx) => (
                                <span
                                  key={idx}
                                  className="bg-transparent border-2 border-green text-white rounded-full px-3 py-1 text-xs"
                                >
                                  {work}
                                </span>
                              ))}
                          </div>
                          <div className="flex flex-row gap-4 justify-center">
                            <button
                              className="bg-green text-white px-3 py-1 rounded-full text-xs"
                              onClick={handlePopup}
                            >
                              Chat With Me
                            </button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                ) : (
                  <div className="text-center text-gray-500 p-10">
                    No mentors available for this country.
                  </div>
                )}
              </Swiper>
              <LeadGenerationPopup
                isOpen={isPopupVisible}
                onClose={() => setIsPopupVisible(!isPopupVisible)}
              />

              <div
                className={`mentor-button-prev w-9 h-9 bg-blue rounded-full flex justify-center items-center rotate-180 text-white absolute left-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer ${
                  isBeginning ? "opacity-50 pointer-events-none" : "opacity-100"
                }`}
              >
                <IconParkOutlineRight />
              </div>
              <div
                className={`mentor-button-next w-9 h-9 bg-blue rounded-full flex justify-center items-center absolute text-white right-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer ${
                  isEnd ? "opacity-50 pointer-events-none" : "opacity-100"
                }`}
              >
                <IconParkOutlineRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UniversitiesWorldwideMentors;
