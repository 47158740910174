import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from '../../../util/config';

export const fetchLandingData = createAsyncThunk(
  "collegePredictorLanding/fetchLandingData",
  async () => {
    const response = await axios.get(
      config.apiUrl + "/api/college/predictorIntermediatePage/joint-entrance-examination-main"
    );
    return response.data;
  }
);
