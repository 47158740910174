import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getBlogOrNewsUrl } from "../../utils";
type RelatedBlogsProps = {
  title: string;
  blogRelated: {}[];
  otherNews: any;
};
const RelatedBlogs: React.FC<RelatedBlogsProps> = ({
  title,
  blogRelated,
  otherNews = [],
}) => {
  const [selectedTab, setSelectedTab] = useState(1);

  console.log("RelatedBlogs", otherNews);

  const selectedContent = selectedTab == 1 ? otherNews : blogRelated;

  // const selectedContent = Op?.filter((blog: any) => {
  //   if (selectedTab == 1) return blog?.category == "News";
  //   else return blog?.category != "News";
  // });

  useEffect(() => {
    if (blogRelated?.length <= 0) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  }, [blogRelated]);

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="p-4 col-span-1 bg-[#FFE9E9]">
        <div className="flex items-center justify-center gap-4">
          <div className="flex flex-col flex-1 gap-1">
            <div className="h-0.5 bg-pink"></div>
            <div className="h-0.5 bg-green"></div>
            <div className="h-0.5 bg-blue"></div>
          </div>
          <div className="flex items-center gap-1">
            <span className="text-4xl text-yellow">[</span>
            <span className="text-xl font-bold">{title}</span>
            <span className="text-4xl text-yellow">]</span>
          </div>
          <div className="flex flex-col flex-1 gap-1">
            <div className="h-0.5 bg-pink"></div>
            <div className="h-0.5 bg-green"></div>
            <div className="h-0.5 bg-blue"></div>
          </div>
        </div>
        <div className="flex gap-2 p-2 border-2 rounded border-green">
          {blogRelated?.length > 0 && (
            <button
              className="flex-1 p-2 text-white rounded"
              style={{
                background: selectedTab == 0 ? "#173cba" : "white",
                color: selectedTab == 0 ? "white" : "#383838",
              }}
              onClick={() => setSelectedTab(0)}
            >
              Blogs
            </button>
          )}
          {otherNews?.length > 0 && (
            <button
              className="flex-1 p-2 rounded"
              style={{
                background: selectedTab == 1 ? "#173cba" : "white",
                color: selectedTab == 1 ? "white" : "#383838",
              }}
              onClick={() => setSelectedTab(1)}
            >
              All News
            </button>
          )}
        </div>
        <div className="divide-y-2 divide-green">
          {selectedContent?.map((blog: any) => (
            <Link
              to={getBlogOrNewsUrl(blog)}
              state={{
                from: "BlogDetail",
                blogDetail: blog,
              }}
              className="flex py-3 text-xs md:text-base"
            >
              {blog?.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelatedBlogs;
