import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../util/config";

export const fetchLandingData = createAsyncThunk(
  "collegeCompare/fetchLandingData",
  async () => {
    const response = await axios.get(
      config.apiUrl + "/api/college/college-compare-page"
    );
    return response.data?.data;
  }
);

export const fetchAndAddCollegeData = createAsyncThunk(
  "collegeCompare/fetchCollegeData",
  async (collegeId: number) => {
    const response = await axios.get(
      config.apiUrl + `/api/college/collegeDetailsById/${collegeId}`
    );
    if (response.data) return { ...response.data?.data, id: collegeId };
    else return null;
  }
);

export const fetchSearchData = createAsyncThunk(
  "collegeCompare/fetchSearchData",
  async (searchKeyword: string) => {
    if (searchKeyword.trim().length == 0) return [];
    const response = await axios.get(
      config.apiUrl + `/api/college/searchByCollegeName/${searchKeyword}`
    );
    return response.data?.data;
  }
);
