import React, { useState } from "react";
import SOPLORCraftingImg from "../../images/study-abroad/Student/sopLorCrafting-img.png";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import { LeadGenerationPopup } from "../../screens/colleges-new/landing/components/WebinarAndArticles";

interface SOPLORCraftingProps {
  data: {
    title: string;
    description: string;
    buttonText: string;
  };
}

const SOPLORCrafting: React.FC<SOPLORCraftingProps> = ({ data }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <section className="bg-[#FFF9E5] py-9">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center gap-10 ">
          <div className="lg:w-[6%] hidden lg:block"></div>
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col gap-7">
              <div className="flex flex-col gap-4">
                <h3 className="text-2xl font-medium lg:font-light text-blue">
                  {data.title}
                </h3>
                <div className="flex flex-col">
                  <p className="text-base text-black">{data.description}</p>
                </div>
              </div>
              <div className="flex flex-row">
                <button
                  onClick={handlePopup}
                  className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white"
                >
                  {data.buttonText} <EpRight />
                </button>
                <div className="flex flex-col justify-start text-left">
                  <LeadGenerationPopup
                    isOpen={isPopupVisible}
                    onClose={() => setIsPopupVisible(!isPopupVisible)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/3 xl:w-1/2 hidden lg:block"></div>
          <div className="w-full lg:w-1/2">
            <div className="flex w-full h-full">
              <img
                src={SOPLORCraftingImg}
                alt={data.title}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SOPLORCrafting;
