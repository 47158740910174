import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const user = "John";
  const role = "Student";

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className="w-full flex bg-white flex-row">
        <div className="flex-grow"></div>

        <div className="user-content relative flex flex-row gap-4 items-center pr-4 md:pr-12 py-2">
          <button>
            <FontAwesomeIcon
              icon={faBell}
              className="h-4 w-4 text-gray-600  pr-4"
            />
          </button>
          <div className="w-12 h-12 flex items-center justify-center bg-gray-200 rounded-lg shadow-lg ml-2 mr-1">
            <img src="/images/avatar.webp" className="h-8 w-8 text-gray-700" />
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-bold">{user}</div>
            <div className="text-sm">{role}</div>
          </div>
          <button
            className="text-base text-blue border border-blue rounded-full px-4 py-2"
            onClick={handleLogout}
          >
            Log out
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
