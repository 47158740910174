import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faPause,
  faPlay,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import Modal from "../../careers-comp/knowledgeHub/ModalPopup";

const DELAY = 5000;

interface Props {
  slides: { image: string; title: string; description: string }[];
  initialIndex: number;
  isOpen: boolean;
  onClose: () => void;
}

const CareerVisualStoriesPopup: React.FC<Props> = ({
  slides = [],
  initialIndex,
  isOpen,
  onClose,
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [paused, setPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (!isOpen) return;
    setCurrentIndex(initialIndex);
    setPaused(false);
    setProgress(0);
    setIsCompleted(false);
  }, [isOpen, initialIndex]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    let progressInterval: ReturnType<typeof setInterval>;

    if (!paused && isOpen && !isCompleted) {
      progressInterval = setInterval(() => {
        setProgress((prev) => (prev < 100 ? prev + 100 / (DELAY / 100) : 100));
      }, 100);

      timer = setTimeout(() => {
        if (currentIndex === slides.length - 1) {
          setIsCompleted(true);
          setProgress(100);
        } else {
          setCurrentIndex((prev) => (prev + 1) % slides.length);
          setProgress(0);
        }
      }, DELAY);
    }

    return () => {
      clearTimeout(timer);
      clearInterval(progressInterval);
    };
  }, [paused, currentIndex, isOpen, isCompleted]);

  const goToNextSlide = () => {
    if (currentIndex === slides.length - 1) {
      setIsCompleted(true);
      setProgress(100);
    } else {
      setCurrentIndex((prev) => (prev + 1) % slides.length);
    }
    setPaused(false);
    setProgress(0);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    setPaused(false);
    setProgress(0);
  };

  const reloadSlides = () => {
    setCurrentIndex(0);
    setIsCompleted(false);
    setPaused(false);
    setProgress(0);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex items-center justify-center h-screen gap-4 w-max CareerVisualSpotBlogPopup">
        <button
          onClick={goToPrevSlide}
          className="hidden lg:block disabled:opacity-50"
          disabled={isCompleted}
        >
          <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-solid border-black rounded-[50px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="15 18 9 12 15 6" />
            </svg>
          </div>
        </button>

        <div className="relative h-full overflow-hidden max-sm:w-[100vw] w-[50%]">
          <div className="absolute top-0 left-0 flex w-full gap-2 p-2">
            {slides.map((_, index) => (
              <div key={index} className="flex-1 bg-gray-300">
                <div
                  className={`progress-bar ${
                    index < currentIndex ||
                    (isCompleted && index === currentIndex)
                      ? "completed"
                      : ""
                  }`}
                  style={{
                    width: `${index === currentIndex ? progress : 100}%`,
                    backgroundColor:
                      index === currentIndex ||
                      (isCompleted && index === currentIndex)
                        ? "#1D4ED8"
                        : "",
                    height: "5px",
                  }}
                />
              </div>
            ))}
          </div>

          <img
            className="object-cover h-full max-sm:w-[100vw]"
            src={slides[currentIndex].image}
            alt={`Slide ${currentIndex}`}
          />

          <div className="absolute flex gap-4 top-12 right-12">
            {!isCompleted && (
              <button onClick={() => setPaused((prev) => !prev)}>
                <FontAwesomeIcon
                  icon={paused ? faPlay : faPause}
                  size="lg"
                  className="text-white"
                />
              </button>
            )}
            {isCompleted && (
              <button onClick={reloadSlides} className="lg:block">
                <FontAwesomeIcon
                  icon={faRedo}
                  size="lg"
                  className="text-white"
                />
              </button>
            )}
            <button onClick={onClose}>
              <FontAwesomeIcon
                icon={faClose}
                size="lg"
                className="text-white"
              />
            </button>
          </div>

          <motion.div
            initial={{ y: "100%", x: "-50%" }}
            animate={{ y: "0%", x: "-50%" }}
            exit={{ y: "0%", x: "-50%" }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="absolute w-[110%] bottom-0 left-1/2 bg-[#000000AA] py-12 px-12"
            style={{
              borderTopLeftRadius: "50% 75px",
              borderTopRightRadius: "50% 75px",
            }}
          >
            <div className="text-white text-center flex flex-col gap-4">
              <h4 className="text-xl text-white font-bold">
                {slides[currentIndex].title}
              </h4>
              <p className="text-base text-white">
                {slides[currentIndex].description}
              </p>
            </div>
          </motion.div>
        </div>
        <button
          onClick={goToNextSlide}
          className="hidden lg:block disabled:opacity-50"
          disabled={isCompleted}
        >
          <div className="flex justify-center items-center p-2.5 w-11 h-11 bg-white border border-solid border-black rounded-[50px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </div>
        </button>
      </div>
    </Modal>
  );
};

export default CareerVisualStoriesPopup;
