import "./CareerDetailsStyles.scss";

const EducationPathway: React.FC<{ pathway: any }> = ({ pathway }) => {
  if (!pathway) return null;

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col lg:flex-row justify-between md:pr-8 gap-6">
            <div className="flex flex-col w-full lg:w-4/5">
              <div className="flex max-md:flex-col max-md:w-full">
                <h3 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                  {pathway.title}
                </h3>
              </div>
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 educationPathwayList"
                dangerouslySetInnerHTML={{
                  __html: pathway.description as string,
                }}
              ></div>
            </div>
            <div className="flex flex-col items-center justify-center w-full lg:w-1/5 relative bg-[#D9D9D9]">
              <div className="p-1">
                <img
                  src={pathway.image}
                  alt={pathway.title}
                  className="w-full h-full object-cover rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EducationPathway;
