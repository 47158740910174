import React, { useEffect, useState, useRef } from "react";
import CareerDetailsBanner from "../../components/career-details/CareerDetailsBanner";
import HowBecomeCareerDetails from "../../components/career-details/HowBecomeCareerDetails";
import HistoricalTimeline from "../../components/career-details/HistoricalTimeline";
import CertificationSection from "../../components/career-details/CertificationSection";
import EducationPathway from "../../components/career-details/EducationPathway";
import RolesAndResponsibilities from "../../components/career-details/RolesAndResponsibilities";
import SalarySection from "../../components/career-details/SalarySection";
import FAQSection from "../../components/career-details/FAQSection";
import CareerTypes from "../../components/career-details/CareerTypes";
import Loader from "../../components/Loader/Loader";
import KnowledgeAndSkills from "../../components/career-details/KnowledgeAndSkills";
import CareerPaths from "../../components/career-details/CareerPaths";
import TopEntranceExams from "../../components/career-details/TopEntranceExams";
import TalkToAProfessional from "../../components/career-details/TalkToAProfessional";
import Resources from "../../components/career-details/resource/Resources";
import TabNavigations from "../../components/career-details/CareerTabs";
import DataScienceRoadmap from "../../components/career-details/DataScienceRoadmap";
import CollegesDataScience from "../../components/career-details/CollegesDataScience";

const CareerDetails: React.FC = () => {
  const [careerDetails, setCareerDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const sectionRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  useEffect(() => {
    const fetchCareerDetails = async () => {
      try {
        const response = await fetch(
          "https://mentormenteedev.com/api/career/details/data_scientist"
        );
        const data = await response.json();
        console.log("Career Details Data:", data);
        setCareerDetails(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching career details:", error);
        setLoading(false);
      }
    };

    fetchCareerDetails();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!careerDetails || !careerDetails.length) {
    return <div>Error loading career details.</div>;
  }

  const getDataByOrderIndex = (index: number) =>
    careerDetails.find((item: any) => item.orderIndex === index);

  const resourceData = getDataByOrderIndex(16)?.description || {};

  return (
    <>
      <div id="banner" ref={(el) => (sectionRefs.current["banner"] = el)}>
        <CareerDetailsBanner
          title={getDataByOrderIndex(1)?.title}
          progressTitle="Would you make a good Data Scientist?"
          progressDescription="Take our career test and find your match with over 800 careers."
          progressButtonText="Take the free career test"
        />
      </div>
      <div className="bg-[#ECECEC] flex flex-col gap-8">
        <TabNavigations />
        <div
          id="how-become"
          ref={(el) => (sectionRefs.current["how-become"] = el)}
        >
          <HowBecomeCareerDetails content={getDataByOrderIndex(1)} />
        </div>

        <div id="timeline" ref={(el) => (sectionRefs.current["timeline"] = el)}>
          <HistoricalTimeline timeline={getDataByOrderIndex(2)} />
        </div>
        <div id="pathway" ref={(el) => (sectionRefs.current["pathway"] = el)}>
          <EducationPathway pathway={getDataByOrderIndex(3)} />
        </div>

        <div
          id="certifications"
          ref={(el) => (sectionRefs.current["certifications"] = el)}
        >
          <CertificationSection certifications={getDataByOrderIndex(5)} />
        </div>

        <div
          id="responsibilities"
          ref={(el) => (sectionRefs.current["responsibilities"] = el)}
        >
          <RolesAndResponsibilities responsibilities={getDataByOrderIndex(6)} />
        </div>

        <div
          id="knowledge-skills"
          ref={(el) => (sectionRefs.current["knowledge-skills"] = el)}
        >
          <KnowledgeAndSkills knowledgeSkills={getDataByOrderIndex(7)} />
        </div>

        <div id="salary" ref={(el) => (sectionRefs.current["salary"] = el)}>
          <SalarySection salaryDetails={getDataByOrderIndex(8)} />
        </div>
        <div id="roadmap" ref={(el) => (sectionRefs.current["roadmap"] = el)}>
          <DataScienceRoadmap roadmap={getDataByOrderIndex(9)} />
        </div>

        <div
          id="career-paths"
          ref={(el) => (sectionRefs.current["career-paths"] = el)}
        >
          <CareerPaths
            careerPaths={getDataByOrderIndex(10)}
            topColleges={getDataByOrderIndex(10)?.topColleges}
          />
        </div>

        <div
          id="entrance-exams"
          ref={(el) => (sectionRefs.current["entrance-exams"] = el)}
        >
          <TopEntranceExams entranceExams={getDataByOrderIndex(11)} />
        </div>
        <div
          id="colleges-data-science"
          ref={(el) => (sectionRefs.current["colleges-data-science"] = el)}
        >
          <CollegesDataScience collegesDataScience={getDataByOrderIndex(12)} />
        </div>

        <div
          id="talk-to-professional"
          ref={(el) => (sectionRefs.current["talk-to-professional"] = el)}
        >
          <TalkToAProfessional professionalDetails={getDataByOrderIndex(13)} />
        </div>

        <div id="faqs" ref={(el) => (sectionRefs.current["faqs"] = el)}>
          <FAQSection faq={getDataByOrderIndex(14)} />
        </div>

        <div
          id="career-types"
          ref={(el) => (sectionRefs.current["career-types"] = el)}
        >
          <CareerTypes careersTypes={getDataByOrderIndex(15)} />
        </div>

        <div
          id="resources"
          ref={(el) => (sectionRefs.current["resources"] = el)}
        >
          <Resources
            careerVisualStories={resourceData.visualStories || []}
            careerspotlights={resourceData.spotlights || []}
            blogsAndArticles={resourceData.articles || []}
          />
        </div>
      </div>
    </>
  );
};

export default CareerDetails;
