import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="my-7 container mx-auto p-6  text-black rounded-lg shadow-lg">
      <h2 className="font-bold text-lg mb-4">Privacy Policy</h2>
      <p className="mb-2">
        We at Dreams and Degrees understand the importance of protecting your data and any information we collect for your service. All the user data collected in any form will not be sent out of the company for any purpose. At present, we are not collecting personal data from any of the visitors on the site. As a visitor, you can view all the content written on various blogs, and information on colleges, universities, mentors, and team members. You are not required to provide information to view any of the content.
      </p>
      <p className="mb-2">
        Further, it is noted that we at Dreams and Degrees are not collecting explicitly any information like IP address, device details, operating system, behavioral patterns, or any other information. At our company, we respect data security and will transparently communicate with all users as and when it gets updated, and explicit consent will be asked for. We respect the data privacy rules of India, where our company, Dreams and Degrees, is based, and abide completely by local laws.
      </p>
      <p className="mb-2">
        If for any purpose you have communicated to us via any social media platform of the company or email, the information of the query may be stored for internal purposes. If you visit the advertiser's page through our website, we will not be sharing the URL with the companies at present.
      </p>
      <p className="mb-2">
        Like most platforms, we are not collecting cookies and web log files to track any user data. In the future, for the case of customizing advertisements, we may customize or allow third parties to recognize a unique cookie of your browser.
      </p>
      <p className="mb-2">
        For better representation of information and to provide overall information regarding a piece of content, many external links are placed on the website. It should be noted that once you leave our website by clicking on the external link, we don’t take any legal responsibility regarding the privacy and protection of any information.
      </p>
      <p className="mb-2">
        If a minor is accessing our website, it is recommended to do so under the supervision of a parent or legal guardian. Although we take great care regarding your security as a user of our website, College Mentor, there are certain obligations from your side as you must abide by our terms and conditions and privacy policy.
      </p>
      <p className="mb-2">
        All the material and information displayed on the website is copyrighted and is owned by our company, so you are restricted from using this data anywhere.
      </p>
      <p className="mb-2">
        Every possible care will be taken by the company as per existing industry standards and laws, but you have to acknowledge that the internet is not fully secure, and we cannot provide any absolute assurance regarding the security of the information. Therefore, you shall not hold us responsible for any loss of your information or any other data which you have shared.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
