import React from "react";

interface BlogsAdBannerProps {
  width: string;
  height: string;
  image: any;
  link:string;
}
const BlogsAdBanner: React.FC<BlogsAdBannerProps> = ({
  width,
  height,
  image,
  link
}) => {
  return (
    <>
    <a href={link} target="_blank">
      <img className="" src={image} width={width ? width : "980px"} height={height? height:"90px"} />
      </a>
    </>
  );
};

export default BlogsAdBanner;
