import { createSlice } from "@reduxjs/toolkit";
import { getMyProfile, getOptionData, updateMyProfile } from "./myProfileThunk";

interface IProfile {
  userData: {
    [key: string]: any;
  };
  optionData: any;
  loading: boolean;
  error: string | null;
}

const initialState: IProfile = {
  userData: {},
  optionData: null,
  loading: false,
  error: null,
};

const myProfileSlice = createSlice({
  name: "myProfile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateMyProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateMyProfile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateMyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload?.toString() || "Error updating profile data";
      })
      .addCase(getMyProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyProfile.fulfilled, (state, action) => {
        state.loading = true;
        state.error = null;
        state.userData = { ...action.payload };
      })
      .addCase(getMyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload?.toString() || "Error fetching profile data";
      })
      .addCase(getOptionData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOptionData.fulfilled, (state, action) => {
        state.loading = true;
        state.error = null;
        state.optionData = action.payload;
      })
      .addCase(getOptionData.rejected, (state, action) => {
        state.loading = false;
        state.error = "Error fetching profile option data";
      });
  },
});

export default myProfileSlice.reducer;
