import React, { useCallback, useEffect, useState } from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

interface Mentor {
  imageUrl: string;
  name: string;
  role: string;
  info: string;
  id: number;
}

interface MentorCardProps {
  mentor: Mentor;
  cardSize: "small" | "medium" | "large";
}

const MentorCard: React.FC<MentorCardProps> = ({ mentor, cardSize }) => {
  const cardSizes: { [key: string]: string } = {
    small: "w-[210px] h-[166px]",
    medium: "w-[210px] h-[350px]",
    large: "w-[370px] h-[325px]",
  };

  return (
    <div
      className={`relative rounded-lg overflow-hidden shadow-lg ${cardSizes[cardSize]} cursor-pointer`}
    >
      <img
        loading="lazy"
        src={mentor.imageUrl}
        alt={`${mentor.name}, ${mentor.role}`}
        className="w-full h-full object-fit filter grayscale transition duration-300 ease-in-out hover:filter-none"
      />
      <div className="absolute bottom-0 left-0 w-full h-[54px] bg-[#173CBA] text-white text-center pt-1 pb-2">
        <span className="font-bold">{mentor.name}</span>
        <br />
        <span>{mentor.role}</span>
        <br />
        {/* <span>{mentor.info}</span> Displaying experience */}
      </div>
    </div>
  );
};

const mentors: Mentor[] = [
  {
    imageUrl: "https://i.ibb.co/d0fmHJq/Raja-Shekar.png",
    name: "M.Rajashekar",
    role: "Admission Mentor (India)",
    info: "15+ Year Experience",
    id: 1,
  },
  {
    imageUrl: "https://i.ibb.co/gwJLybF/rajaskhekar.png",
    name: "M.Kavya",
    role: "Student Buddy Mentor",
    info: "1+ Year Experience",
    id: 2,
  },
  {
    imageUrl: "https://i.ibb.co/VQYMbxx/Mentor-8.png",
    name: "K.Madahan",
    role: "Admission Mentor (Abroad)",
    info: "10+ Year Experience",
    id: 3,
  },
  {
    imageUrl: "https://i.ibb.co/c6m2666/Mentor-7.png",
    name: "S.Swathi",
    role: "Health Mentor",
    info: "6+ Year Experience",
    id: 4,
  },
  {
    imageUrl: "https://i.ibb.co/gwJLybF/rajaskhekar.png",
    name: "V. Prakash",
    role: "Career Mentor",
    info: "12+ Year Experience",
    id: 5,
  },
  {
    imageUrl: "https://i.ibb.co/gwJLybF/rajaskhekar.png",
    name: "A. Srinivas",
    role: "Technical Mentor",
    info: "8+ Year Experience",
    id: 6,
  },
  {
    imageUrl: "https://i.ibb.co/gwJLybF/rajaskhekar.png",
    name: "P. Sunitha",
    role: "Life Coach",
    info: "5+ Year Experience",
    id: 7,
  },
];

const MentorSection: React.FC = () => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [navBtnDisabled, setNavBtnDisabled] = useState({
    prev: true,
    next: false,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [isPrevHovered, setIsPrevHovered] = useState(false);

  useEffect(() => {
    if (swiperRef && swiperRef.slides) {
      setTotalSlides(swiperRef.slides.length);
    }
  }, [swiperRef]);

  const handleSlideChange = () => {
    if (swiperRef) {
      setCurrentSlide(swiperRef.realIndex || 0);
      setNavBtnDisabled({
        prev: swiperRef.isBeginning,
        next: swiperRef.isEnd,
      });
    }
  };

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const progressBarElement = (
    <div className="flex gap-4 items-center">
      <span>{currentSlide + 1}</span>
      <div className="w-24 h-1 bg-[#D9D9D9]">
        <div
          className="h-full bg-[#173CBA]"
          style={{
            width: totalSlides
              ? ((currentSlide + 1) / totalSlides) * 100 + "%"
              : "0%",
          }}
        ></div>
      </div>
      <span>{totalSlides}</span>
    </div>
  );

  const prevButton = (
    <button
      className={`${navBtnDisabled.prev ? "" : ""} p-2.5`}
      onClick={handlePrevious}
      onMouseEnter={() => setIsPrevHovered(true)}
      onMouseLeave={() => setIsPrevHovered(false)}
    >
      <LeftArrow />
    </button>
  );

  const nextButton = (
    <button
      className={`${navBtnDisabled.next ? "" : ""} p-2.5`}
      onClick={handleNext}
    >
      <RightArrow isPrevHovered={isPrevHovered} />
    </button>
  );
  return (
    <div className="items-center px-20 py-20 md:w-full bg-[#F4F6F8] max-md:px-5 mt-6">
      <h3 className="text-[40px] font-bold leading-none text-[#173CBA] ">
        The world is ready for you.{" "}
        <span className="text-[#0C9]">Be ready for the world.</span>
      </h3>
      <p className="mt-3.5 leading-loose text-black text-base ">
        We connect individuals with mentors to help them achieve their college
        and career dreams through Offline & Online Mentoring.
      </p>

      <div className="max-md:hidden">
        <div className="flex flex-row md:container justify-between mt-14">
          <div className="flex justify-center gap-4 ">
            <MentorCard mentor={mentors[0]} cardSize="medium" />
          </div>

          <div className="flex justify-between gap-[18px] flex-col">
            <MentorCard mentor={mentors[1]} cardSize="small" />
            <MentorCard mentor={mentors[2]} cardSize="small" />
          </div>

          <div className="flex justify-center gap-4 my-auto">
            <MentorCard mentor={mentors[3]} cardSize="large" />
          </div>

          <div className="flex justify-between gap-[18px] flex-col">
            <MentorCard mentor={mentors[4]} cardSize="small" />
            <MentorCard mentor={mentors[5]} cardSize="small" />
          </div>

          <div className="flex justify-center gap-4 ">
            <MentorCard mentor={mentors[6]} cardSize="medium" />
          </div>
        </div>
      </div>

      <div className="md:hidden mt-14">
        <Swiper
          onSwiper={setSwiperRef}
          onSlideChange={handleSlideChange}
          spaceBetween={10}
          className="w-full"
          modules={[Autoplay, Navigation]}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          {mentors.map((mentor) => (
            <SwiperSlide key={mentor.id}>
              <MentorCard mentor={mentor} cardSize="large" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex justify-center container swiper-buttons relative mx-auto py-7  ">
          {prevButton}
          {progressBarElement}
          {nextButton}
        </div>
      </div>
    </div>
  );
};

export default MentorSection;

const LeftArrow = () => {
  return (
    <div className="p-4 border border-black hover:border-[#173CBA] rounded-full bg-white hover:bg-[#173CBA] group">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-current text-black group-hover:text-white"
          d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4376C5.43046 12.534 5.54562 12.6104 5.67226 12.6623C5.7989 12.7142 5.93452 12.7407 6.07139 12.7402C6.20826 12.7398 6.3437 12.7123 6.46996 12.6595C6.59623 12.6067 6.70985 12.5297 6.80208 12.4313C6.89972 12.3345 6.97721 12.2193 7.0301 12.0923C7.08298 11.9654 7.11021 11.8292 7.11021 11.6917C7.11021 11.5542 7.08298 11.4181 7.0301 11.2911C6.97721 11.1642 6.89972 11.049 6.80208 10.9522L3.375 7.52508H13.7917C13.9928 7.51863 14.1874 7.45432 14.3496 7.34069C14.5117 7.22705 14.6338 7.06908 14.7019 6.88914C14.7701 6.70921 14.7808 6.516 14.7322 6.33329C14.6837 6.15058 14.5789 5.98734 14.4304 5.86559C14.282 5.74385 14.0962 5.66962 13.8991 5.65366C13.8635 5.65688 13.8276 5.65866 13.7917 5.65866V5.45842Z"
        />
      </svg>
    </div>
  );
};

const RightArrow = ({ isPrevHovered }: { isPrevHovered: boolean }) => {
  return (
    <div className="p-4 border border-black hover:border-[#173CBA] rounded-full bg-white hover:bg-[#173CBA] group">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`fill-current text-black group-hover:text-white ${
            isPrevHovered ? "hovered-arrow-style" : ""
          }`}
          d="M1.20833 5.45842H11.625L8.19792 2.03134C8.10028 1.9345 8.02279 1.8193 7.9699 1.69236C7.91702 1.56542 7.88979 1.42927 7.88979 1.29176C7.88979 1.15425 7.91702 1.01809 7.9699 0.891158C8.02279 0.764222 8.10028 0.649012 8.19792 0.552176C8.39309 0.358165 8.6571 0.249268 8.93229 0.249268C9.20748 0.249268 9.4715 0.358165 9.66667 0.552176L14.1354 5.03134C14.5267 5.42024 14.7477 5.94844 14.75 6.50008V6.50008C14.7449 7.04811 14.5241 7.57207 14.1354 7.95842L9.66667 12.4376C9.56954 12.534 9.45438 12.6104 9.32774 12.6623C9.2011 12.7142 9.06548 12.7407 8.92861 12.7402C8.79174 12.7398 8.6563 12.7123 8.53004 12.6595C8.40377 12.6067 8.29015 12.5297 8.19792 12.4313C8.10028 12.3345 8.02279 12.2193 7.9699 12.0923C7.91702 11.9654 7.88979 11.8292 7.88979 11.6917C7.88979 11.5542 7.91702 11.4181 7.9699 11.2911C8.02279 11.1642 8.10028 11.049 8.19792 10.9522L11.625 7.52508H1.20833C1.00719 7.51863 0.812626 7.45432 0.65045 7.34069C0.488273 7.22705 0.366176 7.06908 0.298064 6.88914C0.229953 6.70921 0.219232 6.516 0.267802 6.33329C0.316371 6.15058 0.421082 5.98734 0.569587 5.86559C0.718092 5.74385 0.903895 5.66962 1.10093 5.65366C1.13646 5.65688 1.1724 5.65866 1.20833 5.65866V5.45842Z"
        />
      </svg>
    </div>
  );
};
